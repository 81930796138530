/*
    @package MEDICALERTTHEMEPREMIUM
    ======================================
        GENERAL WP BLOCK COMPONENT SCSS
    ======================================
*/

// [START] BACKGROUD COLORS
.has-prussian-blue-background-color{
    background-color: var(--color-success);
}
.has-celadon-blue-background-color{
    background-color: var(--color-info);
}
.has-mint-cream-background-color{
    background-color: var(--color-light-blue);
}
.has-spanish-viridian-background-color{
    background-color: var(--color-primary);
}
.has-rose-madder-background-color{
    background-color: var(--color-danger);
}
.has-sinopia-background-color{
    background-color: var(--color-notification);
}
.has-beau-blue-background-color{
    background-color: var(--color-secondary);
}
.has-black-froga-background-color{
    background-color: var(--color-black);
}
// [END] BACKGROUD COLORS

// [START] FONT COLORS
.has-prussian-blue-color p{
    color: var(--color-success);
}
.has-mint-cream-color p{
    color: var(--color-light-blue);
}
.has-spanish-viridian-color p{
    color: var(--color-primary);
}
.has-rose-madder-color p{
    color: var(--color-danger);
}
.has-sinopia-color p{
    color: var(--color-notification);
}
.has-beau-blue-color p{
    color: var(--color-secondary);
}
.has-black-froga-color p{
    color: var(--color-black);
}
.has-white-color p{
    color: var(--color-white);
}
// [END] FONT COLORS
