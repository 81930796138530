/*
    @package MEDICALERTTHEMEPREMIUM
    ==================================================
        MOST RECENT QUOTE HORIZONTAL COMPONENT SCSS
    ==================================================

*/
.maf-component-quotes{
    font-size: 0.8em;
    .wp-block-pullquote{
        border-color: var(--color-info);
        cite{
            p{
                font-size: 1rem;
            }
        }
    }
}

@media (min-width: 768px) {
    .maf-component-quotes{
        font-size: 1em;
    }
}
