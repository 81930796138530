@import "../skin/skinVariables";

h2,
h3,
h4,
h5,
h6 {
    font-family: var(--skin-body-font);
}
h1{
    font-family: var(--h1-layout-content);
    color:var(--h1-layout-color);
}
h1,
h1.page-title,
h1.archive-title,
h1.product-name{
    font-size: 1.63em;
    text-transform: uppercase;
    font-weight: bold;
    font-weight: 600;
    margin: 0;
}

h2{
    font-size: 1.53em;
}

h1.product-name{
    font-size: 2.63em;
    margin: 0 0 0.6em;
}
div.maf-element-h1 > div{
    margin: 0;
}

.maf-element-h1 h1,
h1.header.page-title,
.maf-element-h1 h1.page-title{
    margin: 0;
    background: none;
    padding: 0;
    left: 0;
    font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
    font-size: 1.3125em;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #051424;    ;
}

h1.header.page-title:before{
    width: 0;
    left: 0;
}
h1.product-name{
    margin: 0 0 0.3em;
    font-size: 2.3em;
}
.maf-page-layout-content{
    h2{
        margin:0 0 1em;
    }
}

.maf-page-header{
    margin: 36px 16px 16px;
    .maf-element-h1{
        margin: 0 0 16px;
    }
    .maf-copy-blocks{
        p{
            margin: 0;
            font-size: 0.875em;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #051424;            
        }
    }
}


.maf-page-layout-informational-sidebar,
.maf-page-layout-informational-no-sidebar,
.maf-page-layout-content-contact,
.maf-page-layout-cart,
.maf-page-layout-bcop,
.maf-page-layout-category,
.maf-element-h1{
    h1{
        text-transform: capitalize;
        color: var(--color-black);
        font-size: 2.63em;
        font-weight: bold;
        font-weight: 900;
        letter-spacing: 0;
        margin: 0 0 24px;
    }
}
.maf-component-checkout-header h1{
    font-family: var(--h1-layout-content);
}

@media (min-width: 768px) {
    .maf-page-header{
        margin: 48px 0 0;
        .maf-element-h1{
            margin: 0 0 24px;
        }
        .maf-copy-blocks{
            p{
                font-size: 1.3125em;
            }
        }        
    }
    .maf-element-h1 h1,
    h1.header.page-title,
    .maf-element-h1 h1.page-title{
        font-size: 2.25em;
    }

}

@media (max-width: 768px) {

    .maf-page-layout-informational-sidebar,
    .maf-page-layout-informational-no-sidebar,
    .maf-page-layout-bcop,
    .maf-element-h1{
        h1{
            font-size: 2em;
        }
    }

}
