/*
    @package MEDICALERTTHEMEPREMIUM
    ======================================
        HALF HERO H1 ONE COMPONENT SCSS
    ======================================
*/

// [START] HALF HERO H1 1
.maf-component-half-hero-h1-1{
    font-size: 2.83vw;
    text-align: center;
    background-color: var(--color-info);
    div{
        &:nth-of-type(1){
            padding: 5% 0 2%;
            width: 90%;
            margin: 0 auto;
        }
        > figure{
            background-size: cover !important;
            background-position: 100% 0;
            padding-top: calc((360% / 620) * 100);
        }
        h1{
            color:var(--color-white);
            font-size: 2.7em;
            font-weight: bold;
            font-weight: 700;
        }
        p{
            color:var(--color-white);
            font-size: 1.73em;
        }
    }
}
// [END] HALF HERO H1 1

// [START] HALF HERO LOGO 1
.maf-component-half-hero-logo-1{
    &.maf-component-half-hero-logo-1-medicalguardian{
        background-color:#f8f8f8;
        margin: 0 0 3em;
    }
    div{
        &:nth-of-type(1){
            padding: 5% 0 2%;
            width: 90%;
            margin: 0 auto;
        }
        > figure{
            background-size: cover !important;
            background-position: 100% 0;
            padding-top: calc((485% / 982) * 100);
        }
        h1{
            img{
                display: block;
                width: 70%;
                margin: 0 auto;
            }
        }
        p{
            color:var(--color-white);
            font-size: 1.73em;
        }
    }
}
// [END] HALF HERO LOGO 1

@media (min-width: 768px) {

    // [START] HALF HERO H1 1
    .maf-component-half-hero-h1-1{
        font-size: 1.33vw;
        display: flex;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: -webkit-box-flex;
        align-items: center;
        -webkit-align-items: center;
        -ms-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-justify-content: center;
        > div{
            flex:1;
            &:nth-of-type(1){
                padding: 0%;
                flex: 1.5;
            }
        }
    }
    // [END] HALF HERO H1 1

    // [START] HALF HERO LOGO 1
    .maf-component-half-hero-logo-1{
        display: flex;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: -webkit-box-flex;
        align-items: center;
        -webkit-align-items: center;
        -ms-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-justify-content: center;
        > div{
            flex:1;
            &:nth-of-type(1){
                padding: 0%;
                flex: 1;
            }
        }        
    }
    // [END] HALF HERO LOGO 1

}

@media (min-width: 992px) {
    .maf-component-half-hero-h1-1{
        font-size: 1em;
        margin: 0 0 3em;
    }
}
