#js-googlepay-container,
#minicart-googlepay-container,
#cart-googlepay-container {
	text-align: center;
	height: 40px;
}

#js-googlepay-container,
#minicart-googlepay-container,
#cart-googlepay-container {
	text-align: center;
	height: 40px;
}

.payments-digital {
	.card {
	  margin-bottom: 0px !important;
	}
  
	.payment-btn-container {
	  display: flex;
	  flex-wrap: wrap;
	  justify-content: center;
	  align-items: center;
	  padding: 20px 0;
	  gap: 24px;
  
	  // Styles for Apple Pay and Google Pay buttons
	  .apple-pay-button, .google-pay-button {
		display: flex;
		justify-content: center;
		height: 50px !important;
	  }
		@media (max-width: 767px) {
		  gap: 16px;
		}
	}
  
	.continue-payment {
	  color: #1d3557;
	  font-family: "SourceSansPro Regular", helvetica, sans-serif !important;
	  margin-bottom: 40px;
	  margin-top: 40px;
	  position: relative;
	  font-size: 1.125rem;
	  text-align: center;
  
	  @media (max-width: 575px) {
		width: 175px;
		height: 32px;
		font-size: 0.875rem;
		margin-bottom: 24px;
		margin-top: 24px;
	  }
	}
  
	.continue-text-parent {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  position: relative;
	  text-align: center;
  
	  &::before {
		content: "";
		flex-grow: 1;
		height: 1px;
		background-color: rgba(0, 0, 0, .125);
		margin-right: 24px;
	  }
  
	  &::after {
		content: "";
		flex-grow: 1;
		height: 1px;
		background-color: rgba(0, 0, 0, .125);
		margin-left: 24px;
	  }
	}
  
	.dw-apple-pay-button, .gpay-button.black.checkout.en {
	  width: 300px !important;
	  height: 50px !important;
	  border-radius: 8px;
	  margin: 0 !important;
	  outline: none !important;
	}
	.dw-apple-pay-button.apple-pay-cart,
	.dw-apple-pay-button.apple-pay-cart:hover {
		border-radius: 8px !important;
	}
}