/*
    @package MEDICALERTTHEMEPREMIUM
    =============================================
        MOST RECENT HORIZONTAL COMPONENT SCSS
    =============================================

*/

.maf-component-most-recent-vertical article .maf-excerpt-wrapper{
    position: relative;
    padding: 6% 4%;
}
