/*
    @package MEDICALERTTHEMEPREMIUM
    ===================================
        SELECT PLAN COMPONENT SCSS
    ===================================

*/

#membershipModalPDP{
	overflow: auto;
}

#membershipModalPDP.maf-modal-memberships-plans-new .bg-dark{
	background-color: #1d3557!important;
}

#membershipModalPDP.maf-modal-memberships-plans-new .bg-info, 
#membershipModalPDP.maf-modal-memberships-plans-new .text-bg-info {
	background-color: #457796!important;
}

#membershipModalPDP.maf-modal-memberships-plans-new .bg-light {
	background-color: #F0F9F9!important;
}

#membershipModalPDP.maf-modal-memberships-plans-new .btn {
    border: none;
    border-radius: 1em;
    min-width: auto;
    min-height: auto;
    width: auto;
    font-size: 1em;
}

#membershipModalPDP.maf-modal-memberships-plans-new .btn-primary {
	background-color: #DC3545!important;
}

#membershipModalPDP.maf-modal-memberships-plans-new .list-group-item{
	border: none;
	font-size: 1.33em;
}

#membershipModalPDP.maf-modal-memberships-plans-new .btn-secondary {
	background-color: #457796!important;
}

#membershipModalPDP.maf-modal-memberships-plans-new .btn-outline-secondary {
	border: 1px solid #457796!important;
}

#membershipModalPDP.maf-modal-memberships-plans-new .btn-outline-dark{
	border: 1px solid #1d3557!important;
	color: #1d3557;
}

#membershipModalPDP.maf-modal-memberships-plans-new .btn-outline-dark:hover,
#membershipModalPDP.maf-modal-memberships-plans-new .btn-outline-dark:focus{
	background: #457796!important;
	color: #fff;
}

#membershipModalPDP.maf-modal-memberships-plans-new .link-dark {
	color: #457796!important;
}

#membershipModalPDP.maf-modal-memberships-plans-new .text-secondary {
	color: #457796!important;
}

#membershipModalPDP.maf-modal-memberships-plans-new .card-header{
    padding: 0;
}

#membershipModalPDP.maf-modal-memberships-plans-new .card-header button.accordion-button{
    padding: 0.5em 1em;
	width:100%;
	text-align:left;
	border:none;
}

#membershipModalPDP.maf-modal-memberships-plans-new .modal-header h3{
	font-size:1.3em;
}

.message-tertiary h4{
	font-size: 1.3em;
    font-weight: bold;
    font-weight: 600;
}

.message-tertiary p{
	line-height:1.3em;
	max-width: 97%;
}
.modal-text-four {
	@media screen and (min-width: 1024px){
		margin-left: auto;
	}
}

#membershipModalPDP.maf-modal-memberships-plans-new .scroll-down-icon{
	width: 1em;
}

#membershipModalPDP.maf-modal-memberships-plans-new .member-picture img{
	border-radius: 50%;
}

#membershipModalPDP.maf-modal-memberships-plans-new #plans-compare > div div.col-lg-4{
	font-size: 0.8em;
	max-width:90%;
}

#membershipModalPDP.maf-modal-memberships-plans-new #plans-compare > div div.col-lg-4 .card-body{
	padding:0;
}

#membershipModalPDP.maf-modal-memberships-plans-new #plans-compare > div div.col-lg-4 .card-body .text-start{
	text-align:left;
}

#membershipModalPDP.maf-modal-memberships-plans-new #plans-compare > div div.col-lg-4 > div{
	border-radius: 15px;
}

#membershipModalPDP.maf-modal-memberships-plans-new #plans-compare > div > h2{
	font-size: 2em;
    font-weight: bold;
}

#membershipModalPDP.maf-modal-memberships-plans-new #plans-compare > div div.col-lg-4 > div h2{
	border-radius: 15px 15px 0 0;
	font-weight:bold;
}

#membershipModalPDP.maf-modal-memberships-plans-new img.icons{
	width:23%;
}

#membershipModalPDP.maf-modal-memberships-plans-new .maf-link,
#membershipModalPDP.maf-modal-memberships-plans-new .maf-link{
	border-bottom: solid 1px;
	font-size: 0.83em;
}

#membershipModalPDP.maf-modal-memberships-plans-new section.bg-info .maf-link{
	color:#fff;
}

#membershipModalPDP.maf-modal-memberships-plans-new li.list-group-item-danger{
	background-color : #f1b8b857;
}

#membershipModalPDP.maf-modal-memberships-plans-new li.list-group-item-secondary{
	background-color : #d6d8db61;
}

#membershipModalPDP.maf-modal-memberships-plans-new .list-group-item-info{
	background-color: #4577962e;
}

#membershipModalPDP.maf-modal-memberships-plans-new .maf-important-heading{
	font-size: 2.4em;
}

#membershipModalPDP.maf-modal-memberships-plans-new #member-stories blockquote {
	font-size: 1.5em;
}

#membershipModalPDP.maf-modal-memberships-plans-new #frequentlyAskQuestions button:after{
	content: "-";
	float: right;
}

#membershipModalPDP.maf-modal-memberships-plans-new #frequentlyAskQuestions button.collapsed:after{
	content: "+";
	float: right;
}

#membershipModalPDP.maf-modal-memberships-plans-new .maf-link:hover{
	text-decoration:none;
	border:none;
}
#membershipModalPDP.maf-modal-memberships-plans-new section.bg-info .maf-link::after{
	background:#fff;
}

@media screen and (min-width: 768px) {

	#membershipModalPDP.maf-modal-memberships-plans-new #plans-compare > div div.col-lg-4{
		max-width:80%;
	}

}

@media screen and (min-width: 992px) {

	#membershipModalPDP.maf-modal-memberships-plans-new .modal-dialog{
		max-width:93%;
	}

}

@media screen and (min-width: 1200px) {
	
	#membershipModalPDP.maf-modal-memberships-plans-new .modal-dialog{
		max-width:83%;
	}

}


@media screen and (max-width: 992px) {

#membershipModalPDP.maf-modal-memberships-plans-new .modal-header,
#membershipModalPDP.maf-modal-memberships-plans-new .message-secondary,
#membershipModalPDP.maf-modal-memberships-plans-new .message-tertiary{
	font-size: 0.7em;
}

#membershipModalPDP.maf-modal-memberships-plans-new .modal-header{
	padding: 0.4em;
}

#membershipModalPDP.maf-modal-memberships-plans-new .modal-header > section,
#membershipModalPDP.maf-modal-memberships-plans-new section.message-secondary{
	width: 100%;
	text-align: center;
}

#membershipModalPDP.maf-modal-memberships-plans-new section.message-tertiary h4,
#membershipModalPDP.maf-modal-memberships-plans-new section.message-tertiary p{
	text-align: left;
	margin: 0;
}

#membershipModalPDP.maf-modal-memberships-plans-new h2{
	font-size: 1.33em;
    font-weight: bold;
    font-weight: 600;
}
#membershipModalPDP.maf-modal-memberships-plans-new .bg-dark{
	padding: 4em 0;
}
#membershipModalPDP.maf-modal-memberships-plans-new  .flickity-page-dots li.dot{
	background:#fff;
}
#membershipModalPDP.maf-modal-memberships-plans-new  .carouselPlans{
    background: #1e3557;
}
#membershipModalPDP.maf-modal-memberships-plans-new #member-stories blockquote{
	font-size: 1em;
}
#membershipModalPDP.maf-modal-memberships-plans-new .carousel-cell .card{
	border-radius: 15px;
}
#membershipModalPDP.maf-modal-memberships-plans-new .carousel-cell .card h2{
	border-radius: 15px 15px 0 0;
}
#membershipModalPDP.maf-modal-memberships-plans-new button.accordion-button{
	font-size: 3.3vw;
}
#membershipModalPDP.maf-modal-memberships-plans-new #btnAlreadyHavePlan{
	border:none;
    padding: 0;
    border-bottom: solid 1px;
}
.flickity-enabled.is-draggable .flickity-viewport{
	height: 1200px !important;
}
#membershipModalPDP.maf-modal-memberships-plans-new img.icons{
	width:100%;
}
#membershipModalPDP.maf-modal-memberships-plans-new .ma-icons-wrap{
	padding-left: 5px;
    text-align: left;
    max-width: 17%;
    padding-right: 10px;
}
#membershipModalPDP.maf-modal-memberships-plans-new .maf-important-heading{
	font-size: 1.33em;
}
#membershipModalPDP.maf-modal-memberships-plans-new .message-tertiary{
	padding-right: 7px;
    padding-left: 7px;
}
#membershipModalPDP.maf-modal-memberships-plans-new .btn {
	white-space: nowrap;
    font-size: 0.8em;
}
#membershipModalPDP.maf-modal-memberships-plans-new .carouselPlans .btn {
    font-size: 1.23em;
}

}

@media screen and (max-width: 678px) {
	.flickity-enabled.is-draggable .flickity-viewport{
		height: 1000px !important;
	}	
}

@media screen and (max-width: 450px) {
	.flickity-enabled.is-draggable .flickity-viewport{
		height: 800px !important;
	}
}
#plans-compare .px-lg-3 {
	padding-left: 0rem !important;
	padding-right: 0rem !important;
}
