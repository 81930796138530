/*
    @package MEDICALERTTHEMEPREMIUM
    ===============================
        DONATION TEMPATE SCSS
    ===============================
*/



.page-template-templatesdonation-page-php .maf-component-donate.maf-component-fix-mob-down {
    display:none;
}
.maf-component-donation-msg1 figure figcaption quote{
    font-weight: bold;
    font-weight: 600;
}
.maf-component-donation-msg1 figure figcaption cite{
    font-size: 0.86em;
    margin: 9% 0 0;
    display: block;
    color: var(--color-grey4)
}

.maf-component-donation-msg1 div.maf-radio-options{
    flex:1;
    text-align: left;
    padding: 3%;
}
.maf-component-donation-msg1 figure{
    background: url("https://blog.medicalert.org/wp-content/uploads/2021/10/donate-hero.jpg");
    background-size: cover;
    background-position: 100% 0;
    padding-top: calc((879% / 1230) * 50);
}
.maf-component-donation-msg1 .maf-quote > span{
    position: absolute;
    left: 1%;
    top: 14%;
    color: var(--color-white);
    font-weight: bold;
    font-weight: 600;
    font-size: 6em;
    line-height: 0;
}
.maf-component-donation-msg1 figure figcaption{
    position: absolute;
    top: 5%;
    left: 2%;
    background: #1d3557e8;
    color: var(--color-white);
    width: 56%;
    padding: 8% 4%;
    text-align: left;
}

@media (min-width: 768px) {
    .maf-component-donation-msg1 .maf-donation-hero-wrap > div{
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .maf-component-donation-msg1 figure{
        flex:1.5;
    }
    .maf-component-donation-msg1 figure figcaption{
        font-size: 1.73vw;
        top: 16%;
        left: 1%;
    }
}
@media (min-width: 992px) {
    .maf-component-donation-msg1 figure figcaption{
        font-size:1.33vw;
        width: 20%;
        top: 5%;
        background: var(--color-success);
        padding: 4.8% 2.3%;
    }
}
@media (min-width: 1200px) {
    .maf-component-donation-msg1 figure figcaption{
        font-size:1em;
    }
}
@media (max-width: 768px) {
    .maf-component-donation-msg1 figure{
        padding:0;
    }
    .maf-component-donation-msg1 figure figcaption{
        top: 0;
        width: 100%;
        position: initial;
        padding: 14% 4%;
    }
    .maf-component-donation-msg1 .maf-quote > span{
        top: 7%;
    }
}
