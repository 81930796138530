/*
    @package MEDICALERTTHEMEPREMIUM
    ==================================
        TOP GLOBAL BANNER COMPONENT
    ==================================

*/
.header-banner {
    background-color: #222;
    text-align: center;
    color: #fff;
}
.maf-global-banner p{
    margin:0;
    .maf-link-underline{
        border-bottom: solid 1px;
        &:hover,
        &:focus{
            text-decoration: none;
            border-bottom: solid 1px;
        }
    }
}
.prom-message a{
    text-decoration: none;
}
.prom-message span a{
    color: var(--color-info);
    font-weight: 600;
    text-decoration: none;
}
.header-banner .close-button {
    width: 2.125em;
}
.header-banner .container {
    padding: 0;
}
.header-banner .close-button .close {
    opacity: 1;
    color: #fff;
    width: 100%;
    height: 100%;
    background-color: transparent;
}
.header-banner .close-button .close span{
    color: inherit;
}
.maf-global-banner p{
    font-size: 0.93em;
}

@media (min-width: 768px) {
    
.maf-global-banner p{
    font-size: 1em;
}

}
