/*
    @package MEDICALERTTHEMEPREMIUM
    ===================================
        ALL HALF HERO CONTENT BLOCK
    ===================================

*/


.maf-content-component-half-hero h1,
.maf-content-component-half-hero h2,
.maf-content-component-half-hero p{
    color: #ffffff;
    margin:0;
}
.maf-content-component-half-hero{
    figure{
        width: 100%;
        padding-top: calc((350% / 629) * 100);
        background-repeat: no-repeat !important;
        background-size: contain !important;
    }
    p{
        text-transform: capitalize;
    }
}
.maf-organism-main-body .maf-content-component-half-hero h1{
    font-size: 3.4em;
    margin: 0 0 2%;
    font-family: var(--h1-layout-content);
    line-height: 1.3;
    text-transform: capitalize;
    font-weight: bold;
    font-weight: 900;
}
.maf-content-component-half-hero .maf-content-component-main-wrap > div{
    flex: 1;
}
.maf-content-component-main-wrap{
    text-align: center;
}
.maf-hero-half{
    padding: 5% 0 0;
}

// [START] HALF HERO 1
.maf-content-component-half-hero-1{
    background: var(--color-info);
    font-size: 3vw;
}
// [END] HALF HERO 1

@media (min-width: 768px) {
    .maf-content-component-half-hero-1{
        font-size: 1vw;
        .maf-content-component-main-wrap{
            display: flex;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: -webkit-box-flex;
            align-items: center;
            -webkit-align-items: center;
            -ms-align-items: center;
            justify-content: center;
            -webkit-justify-content: center;
            -ms-justify-content: center;
            .maf-hero-half{
                padding: 0;
            }
        }
    }

}

@media (min-width: 992px) {
    .maf-content-component-half-hero-1{
        font-size: 1em;
    }
}
