.button-custom {
    min-width: 177px;
}

.maf-Button-text {
    color: #fff;
}

.entered {
    display: block !important;
}

.text-info {
    font-family: "SourceSansPro Semibold" !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.2 !important;
    letter-spacing: -0.71px !important;
}
.cards-header-text {
    font-weight: 600 !important;
}
.maf-message-category-card{    
    & > div{
        justify-content: space-between;     
        .maf-left{            
            h2{
                // width:75% !important;
            }            
        }
    }
}

.owl-dots{
    display: none;
}

.owl-nav{
    margin-top: 0 !important;
}

span{
    color: unset;
}

.text-info-header{
    line-height: 1.14;
    letter-spacing: -1px;
}

.home-everyday-hero{
    margin-bottom: 76px;
    @media (max-width: 768px) {
        margin-bottom: 56px;
    }
    &__container{
        display: flex;
        align-items: center;
        gap: 96px;
        margin-bottom: 76px;
        margin: auto;
        max-width: 1176px;
        @media (max-width: 1176px) {
            margin: 0 16px;
        }
        @media (max-width: 1024px) {
            gap: 32px;
        }
        @media (max-width: 690px) {
            flex-wrap: wrap;
        }
        .home-everyday-hero__image{
            width: 100%;
            min-width: 348px;
            max-width: 348px;
            min-height: 511px;
            @media (max-width: 690px) {
                min-width: 100%;
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
        .home-everyday-hero__content{
            h2{
                font-family: "SourceSansPro Semibold" !important;
                margin-bottom: 40px;
                font-size: 56px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: -1px;
                text-align: left;
                @media (max-width: 772px) {
                    font-size: 36px;
                    line-height: 1.33;
                    margin-bottom: 16px;
                }
            }
            p{
                font-family: "SourceSansPro Regular" !important;
                margin-bottom: 24px;
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #051424;
                @media (max-width: 772px) {
                    font-size: 18px;
                }

            }
            .short-line{
                font-family: "SourceSansPro Semibold" !important;
                margin-bottom: 40px;
                font-size: 21px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #051424;
                @media (max-width: 772px) {
                    font-size:  21px;
                    margin-bottom: 26px;
                }

            }
        }
    }
}