/*
    @package MEDICALERTTHEMEPREMIUM
    ========================================
        PLP BANNER DEFAULT COMPONENT SCSS
    ========================================

*/

a.maf-text-link-default{
    font-weight: bold;
    font-weight: 600;
    border-bottom: solid 1px;
    line-height: 1;
}
.maf-components-banner-plp-default img{
	width: 63%;
}
.maf-components-banner-plp-default .maf-icons span{
	&.maf-subheader{
		font-weight: 800;
		font-size: 1.33em;
		line-height: 1;
    	margin: 0 0 2%;
	}
	&.maf-plus-sign{
		font-size: 3.5em;
	}
}
.maf-components-banner-plp-default .maf-icons{
	display: -ms-flex;
	display: -webkit-flex;
	display: flex;
  	align-items: center;
  	justify-content: center;
	text-align: center;
	font-size: 0.53em;
}

.maf-components-banner-plp-default figure{
	padding: 1% 0;
}
.maf-components-banner-plp-default figcaption{
	text-align: center;
	margin: 0.75em 0 0;
}
.maf-components-banner-plp-default figcaption span{
	display: block;
}
.maf-components-banner-plp-default > div:nth-of-type(2){
    background: var(--color-white);
	padding: 0% 3% 0;
	font-size: 2vw;
    margin: 4% 0;
}
.maf-components-banner-plp-default > div:nth-of-type(1){
	padding: 0;
    background: #ffffff;
    text-align: left;
}
.maf-components-banner-plp-default > div:nth-of-type(1) p,
.maf-components-banner-plp-default > div:nth-of-type(1) a{
	color: var(--color-success);
}
.maf-plp-banner-wrap{
    margin: 0 0 1em;
}
.maf-button{
	margin: 1em 0 0;
}
.maf-plp-banner-wrap{
	blockquote{
		font-weight: bold;
		font-weight: 600;
		font-size: 2.5em;
		margin: 0;
		color: var(--color-success);
		line-height: 1;
		cite{
			color: var(--color-success);
		}
	}
	h2{
		font-weight: bold;
		font-weight: 600;
		font-size: 2em;
		color: #1D3557;;
	}
}

@media (min-width: 992px) {
	.maf-button-m-m0{
		margin: 0;
	}
	.maf-components-banner-plp-default{
		margin: 0% 0 2em;
	}
	.maf-components-banner-plp-default > div:nth-of-type(1){
		padding: 3.5em 2% 2%;
	}

	span.maf-brake-desktop{
		display: block;
	}
	.maf-components-banner-plp-default > div:nth-of-type(2){
		font-size: 1.1vw;
		margin: 0;
	}
	.maf-components-banner-plp-default{
		display: -ms-flex;
		display: -webkit-flex;
		display: flex;
		overflow: auto;
		overflow: auto;
  		align-items: center;
  		justify-content: center;
		> div:nth-of-type(2){
			text-align: center;
		}
	}
	.maf-components .maf-col-2{
		flex: 1.5;
		order: 0;
	}
	.maf-components .maf-float-right{
		order: 1;
		flex: 3;
	}
 	.collapse.dont-collapse-sm {
		display: -ms-flex;
		display: -webkit-flex;
		display: flex;
    	height: auto !important;
    	visibility: visible;
  	}
  	.maf-btn-toggle{
  		display: none;
  	}
	.maf-plp-banner-wrap {
		div.maf-element-h1{
			position: absolute;
			width: 100%;
			padding: 1em 4% 0;
			h1.header.page-title{
				text-align: left;
				padding: 0;
				color: var(--color-white);
				width: 100%;
				margin: 0;
			}
		}
	}
	.maf-components-banner-plp-default .maf-icons{
		font-size: 0.75em;
	}
}
@media (min-width: 1200px) {
	.maf-components-banner-plp-default > div:nth-of-type(2){
		font-size: 0.65em;
	}
	.maf-components-banner-plp-default .maf-icons{
		font-size: 1em;
	}
}
@media (max-width: 768px) {
	#maincontent{
		.maf-components-banner-plp-default-wrap{
			.maf-element-h1,
			.maf-btn-toggle{
				text-align: center;
				a.maf-btn-toggle{
					margin: 0;
				}
			}
			.maf-components-banner-plp-default>div:nth-of-type(1){
				padding: 1em 0;
			}
		}
		.maf-component-membership-plans{
			.maf-button{
				text-align: center;
				a{
					margin: 0;
				}
			}
		}
		.maf-components-banner-plp-default{
			> div{
				&.maf-float-right{
					.maf-header{
						text-align: center;
						font-size: 1.45em;
					}
					.maf-icons{
						font-size: 2.85vw;
					}
				}
			}
		}
	}
}
