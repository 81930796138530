/*
    @package MEDICALERTTHEMEPREMIUM
    ==================================
        DONATION COMPONENT SCSS
    ==================================

*/

.maf-donation-component-default-form,
.maf-donation-component-default{
    input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }
    label {
        display: inline-block;
        padding: 10px 20px;
        border: 2px solid #444;
        border-radius: 7px;
        font-weight: bold;
        font-weight: 600;
        min-width: 94px;
        text-align: center;
    }
    label:hover {
        background-color: var(--color-light-blue);
        cursor:pointer;
    }
    input[type="radio"]:checked + label {
        background-color: var(--color-success);
        color:var(--color-white);
    }
    input.btn-danger{
        display: inline-block;
        font-size: 0.98rem;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-weight: bold;
        font-weight: 600;
        text-decoration: none;
        min-width: 233px;
        min-height: 41px;
        margin: 1.5em 0.3em 0;
        box-shadow: 1px ​1px 2px 0px transparent;
        padding: 0.75rem 1.5rem;
        outline: none;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        line-height: 1;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        position: relative;
        z-index: 2;
    }
    h2{
        font-weight: bold;
        font-weight: 900;
        font-size: 2.3em;
        margin: 0 0 1em;
        letter-spacing: 0;
    }
    input#customdonationinput,
    input#monthly_donation_other_field,
    input#onetime_donation_other_field{
        padding: 10px 20px;
        width: 100%;
    }
    div.maf-donate-button{
        margin: 6% 0 0;
        input{
            margin: 1.5em 0 0;
        }
    }
    .nav-pills {
        .nav-item{
            a{
                &.nav-link{
                    border: solid 1px;
                    color: var(--color-success);
                    border-color: var(--color-success);
                    &.active{
                        color: var(--color-white);
                        background-color: var(--color-success);
                    }
                    &:hover{
                        color: var(--color-black);
                        background-color: var(--color-light-blue);
                        &.active{
                            color: var(--color-white);
                            background-color: var(--color-success);
                        }
                    }
                    &:focus{
                        color: var(--color-white);
                        background-color: var(--color-success);
                    }            
                }
            }
        }
        .show{
            >.nav-link{
                color: var(--color-white);
                background-color: var(--color-success);
            }
        }
    }
    > ul{
        li{
            width: 49%;
            text-align: center;
            &:nth-of-type(2){
                margin: 0 0 0 2%;
            }
        }
    }
}
