/*
    @package MEDICALERTTHEMEPREMIUM
    =======================================
        RECENT POST 3 COLUMNS COMPONENT
    =======================================

*/

section.maf-component-recent-quotes-3-colum{
    margin: 0 0 8em;
    width:100%;
    article{
        position: relative;
        .maf-excerpt-container{
            position: absolute;
            bottom: -4em;
            width: 100%;
            .maf-excerpt-wrapper{
                background: var(--color-info);
                border-radius: 0 0 30px 30px;
                color: white;
                padding: 6%;
                min-height: 155px;
                position: relative;
                span.maf-quote{
                    color: var(--color-white);
                    position: absolute;
                    font-size: 6em;
                    line-height: 0;
                    font-weight: bold;
                    font-weight: 900;
                    &.maf-left-quote{
                        top:0.05em;
                        left:0;
                    }
                    &.maf-right-quote{
                        bottom:-0.2em;
                        right:0;
                    }
                }
            }
        }

        figure{
            filter: grayscale(1);
            -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
            &:hover{
                filter: none;
                -webkit-filter: grayscale(0%);
            }
            img{
                border-radius: 30px 30px 0 0;
            }
        }

    }
}


@media (min-width: 768px) {
    .maf-component-recent-quotes-3-colum > div.maf-component-cb-body{
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        article{
            flex:1;
            margin: 2%;
        }
    }
}

@media (max-width: 768px) {
    .maf-component-recent-quotes-3-colum{
        article{
            margin: 0 auto 7em;
            width: 94%;
        }
    }
}
