/*
    @package MEDICALERTTHEMEPREMIUM
    ==================================
            FONTS ELEMENTS SCSS
    ==================================

*/

/* ======================== */
/*        FONTS GENERAL     */
/* ======================== */

h1,
h2,
h3,
a,
p,
span,
dd,
button,
label,
font,
li,
quote,
cite {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: var(--skin-body-font);
  margin-top: 0;
}

// .maf-banner-wrap {
//     a{
//         font-family: "Source Sans Pro", sans-serif;
//     }
// }
p,
span,
dd,
button,
label,
font {
  color: var(--color-black);
  &.has-celadon-blue-color {
    color: var(--color-info);
  }
}
h1,
h2,
h3 {
  color: var(--color-success);
}
a {
  color: var(--color-info);
  font-weight: bold;
  font-weight: normal;
}

/* ======================== */
/*        FONTS COLORS     */
/* ======================== */
.has-text-color.has-white-color {
  color: var(--color-white);
}
.has-text-color.has-rose-madder-color {
  color: var(--color-danger);
}

/* ======================== */
/*        ICONS FONTS       */
/* ======================== */

.maf-need-help {
  display: inline-block;
  font-weight: bold;
  font-weight: 500;
  i {
    display: block;
    text-align: center;
  }
}
// For span fontfamily
.fid-main__right span,
.secondary-heading span,
.main-heading span,
.fid-protection__heading span,
.fid-plans__cards span , #shop_id span{
  font-family: "SourceSansPro SemiBold" !important;
}
.fid-getStarted__left__points span , .maf-text-links-list a{
  font-family: "SourceSansPro Regular";
  font-weight: normal !important;
}
.fid-main__left .shop-button{
    text-decoration: none;
}

