/*
    @package MEDICALERTTHEMEPREMIUM
    ===============================
        CONDITION LAYOUTS SCSS
    ===============================
*/


/*
====================================
    GUTTENBERG BLOCKS OVERWRITE
====================================
*/

.maf-page-layout-content-condition .wp-block-columns{
    width: 94%;
    padding-right: 15px;
    padding-left: 15px;

}
.maf-page-layout-content-condition .maf-content-component-half-hero{
    margin:0;
}
.maf-page-layout-content-condition{
    h2{
        font-size: 2.03em;
        font-weight: bold;
        font-weight: 900;
    }
}
