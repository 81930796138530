/*
    @package MEDICALERTTHEMEPREMIUM
    ===============================
            CHECKOUT SFCC SCSS
    ===============================

*/
.form-group{
    span{
        color: var(--color-grey6);
    }
}
#checkout-main{
    .shipping-section{
        .shipping-method-block{
            margin: 1em 0 0;
            h3{
                font-weight: bold;
                font-weight: 600;
            }
            .leading-lines .start-lines:before{
                content:"";
            }   
        }
    }
    .shipping-summary{
        .summary-details{
            .start-lines:before{
                content:"";
            }
        }
    }
    .next-step-button{
        .maf-form-check{
            margin: 0 auto 0.5em;
        }
        button{
            margin: 0;
        }
    }
}
.maf-error-message label{
    color:var(--color-danger);
}

.maf-checkout-create-account-background{
    .maf-price-total{
        overflow: auto;
        background: white;
        padding: 1em;
        margin: 0 0 1em;
    }
}

#maincontent{
    .data-checkout-stage {
        // Shipping 
        &[data-checkout-stage=payment]{
            .address-selector-block {
                .btn-show-details,
                .btn-add-new {
                    border: solid;
                    padding: initial;                    
                    min-width: auto;
                    width: 46%;
                    max-width: 100%;
                    margin: 1em 0 0;
                    -ms-flex: 0 0 48%;
                    flex: 0 0 48%;
                }
                .btn-add-new{
                    margin-left: 4%;
                }
            }
        }
        &[data-checkout-stage=shipping] {
            .shipment-selector-block{
                .btn-show-details,
                .btn-add-new {
                    border: solid;
                    padding: initial;                    
                    min-width: auto;
                    width: 46%;
                    max-width: 100%;
                    margin: 1em 0 0;
                    -ms-flex: 0 0 48%;
                    flex: 0 0 48%;
                }
                .btn-add-new{
                    margin-left: 4%;
                }                
            }
        }
    }
}

// [START] CONFIRMATION PAGE
#maincontent{
    .container.receipt{
        padding: 2em 15px;
        .col-lg-6{
            margin: 0 auto;
        }
    }
}

// [END] CONFIRMATION PAGE

@media screen and (max-width: 768px){
    .maf-navbar-header-checkout{
        //width:49%;
        padding: 0;
    }
    #checkout-main{
        .next-step-button{
            position:fixed;
            bottom:0;
            width: 100%;
            z-index:99;
            background-color: hsla(0,0%,100%,.95);
            box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
            padding: 1rem;
            margin-left: inherit;
            div{
                padding: 0;
                &.maf-form-check{
                    width: 90%;
                }
                button{
                    margin: 0;
                }
            }            
            
        }
        .info-icon .icon{
            background-color: var(--color-info);
        }
    }
}
