/*
    @package MEDICALERTTHEMEPREMIUM
    ===============================================
        TWO CULUMNS CONDITIONS CONTENT BLOCK CMS
    ===============================================

*/

.maf-two-columns-condition{
    &> div{
        padding: 9% 0;
    }
}
@media (min-width: 768px) {
    .maf-two-columns-condition{
        &> div:nth-of-type(1){
            padding: 0 3% 0 0;
        }
        &> div:nth-of-type(2){
            padding: 5% 0 0 6%;
            border-left: solid 1px var(--color-grey5);
            img{
                width: 73%;
            }
        }
    }
}
