/*
    @package MEDICALERTTHEMEPREMIUM
    ==================================
           UTILITY NAV COMPONENT
    ==================================

*/

.maf-organism-utility-nav{
    background: var(--color-grey3);
}
.maf-c-nav-utility{
    list-style: none;
}
.maf-c-nav-utility li{
    font-size: 1em;
    border-right:solid 1px var(--color-grey4);
    a{
        font-size: 1.33em;
    }
}
.maf-c-nav-utility{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -webkit-box-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
}
@media (max-width: 992px) {
    .maf-c-nav-utility-mobile{
        padding: 0;
        margin: 0;
        .dropdown-menu{
            left: initial;
            right: 0;
            padding: 0.5em 1em;
        }
    }
}