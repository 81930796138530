/*
    @package MEDICALERTTHEMEPREMIUM
    ==================================
           MODALS COMPONENT SCSS
    ==================================
*/
.modal{
    .modal-dialog{
        max-width: 920px;
    }
}
.modal-background{
    z-index: 9;
}
.modal .modal-title
.modal .modal-body h3,
.modal .full-pdp-link{
    font-size: 1em;
    font-weight: bold;
    font-weight: 600;
}
.modal .modal-body,
.modal .maf-modal-body{
    padding: 2em 1em 3em;
}
.modal .modal-header{
    background: var(--color-info);
}
#editProductModal{
    .modal-header{
        background: none;
        border: none;
        button{
            span{
                color: var(--color-black);
            }
        }
    }   
}
.modal .modal-title{
    color:#fff;
}
.modal#noPlanConfirmationModalPDP .modal-title{
    color: var(--color-success);
}
.quick-view-dialog{
    .modal-body{
        .product-detail{
            width: 100%;
            padding: 0;
            margin: 0 auto;
        }
    } 
}
.modal .modal-header-alert{
    background: var(--color-danger);
}
.modal .modal-header-primary{
    background: var(--color-primary);
}
.modal .modal-header,
.modal .modal-header .modal-title,
.modal .modal-header .full-pdp-link,
.modal .modal-header button,
.modal .modal-header button span{
    color:white;
    text-shadow:none;
}
.modal .modal-body h3{
    font-size: 1.2em;
}
.modal-dialog button.close{
    position: absolute;
    right: 1rem;
}
.modal .modal-body div.maf-button{
    position: absolute;
    bottom: -1em;
    left: 0;
    width: 100%;
}
.maf-component-modal{
    .nav-tabs{
        margin-bottom: 1rem;
        .nav-item{
            &>.nav-link{
                color:var(--color-black);
                &.active{
                    color:var(--color-white);
                    background-color: var(--color-success);
                    border-bottom: .188em solid var(--skin-primary-color-1);
                }
                &:hover{
                    color:var(--color-black);
                    background-color: var(--color-light-blue);
                    border-bottom: 0.188em solid var(--color-secondary);
                }
                &:focus{
                    color:var(--color-white);
                    background-color: var(--color-success);
                    border-bottom: .188em solid var(--skin-primary-color-1);
;                }
            }
        }
    }
}
.add-to-cart-messages{
    z-index: 99999;
}
#couponBeingAdded{
    .modal-dialog{
        max-width: 20em;
        text-align: center;
        width: 100%;
        margin: 4em auto;
        .modal-content{
            background: #f0f9f9;
            .modal-body{
                padding: 1em;
                p{
                    margin: 0;
                    font-size: 1em;
                    font-weight: bold;
                    font-weight: 600;
                }
            }
        }
    }
}
#maf-transaction-modal{
    text-align: center;
    img{
        width: 33%;
        margin: 1em;
    }
    .progress{
        height: auto;
        margin: 1em auto 1.5em;
        width: 70%;
        .progress-bar{
            width: 70%;
            background-color: var(--color-green);
        }
    }
}

// [START] Flickity SLIDER CAROUSEL
.carouselPlans {
    background: #FAFAFA;
}

.carousel-cell {
    width: 104%;
    height: 43em !important;
    // background: #8C8;
}

.carousel.is-fullscreen .carousel-cell {
    height: 100%;
}
// [END] Flickity SLIDER CAROUSEL

@media (min-width: 768px) {
    .modal .modal-title{
        font-size: 1.3em;

    }
}
@media (max-width: 768px) {
    .quick-view-dialog {
        .product-detail{
            width: 100%;
        }
        .modal-body{
            max-height: 100%;
            .maf-donation-component-default{
                .maf-donate-button{
                    input.btn-danger{
                        max-width: 100%;
                        width: 100%;
                        min-width: 100%;
                        font-size: 3.2vw;
                        margin: 0;
                    }
                }
            }
        }
    }
    #sizeChartModal{
        .brecelt_im{
            float: none;
            margin-top: -2em;
            img{
                width: 44%;
            }
        }
    } 
    // [START] Flickity SLIDER CAROUSEL
    .carouselPlans .carousel-cell {
        font-size: 3vw;   
    }
    .carouselPlans .carousel-cell-basic{
        left: 32%!important;
    }
    .carouselPlans .carousel-cell-basic.is-selected{
        left: 0%!important;
    }
    .carouselPlans .carousel-cell-advantage{
        left: 0%!important;
        z-index: 2;
    }
    .carouselPlans .carousel-cell-advantage-plus{
        left: -22%!important;
    }
    .carouselPlans .carousel-cell-advantage-plus.is-selected{
        left: 0%!important;
    }
    // [END] Flickity SLIDER CAROUSEL
}
