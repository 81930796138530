/*
    @package MEDICALERTTHEMEPREMIUM
    ==================================
        MAIN NAVIGATION COMPONENT
    ==================================

*/
.main-menu{
    position: relative;
    z-index: 99;
}

.main-menu .navbar .close-menu button,
.main-menu .navbar .close-button button {
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}

// CLOSE MENU
.menu-toggleable-left .close-menu {
    padding: 15px;
    background-color: #eee;
    border-bottom: 1px solid #ccc;
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
}

.menu-toggleable-left li > .close-menu {
    margin-right: 0;
    margin-top: -0.6rem;
    margin-left: 0;
}

.multilevel-dropdown .navbar > .close-menu > .back {
    display: none;
}

.multilevel-dropdown .close-menu .back .caret-left {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-left: 0.3 solid transparent;
    width: 0;
    height: 0;
    display: inline-block;
    margin-bottom: 0.125em;
}

.maf-multilevel-dropdown .maf-megamenu{
    border:none;
}

.maf-multilevel-dropdown .maf-megamenu > li:nth-child(even){
    background:var(--maf-mob-nav-submenu);
}

a[aria-expanded="true"]{
    background:var(--maf-nav-bg-primary);
    color:var(--maf-nav-font-primary);
}


.nav-item > .nav-link {
    // color: var(--maf-o-footer-legal_nav-text);
    font-weight: bold;
    font-weight: 600;
}

.maf-wp-navbar-nav{
    float: left;
}
.nav-item{
    span.user-message{
        // color: #ffffff;
        font-weight: bold;
        font-weight: 600;
    }
}
.maf-wp-dropdown-toggle:after{
    margin-left: -0.1em;
}

.multilevel-dropdown .dropdown-item{
    padding:0;
    a.dropdown-link{
        padding: .425em 5em .425em 1em;
        &:hover{
            text-decoration: none;
            background: var(--maf-nav-bg-primary-hover);
            color: var(--maf-nav-font-primary);
        }
    }
}

// [START] Progress Bar
.progress-container {
    width: 100%;
    height: 8px;
    background: #F8F7F5;
}
.progress-bar {
    height: 8px;
    width: 0%;
}
// [END] Progress Bar

// [START] Sticky Bar
.sticky{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1021;
    box-shadow: -4px 2px 17px 0px #818181;
}
.sticky-next{
    top:0em;
}
.page-template-home-page #maf_header_nav{
    top:0;
}
// [END] Sticky Bar


.user-message{
    color: #000000;
}


@media (min-width: 768px) {
    // .dropdown:hover .dropdown-menu {
    //     display: block;
    // }
    .navbar.bg-inverse .navbar-nav .nav-item + .nav-item {
        margin-left: 0;
    }
    .navbar.bg-inverse .navbar-nav .nav-link {
        padding: 1rem 0.6rem;
        white-space: nowrap;
        cursor: default;
    }
    .navbar-expand-lg {
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
        -ms-flex-pack: start;
            justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: row;
            flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
    }

    // CLOSE
    .menu-toggleable-left .close-menu {
        display: none;
    }
    .maf-multilevel-dropdown .dropdown-menu{
        top: 95%;
        font-size: .93rem;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 2px 2px 15px 5px rgba(0,0,0,.15);;
        -webkit-box-shadow: 2px 2px 15px 5px rgba(0,0,0,.15);;
        -moz-box-shadow: 2px 2px 15px 5px rgba(0,0,0,.15);
    }

    // .nav-item .nav-link:hover,
    // .nav-item .nav-link:focus,
    // .nav-item.show .nav-link {
    // background-color: var(--maf-nav-bg-primary-hover);
    // color: var(--maf-nav-font-primary);
    // }
    .maf-multilevel-dropdown .maf-megamenu > li:nth-child(even){
        background:none;
    }
    .user-message{
        color: var(--color-white);
    }
    .maf-wp-nav-item-login{
        float: left;
        display: flex;
        a{
            padding: 1rem 0.6rem;
            color: var(--color-white);
            span{
                color: var(--color-white);
            }
        }
    }

    // [START] FOOTER NAVIGATION
    footer ul.collapse:not(.show),
    footer ul.collapsing{
        display: block;
        height: auto !important;
        visibility: visible;
    }
    // [END] FOOTER NAVIGATION
}

@media (min-width: 992px) {
    .user-message{
        color: var(--color-black);
    }
}

@media (max-width: 768px) {


    // [START] FOOTER NAVIGATION
    .collapse:not(.show) {
        display: none;
    }
    .collapsible-xs .title::after {
        display: block;
        clear: both;
        content: "\F078";
    }
    .footer-item h3.title::after {
        float: right;
        content: "\F077";
        font-family: "FontAwesome";
    }
    .footer-item h3.collapsed::after {
          content: "\F078";
          margin-top: -0.125em;
    }
    // [END] FOOTER NAVIGATION

    // [START] WORDPRESS ONLY
    .maf-organism-main-nav .main-menu{
        position: relative;
        overflow: auto;
        min-width: 113%;
    }
    .maf-organism-main-nav .main-menu > .container > .row{
        display:block;
    }

    // [END] WORDPRESS ONLY
}
