/*
    @package MEDICALERTTHEMEPREMIUM
    ===============================
        PROGRESS BARS ELEMENTS
    ===============================

*/
@keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0; }
    to {
      background-position: 0 0; }
}
.progress {
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.1875rem;
}
.progress-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color:var(--maf-progress-bar);
    transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
    .progress-bar-animated {
        animation: none;
    }
}
.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}
.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
}


// [START] Progress Bar
.progress-container {
    width: 100%;
    height: 8px;
    background: #F8F7F5;
}
.progress-bar {
    height: 8px;
    width: 0%;
}
// [END] Progress Bar
