/*
    @package MEDICALERTTHEMEPREMIUM
    ===============================
        CONDITION LAYOUTS SCSS
    ===============================
*/


/*
====================================
    GUTTENBERG BLOCKS OVERWRITE
====================================
*/

.maf-lifeline-left-column{
    background: var(--color-light-blue);
    padding: 4%;
    border-radius: 30px;
}
.maf-lifeline-right-column{
    padding: 4%;
}
