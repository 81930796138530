/*
    @package MEDICALERTTHEMEPREMIUM
    =============================================
        MOST RECENT HORIZONTAL COMPONENT SCSS
    =============================================

*/

.maf-component-most-recent-horizontal figure{
    padding:0;
}
.maf-component-most-recent-horizontal article{
    width: 100%;
    margin: 0 auto;
    background: #F0F9F9;
}
.maf-component-most-recent-horizontal article .maf-excerpt-wrapper{
    padding: 8% 3%;
    width: 100%;
}
.maf-component-most-recent-horizontal article .maf-image-wrapper{
    padding: 0%;
}
.maf-component-most-recent-horizontal h3{
    font-size: 1.33em;
    font-weight: bold;
    font-weight: 600;
}

// [START] CTAs BY POST TYPE
.maf-component-stories a.maf-element-button-post-more:hover{
    background-color: var(--color-info) !important;
    border-color: var(--color-info) !important;
}
.maf-component-podcasts a.maf-element-button-post-more:hover{
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
}

// [END] CTAs BY POST TYPE

@media (min-width: 768px) {
    .maf-component-most-recent-horizontal article .maf-excerpt-wrapper{
        padding: 3%;
    }
}
