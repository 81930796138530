// HOME PAGE OVERWRITES
#volume-button {
  z-index: 3 !important;
}

// PDP OVERWRITES
li.breadcrumb-item a {
  text-decoration: none;
  background-color: transparent;

  &:hover {
    text-decoration: decoration;
  }
}

div[data-action="Product-Show"] header.maf-organism-header-new,
div[data-action="Product-Show"] header.maf-organism-header-new>nav {
  background: #fff;
}

div[data-action="Product-QuickCart"] header.maf-organism-header-new {
  background: #fff;
}

.maf-link-underline {
  text-decoration: none;
  border-bottom: solid 1px;
  display: inline-block;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

div.product-wrapper {
  .primary-images {}

  .maf-pdp-right-side {
    padding: 0;
  }
}

div.modal-header {
  border-radius: initial;
}

.maf-organism-pdp-more-details {
  &.container {
    padding-right: 15px;
    padding-left: 15px;

    .description-and-detail {
      h2 {
        font-size: 1.53em;
        color: var(--secondary);
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      }

      h3 {
        color: var(--primary);
      }

      p {
        font-size: 1em;
      }
    }
  }
}

.maf-component-engraving-pdp {
  p {
    font-size: 1em;
  }
}

.modal-body-membership {
  a {
    &.btn {
      height: auto;
    }
  }
}

// HEADER
.maf-component-search {
  opacity: 1;
}

//CART
div .row.hide-order-discount,
div .row.hide-shipping-discount {
  display: none;
}

div[data-action="Cart-Show"] header.maf-organism-header-new,
div[data-action="Cart-Show"] header.maf-organism-header-new>nav,
div[data-action="Order-Confirm"] header.maf-organism-header-new,
div[data-action="Order-Confirm"] header.maf-organism-header-new>nav {
  background: #fff;
}

// PLP
section.plp-Accordian-container .plp-Accordian-item {
  height: 100%;
}

div[data-action="Search-Show"] header.maf-organism-header-new,
div[data-action="Search-Show"] header.maf-organism-header-new>nav {
  background: #fff;
}

@media (max-width: 768px) {
  #maincontent {
    .maf-component-pdp-panel {
      .maf-select-plan-pdp-cta {
        display: flex;
        gap: 0.5em;
        justify-content: flex-end;

        button {
          min-width: auto;
        }
      }
    }
  }
}

// landing page
.maf-section-3 .plp-product-column .plp-product-color-count {
  align-items: center;
}

#shop_id .product-title-truncate {
  margin: 0 !important;
}

.fid-header a {
  text-decoration: none;
}

.fid-getStarted__left a {
  text-decoration: none;
}

.fi-lastnote {
  font-family: "SourceSansPro Regular", helvetica, sans-serif !important;
  font-size: 18px !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #051424 !important;

  @media (max-width: 768px) {
    font-size: 16px !important;
  }
}

.refinements .filter-section .filter-options {
  z-index: 9;
}

.bs-canvas-overlay-filter {
  display: none;
  opacity: 0.5;
  z-index: 9;
  top: 0;
  left: 0;
}

#Back-button-billing {
  display: flex;
  align-items: center;
  gap: 8px;
}

.wizardloader,
.pdpLoader,
.billingwizardloader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000082;
  /* Adjust the opacity and color as needed */
  z-index: 99999;
  backdrop-filter: blur(1px);
  /* Adjust the blur intensity as needed */
}

.Billingwizardloader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000082;
  /* Adjust the opacity and color as needed */
  z-index: 99999;
  backdrop-filter: blur(1px);
  /* Adjust the blur intensity as needed */
}

.loaderForWizard {
  top: 40%;
  width: 48px;
  height: 48px;
  display: block;
  margin: 15px auto;
  position: relative;
  color: #fff;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loaderForWizard::after,
.loaderForWizard::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  background-color: #fff;
  border-radius: 50%;
  animation: scale50 1s infinite ease-in-out;
}

.loaderForWizard::before {
  top: auto;
  bottom: 0;
  background-color: #fff;
  animation-delay: 0.5s;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes scale50 {

  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

#couponError {
  padding: 0 !important;
  margin-top: 10px;
}

.Wizard-Payment-Information .card-number-wrapper {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    right: 3px;
    background-repeat: no-repeat;
    background-image: url("../../images/credit.png");
    background-size: contain;
    width: 48px;
    height: 30px;
    top: 44px;
    right: 10px;
  }

  &[data-type="visa"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -162px -110px;
  }

  &[data-type="mastercard"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -295px -110px;
  }

  &[data-type="amex"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -230px -15px;
  }

  &[data-type="discover"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -95px -110px;
  }
}

.plp-Accordian-container .plp-Accordian-header-text {
  min-width: unset !important;
}

.New-PDP-Container {
  .Customize-id-accordian .plp-Accordian-body {
    max-height: 100%;
    overflow: visible !important;
  }

  .priceandreviewContainer {
    .plp-card-container {
      .plp-review-star {
        .star-images {
          gap: 6px;
        }
      }
    }
  }

  .declaration {

    /* Custom checkbox container */
    .custom-checkbox-container {
      display: inline-block;
      position: relative;
      padding-left: 30px;
      /* Space for the checkmark */
      cursor: pointer;
      user-select: none;
      font-size: 14px;
    }

    /* Hide the native checkbox */
    .checkboxField {
      display: none;
    }

    /* Custom checkbox */
    .custom-checkbox-container .checkmark {
      position: absolute;
      top: 50%;
      left: 0;
      width: 24px;
      height: 24px;
      border: 1px solid #051424;
      /* Default border */
      border-radius: 4px;
      /* Optional: rounded corners */
      background-color: #fff;
      /* Default background */
      transform: translateY(-40%);
      transition: all 0.3s ease;
    }

    /* Checked state with image */
    .checkboxField:checked+.checkmark {
      background-color: #1d3557;
      /* Background color */
      border-color: #051424;
      /* Border color */
      background-image: url("../../images/icons-check.webp");
      background-size: 14px 14px;
      /* Set image dimensions (width x height) */
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .plp-card-container .plp-product-row {
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 767px) {
      margin-left: 16px;
      margin-right: 16px;
    }

    @media (min-width: 1201px) {
      max-width: 1176px !important;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 24px !important;
    }

    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      max-width: 690px !important;
    }
  }

  .qrcodeDescription {
    font-family: "SourceSansPro Regular", helvetica, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #051424;
    margin-bottom: 24px;

    @media (max-width: 767px) {
      margin-bottom: 24px;
    }
  }

  .questionContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 24px;

    @media (max-width: 690px) {
      margin-bottom: 24px;
    }

    a {
      text-decoration: none;

      span {
        text-decoration: underline;
      }
    }

    .content {
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
      text-decoration: underline;
    }

    .question-circle {
      display: inline-block;
      width: 16px;
      height: 16px;
      position: relative;
      background-color: #fff;
      /* Circle color */
      border-radius: 50%;
      border: solid 1.5px #051424;
    }

    .question-circle::before {
      content: "?";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: black;
      font-size: 10px;
      /* Adjust the size */
      font-weight: bold;
      font-weight: 700;
      font-family: Arial, sans-serif;
    }
  }

  .questionContainer-qrcode {
    @media (max-width: 690px) {
      margin-bottom: 24px;
    }
  }

  .customizeid-container {
    .product-container {
      .size-containers-mobile {
        display: none;

        @media (max-width: 767px) {
          display: flex;
        }
      }

      .errorState {
        color: #e63946;
      }

      .color-error-state {
        border: solid 2px #e63946;
        border-radius: 50%;
      }

      .size-error-state {
        border-radius: 100px;
        border: solid 2px #e63946;
      }

      .customizeid-sizechart,
      .customizeid-product-color {
        margin-bottom: 24px;

        @media (max-width: 767px) {
          margin-bottom: 24px;
        }

        .size-containers {
          margin-bottom: 24px;
        }

        .title {
          font-size: 21px;
          font-family: "SourceSansPro Semibold", helvetica, sans-serif;
          margin-bottom: 24px;
          line-height: 1;

          @media (max-width: 767px) {
            font-size: 18px;
          }

          .customize-color,
          .customize-size {
            font-family: "SourceSansPro Regular", helvetica, sans-serif;
          }
        }
      }

      .customize-size-container {
        .size-custom-attribute {
          background: none;
          border: none;
        }
      }
    }
  }

  .customizeid-container .product-container .product-colors-images-container {
    overflow: visible !important;
  }

  .product-container-col .size-containers {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 26px;
    flex-wrap: wrap;
    margin-left: 16px;
    margin-top: 16px;

    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      margin-left: 24px;
      margin-bottom: 0px;
    }

    .size {
      display: flex;
      width: auto;
      height: 32px;
      justify-content: center;
      align-items: center;
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      padding: 8px 16px;
      border-radius: 100px;
      background-color: #e9eff3;
      font-size: 16px;
    }

    @media (max-width: 767px) {
      margin-left: 0px;
      margin-bottom: 8px;
      display: none;
    }

    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      max-width: 578px;
    }
  }

  .plp-card-header {
    font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #051424;
    margin-bottom: 24px;

    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      max-width: 690px !important;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .plp-card-container .cards-content .plp-Image-container .plp-product-image {
    mix-blend-mode: darken;
  }

  .plp-card-container {
    .plp-product-column {
      margin-bottom: 0 !important;
    }
  }

  .customizeid-container {
    margin-bottom: 64px;
    margin-top: 40px;

    @media (max-width: 767px) {
      margin-left: 0px;
      margin-right: 0px;
    }

    margin-top: 24px;
    margin-bottom: 40px;

    .product-container {
      .product-Image-container {
        @media (max-width: 767px) {
          margin-bottom: 24px;
        }

        .product-image-parent {
          @media (max-width: 767px) {
            margin-left: 16px;
            margin-right: 16px;
          }
        }

        .product-colors-images-container {
          height: 525px;

          @media (max-width: 767px) {
            height: auto;
          }

          .images-wrapper-container {
            height: 480px;

            @media (max-width: 767px) {
              height: auto;
              overflow: auto;
              scrollbar-width: none;
              /* Firefox */
              -ms-overflow-style: none;
              /* Internet Explorer 10+ */
              margin-left: 16px;
              transition: margin-left 0.3s ease;
              /* Smoothly adjust margin */
            }

            .images-wrapper-container::-webkit-scrollbar {
              display: none;
              /* Safari and Chrome */
            }

            .images-wrapper {
              flex-wrap: nowrap;

              @media (max-width: 767px) {
                gap: 16px;
              }

              .product-colors-image {
                @media (max-width: 767px) {
                  min-width: 64px;
                }
              }
            }
          }
        }
      }
    }

    .Customize-id-accordian {
      margin: 0px !important;
    }

    .product-container .noImage {
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        display: flex;
        flex-direction: column-reverse !important;
        align-items: center;
        gap: 24px;
      }
    }

    .product-container .customizeid-button {
      margin-bottom: 40px;
      display: flex;
      justify-content: end;
      text-align: end;

      @media (max-width: 767px) {
        text-align: center;
        justify-content: center;
      }

      .customizeButtonClass {
        width: auto;
        padding: 16px 40px;
        display: flex;
        align-items: center;
      }

      .alredyMember {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #1d3557;
        text-decoration: underline;
      }
    }

    .product-container .customizeid-product-title {
      margin-bottom: 24px;
      text-transform: capitalize;
    }

    .product-container-col {
      @media (min-width: 1200px) {
        position: sticky;
        top: 88px;
        height: fit-content;
      }
    }

    .customizeid-col {
      position: relative;

      @media (max-width: 767px) {
        margin-left: 16px;
        margin-right: 16px;
      }

      .size-containers-mobile {
        .size-containers {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 26px;
          flex-wrap: wrap;
          margin-left: 16px;
          margin-top: 24px;

          .size {
            display: flex;
            width: auto;
            height: 32px;
            justify-content: center;
            align-items: center;
            font-family: "SourceSansPro Regular", helvetica, sans-serif;
            padding: 8px 16px;
            border-radius: 100px;
            background-color: #e9eff3;
          }

          @media (max-width: 767px) {
            margin-left: 0px;
            margin-bottom: 0px;
            gap: 16px;
          }

          @media only screen and (min-width: 768px) and (max-width: 1200px) {
            max-width: 578px;
          }
        }
      }
    }

    .priceandreviewContainer {
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .prices .plp-product-price .sales{
        cursor: default;
      }

      @media (max-width: 767px) {
        align-items: start;
      }

      .plp-review-star {
        gap: 8px;
      }

      .star-icon {
        font-size: 16px !important;
        height: 16px;
      }

      .star-icon::before {
        font-size: 16px !important;
      }

      .plp-review-text {
        @media (max-width: 767px) {
          font-size: 16px !important;
        }
      }

      .price {
        font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
        font-size: 36px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #457c9d;

        @media (max-width: 767px) {
          font-size: 24px;
        }
      }
    }

    .product-description {
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
      margin-bottom: 24px;

      @media (max-width: 690px) {
        font-size: 16px;
      }

      p {
        font-size: 18px !important;

        @media (max-width: 690px) {
          font-size: 16px !important;
        }
      }
    }

    .EngraveID-Container {
      margin-bottom: 24px;

      .error-message {
        color: #ff0004;
        font-size: 14px;
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        margin: 0;
        margin-bottom: 24px;
      }

      .header {
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 21px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
        margin-bottom: 24px;
        display: flex;
        gap: 5px;

        span {
          font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
        }

        @media (max-width: 767px) {
          font-size: 18px;
        }
      }

      .engrave-cards {
        display: flex;
        gap: 24px;
        margin-right: 24px;

        @media (max-width: 767px) {
          margin-right: 0px;
          gap: 15px;
        }

        .selected-engrave-cards-container {
          border: 2px solid #0f7ef8;
          /* Adjust border color to your preference */
          border-radius: 14px;
          position: relative;
          padding: 14px 68px !important;

          @media (max-width: 768px) {
            padding: 14px 23px !important;
          }
        }

        .selected-engrave-cards-container::before {
          content: "\2713";
          /* Unicode for checkmark */
          font-size: 12px;
          color: white;
          background-color: #0f7ef8;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: -10px;
          left: -10px;

          // @media only screen and (min-width: 768px) and (max-width: 970px) {
          //   display: none;
          // }
          @media (max-width: 768px) {
            width: 20px;
            height: 20px;
            font-size: 12px;
          }
        }

        .cards {
          width: 100%;
          text-align: center;
          flex-grow: 0;
          padding: 16px 70px;
          border-radius: 16px;
          box-shadow: 0 4px 8px 2px rgba(55, 67, 79, 0.04),
            0 -2px 8px 2px rgba(55, 67, 79, 0.05);
          background-color: #fff;
          cursor: pointer !important;
          text-decoration: none;
          -webkit-font-smoothing: auto !important;

          @media (max-width: 767px) {
            padding: 16px 25px;
            max-width: 164px;
            height: 108px;
          }

          img {
            width: 140px;

            @media (max-width: 767px) {
              width: 93px;
              height: 54px;
              margin: auto !important;
            }
          }

          .title {
            font-family: "SourceSansPro Regular", helvetica, sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #051424;
            text-align: center;
            margin-bottom: 16px;

            @media (max-width: 767px) {
              font-size: 14px;
              margin-bottom: 8px;
            }
          }
        }
      }
    }

    .compareBenifits {
      display: flex;
      gap: 5px;
      align-items: center;
      margin-bottom: 24px;

      #tips_qr_vs_engraving-link {
        text-decoration: none;

        span {
          text-decoration: underline;
        }
      }

      &>a {
        text-decoration: none;

        span {
          text-decoration: underline;
        }
      }

      @media (max-width: 767px) {
        margin-bottom: 24px;
      }

      img {
        width: 16px;
        height: 16px;
      }

      .title {
        text-decoration: underline;
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
      }
    }
  }

  .custom-engrave-container {
    .customizeid-button {
      margin-bottom: 0px !important;
    }

    #customizeEngraveid {
      margin-bottom: 0px !important;
    }

    .priceandsize {
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
      margin-bottom: 26px;
      display: flex;
      gap: 16px;
      align-items: center;

      .edit {
        font-size: 16px;
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #457c9d;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .engrave-help {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 24px;

      .header {
        font-size: 18px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      }

      .help {
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #051424;

        a {
          text-decoration: none;

          span {
            text-decoration: underline;

            svg {
              margin-left: 5px;
            }
          }
        }
      }
    }

    #customizeEngraveid {
      margin-bottom: 24px;

      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
    }

    #modern_engraving_form {
      .inputcontainer {
        position: relative;

        .engraveInput {
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          width: 100%;
          height: 48px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 10px;
          margin-bottom: 24px;
          padding: 16px;
          border-radius: 8px;
          border: solid 1.5px #c0c4c8;
          background-color: #fff;
          font-size: 1em;
          font-weight: normal;
          text-transform: uppercase;
        }

        input:focus {
          border: solid 1.5px #457c9d;
          outline: none !important;
        }

        .engraveInput::placeholder {
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          color: #c0c4c8;
          font-weight: normal;
        }

        .count {
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          position: absolute;
          right: 16px;
          top: 16px;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #c0c4c8;
        }
      }

      &.is-invalid {
        .error-message-engraving {
          color: #ff0004;
        }

        input {
          &.engraveInput {
            border-color: #ff0004;
          }
        }
      }
    }

    .previewContainer {
      .header {
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: -0.3px;
        text-align: left;
        color: #828991;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .dataContainer {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: -0.3px;
        text-align: center;
        color: #828991;
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        width: 320px;
        height: 220px;
        flex-grow: 0;
        padding: 26px 59px 26px 58px;
        border-radius: 110px;
        background-color: #e5e7e8;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;

        .inputdata {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;

          .data {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: #051424;
            text-transform: uppercase;
          }
        }
      }
    }

    .declaration {
      &>div {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-bottom: 36px;
        flex-wrap: wrap;

        .checkboxField {
          min-width: 24px;
          min-height: 24px;
          flex-grow: 0;
          border-radius: 4px;
          border: solid 1px #1d3557;
        }

        .text {
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #051424;
        }
      }

      &.is-invalid {
        .checkmark {
          border-color: #ff0004;
        }

        .form-check-label {
          color: #ff0004;
        }

        .invalid-feedback {
          opacity: 1 !important;
          display: flex !important;
          color: #ff0004;
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          font-size: 16px;
        }
      }
    }

    .previewContainer {
      .header {
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: -0.3px;
        text-align: left;
        color: #828991;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .dataContainer {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: -0.3px;
        text-align: center;
        color: #828991;
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        width: 320px;
        height: 220px;
        flex-grow: 0;
        padding: 26px 59px 26px 58px;
        border-radius: 110px;
        background-color: #e5e7e8;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;

        .inputdata {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;

          .data {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: #051424;
            text-transform: uppercase;
          }
        }
      }
    }

    .declaration {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 24px;
      flex-wrap: wrap;

      .engrave-checkbox{
        margin-bottom: 0 !important;
        flex-wrap: nowrap !important;
      }

      // flex-wrap: wrap;
      .checkboxField {
        min-width: 24px;
        min-height: 24px;
        flex-grow: 0;
        border-radius: 4px;
        border: solid 1px #1d3557;
      }

      .text {
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
      }
    }
  }

  .customizeid-container {
    .product-container {
      .product-Image-container {
        .product-colors-images-container {
          .product-colors-image {
            padding: 8px !important;
          }
        }
      }
    }
  }
}

#tips_qr_vs_engraving {
  max-width: 800px !important;

  @media (max-width: 690px) {
    padding: 32px 0px 0px 16px;
    max-width: 328px !important;
  }

  .offcanvas-header {
    @media (max-width: 690px) {
      align-items: baseline !important;
      padding-right: 10px;
    }
  }
}


.offcanvas-body {
  scrollbar-width: none !important;
  /* Hides scrollbar in Firefox */
  -ms-overflow-style: none !important;

  /* Hides scrollbar in Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none !important;
    /* Hides scrollbar in Safari and Chrome */
  }
}

.QR-Engraved-container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .heading {
    display: flex;
    justify-content: space-between;
    font-family: "SourceSansPro Semibold", helvetica, sans-serif;

    &-main {
      height: 21px;
      text-transform: uppercase;
      font-size: 21px;
      font-weight: normal;
      color: #051424;
      line-height: 1;
    }
  }

  .description {
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #051424;
    margin: 0 !important;

    @media (max-width: 690px) {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
      padding-right: 10px;
    }
  }

  .subheading {
    font-size: 40px;
    font-weight: normal;
    font-family: "SourceSansPro Semibold", helvetica, sans-serif;
    line-height: 1;
    margin: 0 !important;
    text-transform: none !important;

    @media (max-width: 690px) {
      font-size: 24px;
      padding-right: 10px;
    }
  }

  &-body {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1.5fr;
    row-gap: 24px;
    align-content: center;

    @media (max-width: 690px) {
      display: none;
    }
  }

  &-image {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 13px;
      width: 120px;
      height: 120px;
      background-color: #f0f9f9;
      border-radius: 999px;

      & img {
        width: 100% !important;
        height: 51px !important;
      }
    }

    &-text {
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      font-size: 21px;
      line-height: 1;
      text-transform: uppercase;
      color:#457b9d;
    }
  }

  .info-box {

    &-left,
    &-right {
      display: flex;
      justify-content: center;
      padding: 16px;
      border-radius: 16px;
      box-shadow: 0 4px 8px 2px rgba(55, 67, 79, 0.04),
        0 -2px 8px 2px rgba(55, 67, 79, 0.05);
      background-color: #fff;
      min-height: 80px;
      margin: auto;
      min-width: 276px;
      width: 100%;

      @media only screen and (min-width: 690px) and (max-width: 800px) {
        min-width: 100%;
        max-width: 276px;
      }
    }

    &-left {
      padding-right: 32px;
      margin-right: -24px;
    }

    &-right {
      padding-left: 32px;
      margin-left: -24px;
    }

    &-middle {
      display: flex;
      justify-content: center;
      position: relative;
      padding: 36px 24px;
      border-radius: 16px;
      box-shadow: 0 4px 8px 2px rgba(55, 67, 79, 0.04),
        0 -2px 8px 2px rgba(55, 67, 79, 0.05);
      background-color: #457c9d;
      line-height: 1;
      color: #fff;
      min-width: 216px;
      width: 100%;

      @media only screen and (min-width: 690px) and (max-width: 800px) {
        min-width: 100%;
        max-width: 276px;
      }

      p {
        color: #fff !important;
        font-family: "SourceSansPro Semibold", helvetica, sans-serif !important;
      }
    }

    &-text {
      text-align: center !important;
      font-size: 16px !important;
      align-self: center !important;
      line-height: 1 !important;
      margin: 0 !important;

      &-bold {
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      }
    }
  }

  .footer {
    height: 21px;
    font-size: 18px;
    line-height: 1.11;

    @media (max-width: 690px) {
      font-size: 14px !important;
      margin-top: 10px;
      margin-bottom: 41px !important;
    }

    &-bold {
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      color: #000;
      text-decoration: underline;
    }
  }
}

.QR-Engraved-container-mobile {
  display: none;

  @media (max-width: 690px) {
    display: flex;
    gap: 6px;
    padding-right: 10px;
  }

  .info-box-mobile-midle,
  .info-box-mobile-last {
    display: flex;
    flex-direction: column;
    padding: 16px 5px;
    border-radius: 16px;
    box-shadow: 0 8px 16px 0 rgba(69, 124, 157, 0.2);
    background-color: #fff;
    line-height: 1;
    gap: 36px;
    color: #fff;
    min-width: 104px;
    width: 100%;
    height: auto;

    .QR-Engraved-container-image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      flex-grow: 0;
      padding: 17px 7.5px 17px 8.5px;
      border-radius: 999px;

      img {
        width: 64px !important;
        height: 46px !important;
      }
    }

    .QR-Engraved-container-image-text {
      font-size: 16px;
      color: #051424;
    }

    .info-box-mobile-midle-text {
      display: flex;
      font-size: 14px;
      line-height: 1;
      text-align: center;
      justify-content: center;
      color: #051424;
      min-height: 84px;
      font-family: "SourceSansPro Regular", helvetica, sans-serif;

      .info-box-text-bold {
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      }

      .info-box-text {
        font-size: 14px !important;
      }
    }
  }

  .info-box-mobile-first {
    display: flex;
    flex-direction: column;
    gap: 45px;
    margin-right: -6px;
    margin-top: 180px;

    .info-box-mobile-text-box {
      justify-content: center;
      padding: 8px 8px 8px 8px;
      border-radius: 16px 0 0 16px;
      box-shadow: 0 4px 8px 2px rgba(55, 67, 79, 0.04),
        0 -2px 8px 2px rgba(55, 67, 79, 0.05);
      background-color: #457c9d;
      min-width: 91px;
      display: flex;
      min-height: 77.9px !important;

      .info-box-text {
        font-size: 14px !important;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      }
    }
  }
}

.New-PDP-Container,
.Pdp-Plan-container {
  .customize-progressbar {
    display: flex;
    gap: 6px;
    justify-content: left;
    align-items: center;
    margin-bottom: 24px;
    flex-wrap: wrap;

    // scroll-margin: 470px 0 0;
    @media (max-width: 374px) {
      justify-content: center;
      flex-wrap: wrap;
    }

    // @media (max-width: 1200px) {
    //   scroll-margin: 170px 0 0;
    // }
    // @media (max-width: 768px) {
    //   scroll-margin: 70px 0 0;
    // }
    @media (max-width: 690px) {
      gap: 0px;
    }

    .wizard-progress {
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #828991;

      @media only screen and (min-width: 690px) and (max-width: 1200px) {
        font-size: 16px;
      }

      @media (max-width: 690px) {
        font-size: 12px;
        line-height: 1.5;
        margin-right: 2px;
        margin-left: 2px;
      }
    }

    .Wizard-confirmation {
      @media (max-width: 690px) {
        margin-right: 0px;
      }
    }

    .Wizard-engrave-bar {
      background-color: #0f7ef8;
    }

    .Wizard-account {
      color: #0f7ef8;

      @media (max-width: 690px) {
        margin-left: 0px;
      }
    }

    .progress-deactive {
      color: #0f7ef8;
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
    }

    .progress-active {
      color: #0f7ef8;
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
    }

    .Wizard-checked-circle-custom::before {
      @media (max-width: 690px) {
        padding-left: 1px;
      }
    }

    .Wizard-checked-circle::before {
      content: "✓";
      font-size: 10px;
      color: #fff;
      background-color: #828991;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media only screen and (min-width: 690px) and (max-width: 1200px) {
        font-size: 10px;
        width: 18px;
        height: 18px;
      }

      @media (max-width: 690px) {
        font-size: 8px;
        width: 10px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-right: 2px;
        // margin-left: 2px;
      }
    }

    .Wizard-checked-circle-qr::before {
      @media (max-width: 690px) {
        font-size: 8px;
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2px;
        margin-left: 2px;
      }
    }

    .Wizard-account-circle::before {
      background-color: #0f7ef8;
    }

    .bar {
      width: 20px;
      height: 1px;
      flex-grow: 0;
      background-color: #828991;

      @media (max-width: 690px) {
        width: 10px;
      }
    }
  }
}

.pdpCheckoutContainer,
.Pdp-Plan-container {
  .plan-container {
    display: flex;
    gap: 17px;
    width: 100%;
    flex-wrap: wrap;

    @media (max-width: 1439px) {
      flex-direction: column-reverse;
      gap: 40px;
    }

    .descriptionPlan {
      padding-top: 157px;
      min-width: 337px;
      max-width: 337px;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: 690px) {
        min-width: 100%;
      }

      @media (max-width: 1439px) {
        padding-left: 16px;
        padding-right: 16px;
      }

      @media (max-width: 1439px) {
        padding-top: 0px;
        max-width: unset;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .description-heading {
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        font-size: 31px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.16;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
        margin-bottom: 40px;

        @media (max-width: 690px) {
          font-size: 28px;
          margin-bottom: 24px;
        }

        span {
          color: #e63946;
          font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        }
      }

      .plans-deatils-container {
        display: flex;
        flex-direction: column;
        gap: 24px;

        @media (max-width: 1439px) {
          flex-direction: column;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: center;
          gap: 0px;
        }

        @media (max-width: 1439px) {
          flex-direction: column;
        }

        @media (max-width: 729px) {
          gap: 0px;
        }

        hr {
          margin: 15.5px 0;
          height: 1px;
          display: none;
          width: 100%;
          max-width: 339px;
          background-color: #c0c4c8;

          @media only screen and (min-width: 690px) and (max-width: 1439px) {
            max-width: 690px;
          }

          @media (max-width: 1439px) {
            max-width: 100%;
            display: block;
          }
        }

        .custom-plan-display {
          @media (max-width: 1439px) {
            display: none !important;
          }
        }

        .plans-deatils-container-custom {
          display: flex;
          flex-direction: column;
          gap: 24px;

          @media (max-width: 1439px) {
            gap: 0px;
          }
        }

        .plans-details {
          display: flex;
          gap: 16px;
          align-items: center;

          @media (max-width: 1439px) {
            max-width: 100%;
            justify-content: space-between;
          }

          .checkImage {
            display: none;

            @media (max-width: 1439px) {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 24px;
              width: 24px;
            }
          }

          img {
            width: 50px;
            height: 50px;

            @media (max-width: 690px) {
              display: none;
            }
          }

          .details-description {
            display: flex;
            flex-direction: column;
            gap: 8px;
            max-width: 643px;

            .heading {
              font-family: "SourceSansPro Semibold", helvetica, sans-serif;
              font-size: 21px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: #457c9d;

              @media (max-width: 690px) {
                font-size: 16px;
              }
            }

            .details {
              font-family: "SourceSansPro Regular", helvetica, sans-serif;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.25;
              letter-spacing: normal;
              text-align: left;
              color: #051424;

              @media (max-width: 690px) {
                font-size: 14px;
              }

              span {
                font-family: "SourceSansPro Semibold", helvetica, sans-serif;
              }
            }
          }
        }
      }
    }

    .container {
      width: auto;

      @media (max-width: 1439px) {
        overflow: hidden;
        width: 100%;
      }
    }
  }

  // Plans
  .fid-plans {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 56px;
    max-width: 1176px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 767px) {
      margin-bottom: 40px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1439px) {
      margin-bottom: 44px;
    }

    .selectandnotselectplanCustom {
      display: flex;
      justify-content: center;

      a {
        text-decoration: none !important;
      }
    }

    .selectandnotselectplan {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      a {
        text-decoration: none !important;
      }
    }

    .dots-container {
      display: none;

      @media (max-width: 1439px) {
        display: flex;
        justify-content: center;
        gap: 10px;
      }

      .dot {
        width: 16px;
        height: 16px;
        flex-grow: 0;
        background-color: #828991;
        cursor: pointer;
        border-radius: 50%;
      }

      .dot.active {
        background-color: #1d3557;
      }
    }

    .btn-container {
      display: none;

      @media only screen and (min-width: 690px) and (max-width: 820px) {
        display: flex;
        justify-content: center;
        gap: 16px;
        margin-top: 24px;
      }

      .btn {
        min-width: 48px;
        max-width: 48px;
        height: 48px;
        max-height: 48px;
        border: solid 1px #1d3557;
        background-color: white;
        padding: 15px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        margin: 0;

        :focus {
          background-color: white;
        }

        :hover {
          background-color: white;
        }
      }
    }

    .fid-header-plans {
      align-self: stretch;
      flex-grow: 0;
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      font-size: 36px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
      margin-bottom: 24px;
      display: flex;
      justify-content: center;
      text-transform: capitalize;

      @media only screen and (min-width: 690px) and (max-width: 1439px) {
        max-width: 100%;
        margin-left: 72px;
        margin-right: 72px;
        text-align: center;
      }

      @media (max-width: 690px) {
        font-size: 21px;
        margin-bottom: 40px;
        text-align: center;
        margin-left: 16px;
        margin-right: 16px;
      }
    }

    &__heading {
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      font-size: 56px;
      line-height: 64px;
      letter-spacing: -1px;
      color: #051424;
      margin-bottom: 24px;
      text-align: center;
    }

    &__text {
      font-family: "SourceSansPro Regular";
      font-size: 18px;
      line-height: 28px;
      color: #051424;
      text-align: center;
      max-width: 1176px;
      margin-bottom: 69px;

      @media (min-width: 768px) {
        padding: 0;
      }
    }

    &__cards {
      display: flex;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      justify-content: center;
      gap: 17px;

      .plan-card {
        height: 989px;
        width: 100%;
        min-width: 253px;
        max-width: 253px;
        padding: 34px 24px 32px;
        border-radius: 16px;
        box-shadow: 0 8px 16px 0 rgba(69, 124, 157, 0.2);
        background-color: #fff;
        position: relative;

        @media (max-width: 690px) {
          max-width: 300px;
          width: 300px !important;
          height: 251px !important;
          padding: 32px 16px !important;
        }

        @media only screen and (min-width: 690px) and (max-width: 1439px) {
          max-width: 360px;
          min-width: 360px !important;
          height: 283px !important;
          padding: 48px 16px !important;
        }

        &__info {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 12px;

          .info-heading {
            font-family: "SourceSansPro Semibold", helvetica, sans-serif;
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.75;
            letter-spacing: normal;
            text-align: center;
            color: #457c9d;
          }

          .info-price {
            font-family: "SourceSansPro Semibold", helvetica, sans-serif;
            font-size: 56px;
            line-height: 1;
            text-align: center;
            color: #051424;
            display: flex;
            align-items: center;
            gap: 8px;

            &__small {
              font-size: 14px;
              font-family: "SourceSansPro Regular" !important;
            }
          }

          .info-text {
            font-family: "SourceSansPro Regular";
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: center;
            color: #457c9d;
          }
        }

        hr {
          margin: 39.5px 0;
          height: 1px;
          width: 100%;
          background-color: #c0c4c8;

          @media (max-width: 1439px) {
            display: none;
          }
        }

        &__benefits {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0px;
          padding: 0;
          margin: 0px;

          @media (max-width: 1439px) {
            display: none;
          }

          li {
            height: auto;
            list-style-type: none;
            font-family: "SourceSansPro Regular";
            color: #051424;
            font-size: 18px;
            display: flex;
            align-items: center;
            gap: 7px;

            img {
              height: 36px;
              width: 36px;
            }
          }
        }

        .customizeid-button {
          display: flex;
          justify-content: center;
          margin-top: auto;

          button {
            margin: 0px;
            position: absolute;
            bottom: 32px;
          }
        }

        &__note {
          font-family: "SourceSansPro Italic";
          font-size: 16px;
          line-height: 20px;
          color: #051424;
          width: 100%;
          max-width: 300px;
          height: auto;
        }
      }

      .active-card-default {
        min-width: 272px !important;
        max-width: 272px !important;

        @media only screen and (min-width: 698px) and (max-width: 1439px) {
          min-width: 360px !important;
          max-width: 360px !important;
        }

        @media (max-width: 690px) {
          min-width: auto !important;
          max-width: 312px !important;
        }
      }

      .active-card-default,
      .active-card {
        border: 1px solid #0f7ef8;
        height: 1032px !important;
        padding-top: 54px !important;

        @media only screen and (min-width: 698px) and (max-width: 1439px) {
          height: 283px !important;
        }

        @media (max-width: 690px) {
          border: 1px solid #0f7ef8;
          // height: auto !important;
          padding-top: 32.5px !important;
          height: 251px !important;
        }

        @media only screen and (min-width: 768px) and (max-width: 830px) {
          width: 380px !important;
        }

        &__most-popular {
          font-size: 18px;
          color: #fff;
          font-family: "SourceSansPro Semibold", helvetica, sans-serif;
          text-align: center;
          background-color: #0f7ef8;
          padding: 12px 24px;
          border-radius: 100px;
          line-height: 1.17;
          position: absolute;
          top: -2.5%;
          left: 50%;
          transform: translateX(-50%);
          width: 195px;
          height: 45px;

          @media only screen and (min-width: 690px) and (max-width: 1439px) {
            top: -8.5%;
          }
        }

        .info-text {
          font-size: 18px;
        }

        .info-heading {
          font-size: 26px;
        }
      }
    }

    @media (max-width: 1260px) {
      &__cards {
        display: flex;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }

    @media (max-width: 970px) {
      padding: 0;

      &__cards {
        width: 100%;
      }
    }

    @media (max-width: 767px) {
      padding: 0;

      &__cards {
        display: flex;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      &__heading {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 16px;
        width: 343px;
      }

      &__text {
        margin-bottom: 32px;
      }

      &__cards {
        // flex-direction: column;
        width: 100%;
        margin: 0;

        .plan-card {
          width: 100%;
          // height: 100%;
          // padding: 32px 15px 32px 16px;
        }

        .active-card {
          // margin-top: 23px;

          &__most-popular {
            font-size: 18px;
            line-height: 18px;

            @media (max-width: 690px) {
              top: -10.5%;
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      padding: 0;

      &__cards {
        display: flex;
        grid-template-columns: repeat(1, 1fr);

        .plan-card {
          width: 100%;
          // height: auto;
          padding: 32px 15px 32px 16px;
        }

        .safe-card {
          margin-top: 20px;
        }
      }
    }
  }

  .fid-plans__cards {
    width: 100%;
    cursor: pointer;
    padding-top: 34px;
    flex-direction: row;
    transition: transform 0.3s ease-in-out;
    margin: 0px;

    @media only screen and (min-width: 690px) and (max-width: 1439px) {
      gap: 24px !important;
      padding-bottom: 32px;
      padding-top: 26px;
    }

    @media (max-width: 690px) {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 17px;
      padding: 85px 0 0;
      flex-direction: row;
      width: 100%;
      transition: transform 0.3s ease-in-out;

      .plan-card {
        flex: 0 0 auto;
        scroll-snap-align: start;
        transition: transform 0.3s ease;
        // display: none;
      }

      .active-card-index {
        display: block;
        transform: translateY(-50px);
        z-index: 1;
        transition: transform 0.3s ease;
      }

      .visible {
        display: block;
        position: relative;
        z-index: 0;
      }

      /* Optional: Hide scrollbar */
      &::-webkit-scrollbar {
        display: none; // Hide scrollbar for a cleaner look
      }
    }

    @media (max-width: 690px) {
      gap: 8px !important;
      padding-bottom: 26px;
      padding-top: 26px;
      // justify-content: flex-start;
    }
  }
}

.container-card-active {
  border: 2px solid #0f7ef8;
  height: 1032px !important;
  position: relative;
  padding-top: 54px !important;

  @media only screen and (min-width: 768px) and (max-width: 830px) {
    width: 380px !important;
  }

  @media (max-width: 1439px) {
    border: 1px solid #0f7ef8;
    height: auto !important;
  }
}

.Pdp-Plan-container {
  margin-top: 40px;
  max-width: 1176px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 690px) {
    margin-top: 24px;
  }

  .form-floating {
    background-color: transparent !important;
  }

  .planOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    backdrop-filter: blur(1px);
    /* Adjust the blur intensity as needed */

    .loginPage-conatiner {
      position: absolute;
      width: 458px;
      min-height: 549px;
      padding: 40px;
      border-radius: 20px;
      background-color: #fff;
      top: 50%;
      /* Center vertically */
      left: 50%;
      /* Center horizontally */
      transform: translate(-50%,
          -50%);

      /* Adjust for the element's dimensions */
      @media (max-width: 690px) {
        padding: 24px 16px;
        max-width: 343px;
        height: auto;
        min-height: auto;
      }

      .goback-button {
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        gap: 8px;
        width: fit-content;
        cursor: pointer;

        .gobackImage {
          width: 14px;
          height: 14px;
          transform: rotate(270deg);
          cursor: pointer;
        }

        .text {
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #1d3557;

          @media (max-width: 690px) {
            font-size: 16px;
          }
        }
      }

      .forgot-header {
        font-size: 24px;
        text-align: center;
        margin-bottom: 24px;
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;

        @media (max-width: 690px) {
          font-size: 18px;
        }
      }

      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;

        .logo-login {
          height: 45px;
          width: 268px;

          @media (max-width: 690px) {
            height: 31px;
            width: 184px;
          }
        }
      }

      .waaf-box {
        display: flex;
        gap: 24px;
        flex-direction: column;
        margin-bottom: 24px;

        @media (max-width: 690px) {
          gap: 16px;
        }

        #togglePassword {
          cursor: pointer;
          position: absolute;
          right: 10px;
          top: 15px;
        }

        .box {
          gap: 10px;
          height: 51px !important;
          padding-top: 23px;
          border-radius: 8px !important;
          border: solid 1.5px #c0c4c8;
          background-color: #fff;
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #051424;
          width: 100%;

          :focus {
            border-color: #457c9d !important;
          }

          :active {
            border-color: #457c9d !important;
          }
        }

        input:focus {
          border: solid 1.5px #457c9d;
          outline: none !important;
        }

        input::placeholder {
          color: #c0c4c8 !important;
        }

        /* Hide the spinners in WebKit browsers (Chrome, Edge, Safari) */
        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Hide the spinners in Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
          appearance: textfield;
        }
      }

      #returntologin {
        text-align: center !important;
        margin-bottom: 0px;
      }

      .submit-button {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;

        .confirm {
          font-family: "SourceSansPro Semibold", helvetica, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
        }
      }

      .needhelpText {
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #051424;

        span {
          color: #457c9d;
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          cursor: pointer;
        }

        @media (max-width: 690px) {
          font-size: 14px;
        }
      }

      .forgot-password {
        margin-bottom: 24px;

        .text {
          font-family: "SourceSansPro Semibold", helvetica, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.13;
          letter-spacing: normal;
          text-align: left;
          color: #457c9d;
          cursor: pointer;
          width: fit-content;
        }
      }

      .returntoLogin {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0px;
      }

      .description {
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #051424;
        width: 89%;
        margin: auto;
        margin-bottom: 24px;

        @media (max-width: 690px) {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
        }
      }
    }

    #forgot-password {
      width: 448px;
      height: auto;
      min-height: auto;
    }

    #reset-password {
      width: 448px;
      height: auto;
      min-height: auto;

      .goback-button {
        margin-left: auto;
      }

      .success-email {
        height: 60px;
        width: 60px;
        margin: auto;
        margin-bottom: 24px;
      }
    }
  }

  .product-deatilscontainer {
    display: flex;
    justify-content: center;
    gap: 24px;
    max-width: 1176px;
    margin-bottom: 41px;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (min-width: 768px) and (max-width: 1439px) {
      margin-left: 72px;
      margin-right: 72px;
    }

    @media (max-width: 768px) {
      margin-left: 16px;
      margin-right: 16px;
      margin-bottom: 24px;
    }

    .image-container {
      min-width: 132px;
      height: 132px;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 8px;
      border-radius: 14px;
      background-color: #f0f9f9;

      @media (max-width: 1439px) {
        display: none;
      }

      img {
        height: 100%;
        width: 100%;
      }
    }

    .product-data-container {
      display: flex;
      flex-direction: column;
      width: 100%;

      .product-data {
        width: 100%;
        height: 88px;
        display: flex;
        justify-content: space-between;
        padding: 24px;
        border-radius: 16px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;

        @media (max-width: 1439px) {
          padding: 16px;
          height: auto;
        }

        .header-image-mobile-containr {
          display: flex;
          gap: 12px;
        }

        .image-container-mobile {
          display: none;

          @media (max-width: 1439px) {
            min-width: 56px;
            max-width: 56px;
            max-height: 56px;
            min-height: 56px;
            flex-grow: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 4px;
            border-radius: 8px;
            background-color: #f0f9f9;
          }
        }

        .product-Header {
          display: flex;
          flex-direction: column;
          gap: 6px;

          .title {
            font-family: "SourceSansPro Regular", helvetica, sans-serif !important;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #051424;

            @media (max-width: 1439px) {
              opacity: 0.86;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.13;
              letter-spacing: normal;
              text-align: left;
              color: #051424;
            }
          }

          .details {
            font-family: "SourceSansPro Regular", helvetica, sans-serif !important;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #434e5a;

            @media (max-width: 1439px) {
              font-size: 14px;
            }

            span {
              color: #434e5a !important;
              font-family: "SourceSansPro Regular", helvetica, sans-serif;
            }
          }

          .product-button {
            display: none;

            @media (max-width: 690px) {
              display: flex;
              gap: 8px;
              align-items: center;

              .p-button {
                font-family: "SourceSansPro Regular", helvetica, sans-serif !important;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                text-align: left;
                color: #457c9d;
                text-decoration: underline;
                display: flex;
                gap: 8px;
                cursor: pointer;

                a {
                  color: #457c9d !important;
                  font-family: "SourceSansPro Regular", helvetica, sans-serif !important;
                }
              }
            }
          }
        }

        .product-Price {
          display: flex;
          flex-direction: column;
          gap: 6px;
          justify-content: end;
          align-items: end;

          @media (max-width: 1439px) {
            justify-content: normaL;
          }

          .price {
            font-family: "SourceSansPro Regular", helvetica, sans-serif !important;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: right;
            color: #051424;

            @media (max-width: 1439px) {
              font-size: 16px;
            }
          }

          .edit {
            font-family: "SourceSansPro Regular", helvetica, sans-serif !important;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;

            .Change {
              text-decoration: underline;
              color: #457c9d !important;
              font-family: "SourceSansPro Regular", helvetica, sans-serif !important;
            }

            cursor: pointer;

            @media (max-width: 690px) {
              display: none;
            }
          }
        }
      }
    }
  }

  .accounts-links-container {
    max-width: 815px;
    margin-left: auto;
    display: flex;
    justify-content: center;

    @media (max-width: 690px) {
      flex-direction: column;
      gap: 24px;
    }

    @media (max-width:1439px) {
      max-width: 749px;
      margin-inline: auto;
    }

    .donthaveAccount {
      width: 100%;
      display: flex;
      align-items: center;

      @media(max-width:690px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @media (max-width: 1439px) {
        // justify-content: center;
        margin-inline: 10px;
      }
    }

    .loginwithqr {
      justify-content: center !important;
    }

    .login-link-Container {
      width: 100%;
      display: flex;
      justify-content: end;

      @media (max-width: 1439px) {
        // justify-content: flex-start;
      }

      @media (max-width: 690px) {
        justify-content: center;
        // align-items: center;
      }

      .loginLink {
        margin-bottom: 0px;

        @media (max-width:690px) {
          text-align: center;
        }

        @media (max-width:1439px) {
          text-align: center;
          // align-items: center;
        }

        .memberText {
          margin-bottom: 8px;
        }

        @media (max-width: 690px) {
          margin-bottom: 16px;
        }
      }
    }
  }

  .donthaveAccount a {
    font-family: "SourceSansPro Semibold", helvetica, sans-serif !important;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #1d3557;
    cursor: pointer;
    text-decoration: none;
  }

  .login-desktop {
    @media (max-width: 729px) {
      display: none;
    }
  }

  .loginLink {
    font-family: "SourceSansPro Semibold", helvetica, sans-serif !important;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #1d3557;
    margin-bottom: 40px;

    @media (max-width: 768px) {
      margin-left: 16px;
      margin-right: 16px;
    }

    span {
      font-family: "SourceSansPro Semibold", helvetica, sans-serif !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .Wizard-engrave-bar,
  .Wizard-checked-circle::before,
  .bar {
    background-color: #0f7ef8 !important;
  }

  .Wizard-checked-circle::before {
    border: solid 1px #0f7ef8 !important;
  }

  .Wizard-payment {
    font-family: "SourceSansPro Semibold", helvetica, sans-serif !important;
  }

  .Wizard-account,
  .Wizard-payment,
  .Wizard-engrave {
    color: #0f7ef8 !important;

    @media (max-width: 690px) {
      margin-left: 0px;
    }
  }
}

.Wizard-Payment-Information-container .Payment-row .user-details .user-contacts .contact {
  justify-content: normal !important;
}

.custom-checkbox-Invalid {
  span {
    color: red !important;
  }

  a {
    color: red !important;
  }
}

.Custom-Modal,
.Custom-Modal-Invalid {
  position: fixed;
  z-index: 999999;
  margin: auto;
  top: 50%;
  /* Positions the top of the element at 50% of the viewport height */
  left: 50%;
  /* Positions the left of the element at 50% of the viewport width */
  transform: translate(-50%, -50%);
  /* Adjusts the element to center itself */
  display: none;

  .Custom-Modal-container {
    width: 784px;
    border-radius: 20px;
    background-color: #fff;
    padding: 35px;

    @media (max-width: 790px) {
      width: 100%;
      min-width: 720px;
    }

    @media (max-width: 690px) {
      width: 100%;
      min-width: 338px;
    }

    .cross-button {
      display: flex;
      justify-content: end;

      img {
        height: 18px;
        width: 16px;
        cursor: pointer;
      }
    }

    .header {
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
      margin-bottom: 8px;
    }

    .description {
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
      margin-bottom: 35px;
    }

    .address-container {
      gap: 47px;

      @media (max-width: 790px) {
        gap: 20px;
      }

      margin-bottom: 32px;

      .check-box {
        width: 333px;
        height: 121px;
        border-radius: 16px;
        box-shadow: 0 4px 8px 2px rgba(55, 67, 79, 0.04),
          0 -2px 8px 2px rgba(55, 67, 79, 0.05);
        background-color: #fff;
        padding: 15px;
        cursor: pointer;

        @media (max-width: 790px) {
          width: 100%;
        }

        @media (max-width: 690px) {
          width: 100%;
        }
      }

      .activtedCheckbox {
        border: solid 1px #0f7ef8;
        background-color: #fff;
      }

      .text {
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
      }

      .checkboxContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;

        input {
          height: 18px;
          width: 18px;
        }
      }

      .Oaddress {
        margin-left: 30px;
      }
    }

    .addressConfirm {
      text-align: end;

      @media (max-width: 690px) {
        text-align: center;
      }

      button {
        width: 136px;
      }
    }
  }
}

.size-slider {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: -100%;
  /* Completely hidden off-screen initially */
  height: 100%;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  /* Smooth slide-in animation */
  width: 552px;
  padding: 40px 28px 28px 28px;
  background-color: #fff;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  overflow: scroll;
  overflow-x: hidden;

  @media (max-width: 767px) {
    width: 328px;
    border-top-left-radius: 28px;
    border-bottom-left-radius: 28px;
    padding: 26px 24px 24px 24px;
  }

  .cross-button {
    display: flex;
    justify-content: end;

    img {
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }

  .header {
    font-family: "SourceSansPro Semibold", helvetica, sans-serif;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #051424;
    margin-bottom: 36px;

    @media (max-width: 767px) {
      font-size: 24px;
      margin-bottom: 16px;
    }
  }

  .sub-header {
    font-family: "SourceSansPro Semibold", helvetica, sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #457c9d;
    margin-bottom: 24px;

    @media (max-width: 767px) {
      font-size: 18px;
      margin-bottom: 16px;
    }
  }

  .description {
    font-family: "SourceSansPro Regular", helvetica, sans-serif;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: normal;
    text-align: left;
    color: #051424;

    @media (max-width: 767px) {
      font-size: 16px;
      margin-bottom: 18px;
    }

    .content {
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
    }

    margin-bottom: 42px;
  }

  .size-points {
    margin-bottom: 24px;

    @media (max-width: 767px) {
      margin-bottom: 16px;
    }

    .points-header {
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.95;
      letter-spacing: normal;
      text-align: left;
      color: #457c9d;
      margin-bottom: 8px;
      margin-left: 10px;

      @media (max-width: 767px) {
        font-size: 16px;
        margin-left: 4px;
      }
    }

    .point-description {
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.11;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
      margin-left: 34px;

      @media (max-width: 767px) {
        font-size: 16px;
        margin-left: 20px;
      }

      span {
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      }
    }
  }

  .size-points-last {
    margin-bottom: 30px !important;

    @media (max-width: 767px) {
      margin-bottom: 26px;
    }
  }

  .Image-container {
    width: 382px;
    height: 335px;
    margin: auto;
    margin-bottom: 31px;

    @media (max-width: 767px) {
      width: 280px;
      height: 243px;
      margin-bottom: 16px;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  .question-size {
    font-family: "SourceSansPro Regular", helvetica, sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #051424;

    @media (max-width: 767px) {
      font-size: 16px;
    }

    span {
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
    }
  }
}

.size-slider.open {
  right: 0;
}

.wizardBlur .loaderForSize {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000082;
  /* Adjust the opacity and color as needed */
  z-index: 99999;
  backdrop-filter: blur(1px);
  /* Adjust the blur intensity as needed */
}

.Wizard-accountInforamtion-container,
.Wizard-Payment-Information-container {
  .us-autocomplete-pro-menu {
    padding: 10px;
    padding-left: 5px;
    background-color: #ffff;
    color: #051424;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: white;
    box-shadow: 0 4px 8px 2px rgba(55, 67, 79, 0.04),
      0 -2px 8px 2px rgba(55, 67, 79, 0.05);
    position: absolute;
    z-index: 9999;
    width: 100%;

    .suggested-address-line {
      border-radius: 8px;
      padding: 5px;
      padding-left: 10px;

      &:hover {
        background-color: #c0c4c8;
      }
    }
  }
}

@media (max-width: 830px) {
  .Display {
    display: none; // Ensure this class is hidden
  }

  .fid-header-plans::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
  }

  #planError {
    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      // margin-left: 72px;
    }
  }
}

.pdpCheckoutContainer {
  max-width: 1176px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;

  @media (max-width: 920px) {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 24px;
  }

  .pdpCheckoutHeader {
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #051424;
    font-family: "SourceSansPro Semibold", helvetica, sans-serif;
    margin-bottom: 40px;

    @media only screen and (min-width: 920px) and (max-width: 1210px) {
      max-width: 880px;
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: 830px) {
      font-size: 21px;
      margin-bottom: 16px;
    }
  }

  .product-data {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin-bottom: 40px;

    @media (max-width: 1024px) {
      padding: 16px;
      height: auto;
    }

    .previewContainer {
      margin-left: 77px;
      .header {
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: -0.3px;
        text-align: left;
        color: #828991;
        margin-bottom: 16px;
        align-items: left;
        font-size: 16px;
        display: flex; 
        flex-direction: column;
        width:auto;
        @media(max-width:690px){
          text-align: center;
          align-items: center;
          margin-bottom: 12px;
          margin-top: 16px;
        }
      }
      @media(max-width:690px){
        margin-left: 0;
      }
      
      .dataContainer {
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: -0.3px;
        text-align: center;
        color: #828991;
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        max-width: 320px;
        min-height: 220px;
        flex-grow: 0;
        padding: 26px 59px 26px 58px;
        border-radius: 110px;
        background-color: #e5e7e8;
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 3px;
        @media(max-width:690px){
          margin: auto;
          margin-bottom: -8px;
          margin-left: auto;
        }
        @media(max-width:363px){
          font-size: 0.875em;
        }

        .call,.text{
          @media(max-width:363px){
            font-size: 0.875em;
          }
        }

        .inputdata {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;
          @media(max-width:363px){
            font-size: 0.875em;
          }

          .data {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: #051424;
            text-transform: uppercase;
            @media(max-width:363px){
              font-size: 0.875em;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 920px) and (max-width: 1210px) {
      max-width: 880px;
      margin-left: auto;
      margin-right: auto;
    }

    hr {
      height: 1px;
      align-self: stretch;
      flex-grow: 0;
      background-color: #e5e7e8;
      margin-top: 23px;
      margin-bottom: 24px;
    }

    .product-container-data,
    .donation-container-data,
    .plan-container-data {
      display: flex;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .header-image-mobile-containr {
        display: flex;
        gap: 12px;
      }

      .image-container-checkout {
        min-width: 56px;
        max-width: 64px;
        max-height: 64px;
        min-height: 56px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px;
        border-radius: 8px;
        background-color: #f0f9f9;
        @media (max-width: 690px) {
          max-height: 56px;
        }
      }

      .product-Header {
        display: flex;
        flex-direction: column;
        gap: 6px;
        max-width: 800px;

        .title {
          font-family: "SourceSansPro Regular", helvetica, sans-serif !important;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #051424;

          @media (max-width: 1024px) {
            opacity: 0.86;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: normal;
            text-align: left;
            color: #051424;
          }
        }

        .details {
          font-family: "SourceSansPro Regular", helvetica, sans-serif !important;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #434e5a;

          @media (max-width: 1024px) {
            font-size: 14px;
          }

          .sidebar-plan {
            text-decoration: underline;
            cursor: pointer;
          }

          span {
            color: #434e5a !important;
            font-family: "SourceSansPro Regular", helvetica, sans-serif;
          }
        }

        .product-button {
          display: none;

          @media (max-width: 1024px) {
            display: flex;
            gap: 8px;
            align-items: center;

            .p-button {
              font-family: "SourceSansPro Regular", helvetica, sans-serif !important;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.14;
              letter-spacing: normal;
              text-align: left;
              color: #457c9d;
              text-decoration: underline;
              cursor: pointer;
              display: flex;
              gap: 8px;

              a {
                font-family: "SourceSansPro Regular", helvetica, sans-serif !important;
                color: #457c9d !important;
              }
            }
          }
        }
      }

      .product-Price {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: end;

        @media (max-width: 1024px) {
          justify-content: normaL;
        }

        .price {
          font-family: "SourceSansPro Regular", helvetica, sans-serif !important;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: right;
          color: #051424;

          @media (max-width: 1024px) {
            font-size: 16px;
          }
        }

        .edit {
          font-family: "SourceSansPro Regular", helvetica, sans-serif !important;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          display: flex;
          flex-wrap: wrap;
          justify-content: end;
          gap: 8px;
          width: 100%;

          .Change {
            text-decoration: underline;
            color: #457c9d !important;
            font-family: "SourceSansPro Regular", helvetica, sans-serif !important;
          }

          cursor: pointer;

          @media (max-width: 1024px) {
            display: none;
          }
        }
      }
    }
  }

  .checkout-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-bottom: 66px;

    @media (max-width: 690px) {
      gap: 24px;
      margin-bottom: 40px;
    }

    a {
      text-decoration: none !important;
      margin-top: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .keep-shopping {
      font-family: "SourceSansPro Semibold", helvetica, sans-serif !important;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #051424;
      cursor: pointer;
    }
  }

  .plp-product-row {
    margin-left: auto !important;
    margin-right: auto !important;

    @media (max-width: 592px) {
      margin-left: auto !important;
      margin-right: auto !important;
    }

    img {
      mix-blend-mode: darken !important;
    }

    .plp-product-column {
      // margin-bottom: 0 !important;
    }
  }

  .plp-card-header {
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #051424;
    margin-bottom: 24px;
    text-align: center;
    font-family: "SourceSansPro Semibold", helvetica, sans-serif;

    @media (max-width: 690px) {
      font-size: 24px;
      margin-bottom: 16px;
      text-align: left;
    }
  }

  .transparent-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: none;
    z-index: 999;
  }

  .active {
    display: block;
  }

  .open {
    transform: translateX(-552px);
  }

  dialog::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .container-autoRenewal,
  .container-cancel,
  .container-optOut {
    max-width: 552px;
    width: 100%;
    background: white;
    padding: 2.5rem 2rem 2.5rem 2rem;
    min-width: 20rem;
    font-size: 21px;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: -552px;
    height: 100%;
    transition: transform 0.4s ease;
    z-index: 1000;

    .heading {
      display: flex;
      justify-content: space-between;
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      align-items: end;

      &-main {
        height: 1.3125rem;
        text-transform: uppercase;
        font-size: 21px;
        font-weight: normal;
        color: #051424;
        line-height: 1;
      }
    }

    &-body {
      margin-top: 1.5rem;
      flex-direction: column;

      &-text {
        font-family: "SourceSansPro Regular";
        line-height: 1.19;
        font-size: 21px;
        font-weight: normal;
      }

      .text-style {
        color: #457c9d;
      }

      .text-style-email {
        text-decoration: none !important;
      }
    }

    .close-btn {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;

      box-shadow: none;
    }
  }

  .planOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    backdrop-filter: blur(1px);
    /* Adjust the blur intensity as needed */
    overflow: auto;

    .fid-plans {
      max-width: 100%;
      margin: 67px 54px;
      background-color: #fff;
      padding: 48px 58px 64px 58px;
      max-width: 1332px;
      border-radius: 24px;

      @media (max-width: 1439px) {
        margin: 52px 16px 52px;
        padding: 32px 0px 32px 0px;
      }

      @media (max-width: 767px) {
        padding: 32px 0px 32px 0px;
      }

      @media (min-width: 1440px) {
        margin-left: auto;
        margin-right: auto;
      }

      .close-plan-conatiner {
        display: flex;
        justify-content: end;
        align-items: center;
        margin-bottom: 22px;
        width: 100%;
        margin-right: -30px;

        @media (max-width: 1439px) {
          padding-right: 24px;
          margin-right: 0px;
        }

        @media (max-width: 767px) {
          padding-right: 16px;
          margin-right: 0px;
        }

        img {
          width: 18px;
          height: 18px;

          @media (max-width: 767px) {
            width: 14px;
            height: 14px;
          }
        }

        .close-button-plan {
          cursor: pointer;
        }
      }

      .plan-container {
        margin-bottom: 40px;
        gap: 40px;

        @media (max-width: 1439px) {
          flex-direction: column-reverse;
          gap: 40px;
        }

        @media (max-width: 690px) {
          gap: 37px;
        }

        .plan-card {
          box-shadow: 0 16px 32px 0 rgba(69, 124, 157, 0.2);

          @media (max-width: 690px) {
            max-width: 300px;
            width: 300px;
          }
        }

        .SafeAndConnected {
          border: 1px solid #0f7ef8;
        }

        .container {
          max-width: 100% !important;

          @media (max-width: 1439px) {
            overflow: hidden;
            width: 100%;
          }

          .countinueTocheckout {
            font-family: "SourceSansPro Semibold", helvetica, sans-serif !important;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: center;
            color: #1d3557;
            margin-top: 40px;
            text-decoration: underline;

            a {
              font-family: "SourceSansPro Semibold", helvetica, sans-serif !important;
              font-size: 16px;
            }

            cursor: pointer;

            @media (max-width: 1439px) {
              display: none;
            }
          }

          .fid-plans__cards {
            gap: 40px;
            flex-direction: row;
            margin: 0px;

            @media only screen and (min-width: 690px) and (max-width: 1439px) {
              gap: 24px !important;
              padding-bottom: 32px;
              padding-top: 26px;
            }

            @media (max-width: 690px) {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 40px;
              padding: 85px 0 0;
              flex-direction: row;
              width: 100%;
              transition: transform 0.3s ease-in-out;

              .plan-card {
                flex: 0 0 auto;
                scroll-snap-align: start;
                transition: transform 0.3s ease;
                // display: none;
              }

              .active-card-index {
                display: block;
                transform: translateY(-50px);
                z-index: 1;
                transition: transform 0.3s ease;
              }

              .visible {
                display: block;
                position: relative;
                z-index: 0;
              }

              /* Optional: Hide scrollbar */
              &::-webkit-scrollbar {
                display: none; // Hide scrollbar for a cleaner look
              }
            }

            @media (max-width: 690px) {
              gap: 8px !important;
              padding-bottom: 35px;
              padding-top: 26px;
            }

            .active-card__most-popular {
              width: 206px;
              max-width: 206px;
            }
          }
        }

        .descriptionPlan {
          @media (max-width: 1439px) {
            padding-left: 16px;
            padding-right: 16px;
          }

          @media (max-width: 1439px) {
            padding-top: 0px;
            max-width: unset;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .plans-deatils-container {
            @media (max-width: 1439px) {
              flex-direction: row;
              flex-wrap: wrap;
              align-items: flex-end;
              justify-content: center;
            }

            .plans-details {
              @media (max-width: 1439px) {
                max-width: 812px;
              }
            }
          }
        }
      }

      .mobile-addtocart {
        display: none;

        .countinueTocheckout-mobile {
          margin-top: 2em;
        }

        @media (max-width: 1439px) {
          display: block;
          margin-bottom: 40px;
          text-align: center;
        }
      }

      .countinueTocheckout-mobile {
        display: none;
        font-family: "SourceSansPro Semibold", helvetica, sans-serif !important;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #1d3557;
        margin-bottom: 40px;
        text-decoration: underline;
        cursor: pointer;

        a {
          font-family: "SourceSansPro Semibold", helvetica, sans-serif !important;
          font-size: 16px;
        }

        @media (max-width: 1439px) {
          display: block;
        }
      }

      .btn-container {
        @media only screen and (min-width: 690px) and (max-width: 1439px) {
          display: flex;
          justify-content: center;
          gap: 16px;
          margin-top: 24px;
        }
      }

      .fid-header-plans {
        font-size: 36px;
        text-align: center;
        margin-bottom: 40px;

        @media (max-width: 690px) {
          font-size: 21px;
          margin-left: 16px;
          margin-right: 16px;
          margin-bottom: 32px;
        }
      }

      .video-header {
        font-family: "SourceSansPro Semibold", helvetica, sans-serif !important;
        font-size: 32px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        text-align: center;
        color: #051424;
        margin-bottom: 24px;

        @media (max-width: 1439px) {
          font-size: 18px;
          margin-left: 16px;
          margin-right: 16px;
        }
      }

      .maf-message {
        max-width: 1176px !important;

        @media (max-width: 1439px) {
          padding-left: 16px;
          padding-right: 16px;
          margin: 0px;
        }
      }

      #video-container-plan {
        width: 100%;
        height: 100%;

        #video-plan {
          width: 100%;
          height: auto;
          aspect-ratio: 16 / 9;
          /* Maintain 16:9 ratio */
          max-height: 662px;
        }
      }
    }
  }
}

.customizeid-container .product-container .customizeid-sizechart .size-containers .size {
  min-width: 59px !important;
  width: auto !important;
  font-size: 16px;
}

.customizeid-container .product-container .customizeid-sizechart .size-containers .customize-size-container-active {
  min-width: 59px !important;
  width: auto !important;
}

.customizeid-container .product-container .customizeid-sizechart .size-containers .customize-size-container-active .size {
  min-width: 51px !important;
  width: auto !important;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: var(--bs-form-valid-border-color) !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color) !important;
}

.form-floating>.form-control:not(:placeholder-shown)~label::after {
  background: transparent !important;
}

.yotpo-bold-layout .yotpo-head {
  min-height: 100% !important;
  margin-top: 36px !important;

  @media (max-width: 690px) {
    margin-top: 16px !important;
  }
}

.yotpo-display-s .yotpo-head .yotpo-headline {
  margin-top: 0px !important;
}

.yotpo-display-s .yotpo-head {
  margin-top: 0px !important;
}

#customize-color-name {
  text-transform: capitalize;
}

div[data-querystring="pid=104140000"] {
  .product-container {
    img {
      mix-blend-mode: initial !important;
    }
  }
}

div[data-querystring="pid=101460000"] {
  .product-container {
    img {
      mix-blend-mode: initial !important;
    }
  }
}