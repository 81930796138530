/*
    @package MEDICALERTTHEMEPREMIUM
    ======================================
        HALF HERO QUOTE H1 TEMPATE SCSS
    ======================================
*/

.maf-component-half-hero-quote-h1 figure figcaption quote{
    font-weight: bold;
    font-weight: 600;
}
.maf-component-half-hero-quote-h1 figure figcaption cite{
    font-size: 0.86em;
    margin: 9% 0 0;
    display: block;
    color: var(--color-grey4)
}

.maf-component-half-hero-quote-h1 div.maf-block-copy{
    flex:1;
    text-align: left;
    padding: 3%;
    p{
        margin: 0;
        &.maf-font-alert{
            color: var(--color-danger);
            font-weight: bold;
            font-weight: 600;
            span{
                color: var(--color-danger);
                font-style: italic;
            }
        }
    }
}
.maf-component-half-hero-quote-h1 figure{
    background: url("https://blog.medicalert.org/wp-content/uploads/2021/10/membership-hero.jpg");
    background-size: cover;
    background-position: 100% 0;
    padding-top: calc((434% / 806) * 57);
    position: relative;
}
.maf-component-half-hero-quote-h1 .maf-quote > span{
    position: absolute;
    left: 4%;
    top: 7%;
    color: var(--color-white);
    font-weight: bold;
    font-weight: 600;
    font-size: 6em;
    line-height: 0;
}
.maf-component-half-hero-quote-h1 figure figcaption{
    position: absolute;
    top: 5%;
    right: 2%;
    background: #457796eb;
    color: var(--color-white);
    width: 56%;
    padding: 8% 4%;
    text-align: left;
}
.maf-component-half-hero-quote-h1 .maf-block-copy h1{
    text-transform: capitalize;
    color: var(--color-success);
    font-size: 2.63em;
    font-weight: bold;
    font-weight: 900;
    letter-spacing: 0;
    margin: 0 0 0.3em;
}

@media (min-width: 768px) {
    .maf-component-half-hero-quote-h1 .maf-donation-hero-wrap > div{
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .maf-component-half-hero-quote-h1 figure{
        flex:1.5;
    }
    .maf-component-half-hero-quote-h1 figure figcaption{
        font-size: 1.73vw;
        top: 2%;
        right: 2%;
    }
}
@media (min-width: 992px) {
    .maf-component-half-hero-quote-h1{
        width:96%;
        margin: 0 auto;
    }
    .maf-component-half-hero-quote-h1 figure figcaption{
        font-size:1.33vw;
        width: 44%;
        top: 11%;
        right: 4%;
        background: var(--color-info);
        padding: 8% 6%;
    }
}
@media (min-width: 1200px) {
    .maf-component-half-hero-quote-h1 figure figcaption{
        font-size:1em;
    }
}
@media (max-width: 768px) {
    .maf-component-half-hero-quote-h1 figure{
        padding:0;
    }
    .maf-component-half-hero-quote-h1 figure figcaption{
        top: 0;
        width: 100%;
        position: initial;
        padding: 14% 4%;
    }
    .maf-component-half-hero-quote-h1 .maf-quote > span{
        top: 17%;
    }
}
