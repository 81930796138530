/*
    @package MEDICALERTTHEMEPREMIUM
    ===============================================
        VACCINATION HOW IT WORKS COMPONENT SCSS
    ===============================================

*/

.maf-component-vaccination-records-how-it-works{
    text-align: center;
    > div{
        &.maf-plus-sign{
            font-size: 5em;
            line-height: 1;
            font-weight: bold;
            font-weight: 800;
            color: var(--color-success);
        }
        figure{
            width: 23%;
            margin: 0 auto;
        }
        h3{
            font-weight: bold;
            font-weight: 700;
            letter-spacing: 0;
            margin: 0;
            &.maf-sub-header{
                display: inline-block;
                border-top: solid 1px gray;
                border-color: var(--color-grey6);
                padding: 0.5em 0 0;
                margin: 1em 0 0;
            }
        }
        p{
            &.maf-sub-header{
                color: var(--color-info);
                font-weight: 600;
                font-size: 1.3em;
                letter-spacing: 0;
            }
        }
    }

}

@media (min-width: 768px) {
    .maf-component-vaccination-records-how-it-works{
        display: flex;
        align-items: center;
        -webkit-align-items: center;
        -ms-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-justify-content: center;
        > div{
            figure{
                width: 32%;
                margin: 0 auto;
            }
            &.maf-plus-sign{
                position: absolute;
                top: 25.5%;
                left: 39%;
            }
            &:nth-of-type(3){
                > div:nth-of-type(1){
                    display: flex;
                    align-items: center;
                    -webkit-align-items: center;
                    -ms-align-items: center;
                    justify-content: center;
                    -webkit-justify-content: center;
                    -ms-justify-content: center;
                    figure{
                        width: 24%;
                        flex: 1;
                    }
                    > div{
                        flex: 1;
                    }
                }
            }
            &:nth-of-type(1),
            &:nth-of-type(3){
                flex:1;
            }
        }
    }
}
