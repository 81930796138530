/*
    @package MEDICALERTTHEMEPREMIUM
    ===============================
            GENERAL SCSS
    ===============================

*/
//Moved these two imports to htmlHead.isml
@import "skin/skinVariables";

/*
    ============================
        WORDPRESS OVERWRITE
    ============================
*/

body{
  background-color:#ffffff;
}

#maincontent{
    background: var(--skin-background-color-1);
}
img{
  width:100%;
  height:auto;
  max-width:100%;
  max-height: 100%;
}
.plp-Accordian-button{
  img{
    width:auto;
  }
}
// ALL LINKS
// header ~ #maincontent .container a:not(.btn-primary):not(.btn-outline-primary):not(.wp-block-button__link){
//   color: var(--skin-link-color-2);
// }


.us-autocomplete-pro-menu {
  background-color: gray;
  font-size: rem(14px);
  border: solid 1px grey;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  border: solid 1.5px #c0c4c8;
}

figure {
  margin: 0
}
[tabindex="-1"]:focus {
    outline: 0 !important;
}

div.container{
    max-width:1920px;
}

.modal-backdrop.show{
  // z-index: 999999;
}

.modal-open .modal{
  z-index: 9999999;
}

/*
    ==================================
        MAF DESIGN SYSTEM GENERAL
    ==================================
*/
.maf-element,
.maf-component,
.maf-organism,
.maf-element-group,
.maf-organism-group,
.maf-component-group{
  position:relative;
}
.maf-lg-break{
  display: inline-table;
}
.maf-md-vw{
    font-size:3.3vw;
}
.maf-vertical-middle{
  display:block;
  text-align:center;
}
.maf-vertical-middle-100{
  height:100%;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -webkit-box-flex;
  align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
}
.maf-focused{
  border: solid 1px #D14900;
  box-shadow: 0px 1px 9px 0px #d14900;
}
a.maf-clickable-image{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.maf-d-none{
  display: none;
}
span.maf-break-on-mobile{
  display: block;
}

img.pixel{
  height: 1px;
  width: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 576px) {
    .maf-md-vw{
        font-size:1em;
    }
    .maf-vertical-middle{
      display: flex;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: -webkit-box-flex;
      align-items: center;
      -webkit-align-items: center;
      -ms-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-justify-content: center;
    }
}
@media (min-width: 768px) {
  span.maf-break-on-desktop{
    display: block;
  }
  a.maf-no-clickable-item{
    pointer-events: none;
  }
  span.maf-break-on-mobile{
    display: initial;
  }
}

@media (min-width: 992px) {
    .maf-lg-break{
        display: initial;
    }
}

@media (max-width: 370px) {
  .select-container {
    width: 130%;
  }
}

@media (max-width: 768px) {
  nav>div.header {
    //background: #457796;
    background: #f9f9f9;
  }
}
@media (max-width: 768px) {
  .maf-navbar-header-checkout .logo-home {
    padding-left: 0;
  }
}
.secondary-bar .reset {
  margin-top: 0 !important;
  text-transform: uppercase;
  border-radius: 0.1875rem !important;
  color: var(--maf-bootstrap-solid-primary) !important;
  background-color: var(--maf-bootstrap-solid-primary-content);
  border-color: var(--maf-bootstrap-solid-primary);
  &:hover,
  &:focus{
      color: var(--maf-bootstrap-solid-primary-content) !important;
      background-color: var(--maf-bootstrap-solid-primary);
      border-color: var(--maf-bootstrap-solid-primary);
  }
  &.disabled,
  &:disabled{
      opacity: .65;
  }
}

.refinement {
  min-height: 100% !important;
  min-width: 100% !important;
}

.refinements .card-header h2  {
  color: #1D3557 !important;
}
.maf-component-copy-block {
  padding: 0 45px !important;
}
.wp-block-heading {
  font-size: 1.53em;
  color: #1D3557;
}

.search-results .btn{
  min-width: auto;
  margin: 0;
  padding: 0.65rem 1.5rem !important;
  line-height: 1.5;
  max-height: 100%;
}

.grid-header .filter-results {
  text-transform: uppercase;
  border-radius: 0.1875rem !important;
  color: var(--maf-bootstrap-solid-primary-content);
  background-color: var(--maf-bootstrap-solid-primary);
  border-color: var(--maf-bootstrap-solid-primary);
  &:hover,
  &:focus{
      color: var(--maf-bootstrap-solid-primary-content) !important;
      background-color: var(--maf-bootstrap-solid-primary);
      border-color: var(--maf-bootstrap-solid-primary);
  }
  &.disabled,
  &:disabled{
      opacity: .65;
  }
}

.maf-checkout-to-default .btn-primary{
  background-color: var(--maf-bootstrap-solid-primary);
  border-color: var(--maf-bootstrap-solid-primary);
  &:hover,
  &:focus{
    background-color: #15b118;
  }
  border-radius: .1875rem;
}

.promo-code-submit .promo-code-btn{
  margin-top: 0;
  border-radius: 0.1875rem;
  min-height: auto;
}

.continue-shopping-link{
  color: var(--color-info);
  text-decoration: none;
}

.maf-component-cart-help-need a{
  color: var(--color-info);
  font-size: 16px;
  text-decoration: none;
}

.maf-page-layout-cart .card{
  min-height: auto;
}

.maf-page-layout-cart {
  font-style: normal;
  letter-spacing: .05em;
}

.product-delete{
  text-align: end;
  backface-visibility: hidden;
  position: relative;
  transition: .5s color ease;
  cursor: pointer;
}

.actual-membership-plan{
  width: 100%;
  margin: 0 !important;
}

.actual-membership-plan .content-asset {
  font-style: normal;
  letter-spacing: .05em;
}

.maf-membership-detail-info .content-asset .maf-link {
  color: var(--color-info);
  text-decoration: none;
}

.product-edit a{
  color: var(--color-info);
  text-decoration: none;
}

.modal .modal-header{
  border-radius: 0 ;
}

.cart-and-ipay .update-cart-product-global{
  background-color: var(--maf-bootstrap-solid-primary);
  border-color: var(--maf-bootstrap-solid-primary);
  &:hover,
  &:focus{
    background-color: #15b118;
  }
  border-radius: .1875rem;
}
.btn-show-details {
  border-radius: 0.1875rem !important;
  text-transform: uppercase !important;
}
.btn-add-new {
  border-radius: 0.1875rem !important;
  text-transform: uppercase !important;
}

.maf_checkout_buttons .btn-primary {
  text-transform: uppercase !important;
  border-radius: 0.1875rem !important;
  color: var(--maf-bootstrap-solid-primary-content) !important;
  background-color: var(--maf-bootstrap-solid-primary) !important;
  border-color: var(--maf-bootstrap-solid-primary) !important;
  &:hover,
  &:focus{
      color: var(--maf-bootstrap-solid-primary-content);
      background-color: darken($primary, 10%);
      border-color: darken($primary, 10%);
  }
  &.disabled,
  &:disabled{
      opacity: .65;
  }
}

.maf-component-header-no-navigation .header-banner .container {
  padding: 0 !important;
}
#checkout-main {
  span a {
    color: var(--color-info);
    text-decoration: none;
    font-weight: 600;
  }
}