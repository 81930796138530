/*
    @package MEDICALERTTHEMEPREMIUM
    ===============================
            CART SFCC SCSS
    ===============================

*/

.maf-page-layout-cart {
    padding: 1em 0 3em;
    #checkout-main {
      padding: 24px;
    }
    h1.entry-title {
      font-size: 2.3em;
    }
    .maf-component-cart-header {
      margin: 0;
      .cart-continue-shopping {
        margin: 0 0 1em;
        &.cart-continue-shopping-top {
          margin: 1.5em 0 0;
        }
      }
    }
    .line-item-total-price {
      text-align: right;
    }
    .totals {
      background: var(--color-grey3);
      padding: 1% 2%;
      .row {
        margin: 0;
      }
      .maf-component-cart-help-need {
        text-align: center;
        background: var(--color-white);
        padding: 5% 0;
        border: solid 1px grey;
        border-color: var(--color-white);
        margin: 0 0 1em;
        p {
          margin: 0;
          a {
            font-weight: bold;
            font-weight: 800;
          }
        }
      }
      .maf-component-cart-promo-code {
        padding: 0;
        .maf-field-promo-code {
          padding: 0;
          > input {
            min-height: 42px;
          }
        }
        .promo-code-submit {
          padding: 0;
        }
      }
    }
    .maf-component-cart-card {
      padding: 1em 1em 3em;
      background: var(--color-white);
      margin: 1.5em 0 1.5em;
      border: solid 1px gray;
      border-color: var(--color-grey3);
      box-shadow: 1px 1px 4px var(--color-grey5);
      .line-item-header {
        padding: 0;
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      p {
        margin: 0;
        font-weight: bold;
        font-weight: 500;
        font-size: 1em;
        color: var(--color-success);
        &.line-item-label {
          text-transform: capitalize;
          font-weight: bold;
          font-weight: 600;
          color: var(--color-info);
        }
        &.maf-product-name {
          color: var(--color-success);
          font-weight: bold;
          font-weight: 700;
          font-size: 1.3em;
          line-height: 1.2;
        }
      }
      .maf-component-card-left,
      .maf-component-card-right {
        padding: 0;
      }
      .maf-component-card-right {
        padding: 2%;
      }
      .line-item-price {
        text-align: left;
        font-weight: normal;
        font-weight: 500;
      }
      .maf-component-cart-card-item-total-price {
        background: var(--color-grey7);
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: right;
        padding-top: 1%;
        padding-bottom: 1%;
        p,
        div {
          color: var(--color-white);
          font-weight: bold;
          font-weight: 700;
        }
        p {
          margin: 0;
          padding-top: 1%;
          padding-bottom: 1%;
        }
        .price {
          text-align: right;
          padding-top: 1%;
          padding-bottom: 1%;
        }
      }
      .maf-component-donation-info,
      .maf-component-iceqr-info {
        height: 100%;
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        font-size: 1.2em;
      }
    }
    .line-item-membership-wrapper {
      display: -webkit-box;
      display: -moz-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
    .maf-component-card-image {
      width: 100%;
      height: 100%;
      min-height: 100%;
      max-height: 100%;
      width: 73%;
      margin: 0 auto;
      margin-left: 5.3em;
      img {
        max-width: 70%;
      }
    }
    .product-delete {
      position: absolute;
      right: 1em;
      bottom: 0.5em;
      a {
        font-size: 1em;
        color: var(--color-info);
      }
    }
    .maf-component-cart-body {
      .maf-component-cart-card-engraving {
        ul {
          li {
            text-transform: uppercase;
            font-size: 1.1em;
          }
        }
      }
    }
    .maf-component-cart-card-footer {
      background: none;
      padding: 0;
      .maf-membership-detail-info {
        width: 100%;
        max-width: 100%;
        padding: 0;
        margin: 1em 0 2em;
        cite {
          display: block;
          font-size: 0.8em;
          font-style: normal;
        }
        .maf-component-noplan-fineprint {
          p {
            color: var(--color-danger);
          }
        }
      }
    }
    &[class*="-101470000"] {
      .maf-checkout-to-default {
        display: none !important;
      }
      .maf-checkout-to-create-account {
        display: block !important;
      }
    }
  }
  
  #maincontent .promo-code-form {
    input.coupon-code {
      min-height: 42px;
    }
    span {
      &.coupon-error-message {
        color: var(--color-danger);
      }
    }
    button {
      max-height: 42px;
      line-height: 1;
    }
  }
  
  // [START] EDIT PRODUCT
  #editProductModal .maf-need-help {
    display: none !important;
  }
  // [END] EDIT PRODUCT
  
  // [START] DONATION
  .maf-cart-product-Donation .item-attributes > div:not(.product-edit),
  .maf-cart-product-Donation .line-item-promo,
  .maf-cart-product-Donation .line-item-total-price .strike-through,
  .maf-cart-product-Donation .product-card-footer > div > div:first-of-type {
    display: none;
  }
  .maf-card-product-Donation .maf-component-cart-card-item-price {
    display: none;
  }
  .maf-card-product-Donation .maf-component-cart-card-footer {
    padding: 2% 0;
  }
  // [END] DONATION
  
  // [START] CASE HIDE PROMO IF SMART MEDICAL AND DONATION
  .maf-coupon-display-SMARTMEDICALIDONLY,
  .maf-shipping-and-processing.SMARTMEDICALIDONLY,
  .shipping-discount.SMARTMEDICALIDONLY,
  .maf-shipping-and-processing.ENROLL50SMARTMEDICALIDONLY,
  .shipping-discount.ENROLL50SMARTMEDICALIDONLY {
    display: none;
  }
  
  // [END] CASE HIDE PROMO IF SMART MEDICAL AND DONATION
  
  @media (min-width: 768px) {
    .maf-page-layout-cart {
      .maf-component-cart-card {
        .maf-component-cart-header {
          margin: 0 0 1em;
        }
        .line-item-price {
          text-align: right;
        }
        .maf-component-card-image {
          width: 100%;
        }
      }
      .maf-component-cart-card-footer {
        .maf-membership-detail-info {
          padding: 0% 0 1% 2.5em;
          margin: 0% 0 0;
          max-width: 83%;
        }
      }
    }
    #cart-checkout-button {
      padding: 0;
      #plans-compare {
        padding-top: 16px !important;
      }
      h2 {
        font-size: 1.5rem !important;
      }
      .h3 {
        font-size: 1.5rem !important;
      }
      h3 {
        font-size: 1.5rem;
      }
      .list-group-item {
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .modal-content {
        margin-top: 74px;
      }
      .card {
        padding-bottom: 25px !important;
        min-width:auto;
      }
      p {
        margin-bottom: 0 !important;
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    .maf-element-cart-checkout-button {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 99;
      div {
        padding: 0;
      }
    }
    .cart-continue-shopping {
      margin: 1em 0 0;
    }
  }
  
  // [START] MINI CART
  .popover .maf-component-cart-card-engraving,
  .popover .quantity-form {
    display: none;
  }
  .popover .line-item-unit-price {
    visibility: hidden;
  }
  
  .maf-checkout-to-default {
    #planModal-desktop .modal-dialog {
      max-width: 83%;
      margin: auto;
      .close {
        color: white;
        position: absolute;
        top: 0;
        right: 12px;
        font-size: 40px;
        cursor: pointer;
      }
    }
    #planModal-desktop .modal-body-membership {
      #plans-compare {
        background-color: #1d3557 !important;
      }
    }
  }
  // [END] MINI CART
  