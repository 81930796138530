/*
    @package MEDICALERTTHEMEPREMIUM
    ===================================
        PROGRESS BAR COMPONENT SCSS
    ===================================

*/

.maf-component-progress-bar-wrap{
  color: var(--color-success);
  font-weight: bold;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-align: center;
  .maf-component-progress-bar{
    background-color: var(--color-grey4);
    border-radius: 16px;
    width: 100%;
    margin: 0 auto;
    max-width: 333px;
    > div{
        color: var(--color-white);
        background-color: var(--color-info);
        border-radius: 16px;
        padding: 0.4em 16px;  
      }
  }
}
