/*
    @package MEDICALERTTHEMEPREMIUM
    =============================================
        MOST RECENT 2 COLUMNS COMPONENT SCSS
    =============================================

*/

div.maf-component-most-recent-page-2-columns {
    padding: 1em 2em;
}
div.maf-component-most-recent-page-2-columns article{
    background: #F0F9F9;
    padding: 6% 8%;
    min-height: 19em;
    border-radius: 30px;
}
