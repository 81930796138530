/*
    @package MEDICALERTTHEMEPREMIUM
    ===============================
            PAGE LAYOUTS SCSS
    ===============================
*/


// CONTAINER
div.maf-page-layout{
    // margin: 3% auto;
    // width: 94%;
}

//FUL BLEED
.maf-page-layout-full-bleed {
    margin: 0 0 3%;
    div.wp-block-columns{
        width: 94%;
        margin: 0 auto;
    }
}

@media (min-width:768px) {
    
    .maf-padding-left-and-right{
        padding: 0 3%;
    }
    .maf-padding-left-only{
        padding: 0 0 0 3%;
    }

}

@media (min-width:960px) {
    
    .maf-padding-left-and-right{
        padding: 0 7%;
    }
    .maf-padding-left-only{
        padding: 0 0 0 7%;
    }

}

@media (min-width:1300px) {
    
    .maf-padding-left-and-right{
        padding: 0;
    }
    .maf-padding-left-only{
        padding: 0;
    }

}
