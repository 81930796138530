/*
    @package MEDICALERTTHEMEPREMIUM
    ==================================
        ENGRAVING COMPONENT SCSS
    ==================================

*/

.maf-component-engraving{
    .engraving-preview{
        font-size: 0.8em;
        border-radius: 30px;
        margin: 0 auto;
        padding: 0;
        min-height: 100px;
        width: 100%;
        flex: 1;
        .engraving-wrap{
            height: 100%;
            text-align: center;
            width: 100%;
            display: -webkit-box;
            display: -moz-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: 100%;
            background: #E5E7E8;
            border-radius: 40%;
            #engravingwrap{
                margin: 1em 0;
                text-transform: uppercase;
            }
            &>div{
                margin: 2em 0;
            }
            p{
                margin: 0;
                opacity: 0.4;
                text-transform: uppercase;
                font-weight: bold;
                font-weight: 600;
                text-shadow: 1px 0px 1px #5d5c5c;
            }
        }
        &> div{
            background: #e8e8e8;
            border-radius: 30px;
            width: 77%;
            margin: 0 auto;
            padding: 0% 0;
            text-align: center;
        }
    }
    .maf-need-help{
        margin: 1em auto 0;
        text-align: center;
        width: 100%;
        cursor: pointer;

    }
    .engraving-fields{
        form {
            text-align: right;
            &> div{
                position:relative;
                margin: 0 0 1%;
                label{
                    display: inline-block;
                    text-align: left;
                    color: #000;
                }
            }
            input{
                width: 80%;
                color:#000;
                border-color: #000;
                text-transform: uppercase;
                border: solid 1px;
                &::placeholder {
                    color: #757575;
                    font-weight: 300;
                    font-weight: lighter;
                    opacity: 1; /* Firefox */
                }
                &::-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: #757575;
                    font-weight: 300;
                    font-weight: lighter;
                }
                &::-ms-input-placeholder { /* Microsoft Edge */
                    color: #757575;
                    font-weight: 300;
                    font-weight: lighter;
                }
            }
            .maf-confirm-personalization{
                margin: 1em 0 1em 0em;
                padding: 1em;
                input{
                    width: auto;
                }
            }
        }
        h2{
            font-size: 1em;
            text-transform: uppercase;
            font-weight: bold;
            font-weight: 600;
        }
        .counter{
            position: absolute;
            bottom: 0;
            right: 0;
            background: #37434f;
            padding: 0.15em 0.5em;
            color: #fff;
            min-width: 2.3em;
            text-align: center;
            height: 30px;
        }
    }
}
.maf-component-modal-engraving-help{
    z-index: 99999999 !important;
    .modal-content{
        .modal-body{
            padding: 1.5em 1em 3em;
        }
        ul.nav-tabs{
            li.nav-item{
                width: 50%;
                text-align: center;
                line-height: 1;
                a{
                    padding: 1em;
                }
            }
        }
        .tab-content{
            padding: 0 0.5em;
            h4{
                font-weight: bold;
                font-weight: 600;
            }
        }
    }
}

.maf-engraving-line:read-only {
    background-color: var(--color-grey2)
}
.maf_personalization_line.field_disabled{
    opacity: 0.4;
    pointer-events: none;
}

@media (min-width: 768px) {
    .maf-component-engraving{
        .engraving-fields{
            form {
                input{
                    width: 80%;
                    text-transform: uppercase;
                }
            }
        }
    }
    .maf-component-modal-engraving-help{
        .modal-content{
            ul.nav-tabs{
                li.nav-item{
                    width: 50%;
                    text-align: center;
                    line-height: 1;
                }
            }
            .tab-content{
                padding: 0 0.5em;
                h4{
                    font-weight: bold;
                    font-weight: 600;
                }
            }
        }
    }    
}

@media (min-width: 992px) {
    .maf-component-engraving{
        .engraving-fields{
            form {
                input{
                    width: 83%;
                    text-transform: uppercase;
                }
            }
        }
    }
}

@media (min-width: 1300px) {
    .maf-component-engraving{
        .engraving-fields{
            form {
                input{
                    width: 25vw;
                    text-transform: uppercase;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .maf-component-engraving .engraving-fields form{        
        text-align: left;
        input{
            width: 100%; 
        }
    }
}
