
.filter-container-design{
    padding: 0;
}

.sidebar-option-text{
    align-items: center !important;
}

.filter-section .custom-checkbox-filter .refinement-checked::before {
    content: "";
    border: solid 1px #1d3557;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('./img/ui-icons/icon_checks.svg');
    background-size: contain;
    cursor: pointer;
    border-radius: 4px;
    background-color: #1d3557;
}
.filter-section .refinement-checked::before {
    content: "";
    // border: solid 1px #1d3557;
    display: inline-block;
    width: 32px;
    height: 27px;
    background: url('./img/ui-icons/radio-checked-new.svg') no-repeat center center;
    
    background-size: contain;
    cursor: pointer;
    // border-radius: 4px;
    // background-color: #1d3557;
}
.filter-section .refinement::before {
    content: "";
    // border: solid 1px #1d3557;
    display: inline-block;
    background: url('./img/ui-icons/radio-unchecked-new.svg') no-repeat center center;
    width: 32px;
    height: 27px;
    background-size: contain;
    padding: 0 !important;
    cursor: pointer;
    // border-radius: 4px;
    // background-color: #1d3557;
}
.sidebar-option img {
    width: auto !important;
}
.custom-canvas-plp img {
    width: auto !important;
}
.custom-canvas-plp-sort img{
    width: auto !important;
}
.sidebar-canvas-index {
    z-index: 99999 !important;
}
.filter-section .filter-button .filter-button-content {
    width: 100% !important;
}
.refinement-option-button{
    appearance: none;
    background-color: transparent;
    border: none;
    // margin: 0;
    // padding: 0;
    font-family: inherit;
    cursor: pointer;
    outline: none;
    img{
        width: auto;
    }
}
.filter-chips{
    ul {
        list-style-type: none;
    }
    li{
        display: inline-block;
    }
}

.sidebar-backButton {
    width: auto !important;
}
.offcanvas-close-button {
    width: auto !important;
}

.filter-section {
    margin-top: 0 !important;
    .label{
        margin-bottom: 0;
    }
    li{
        height: 20px;
    }
}

.custom-radio-label{
    height: 20px;
    display: flex !important;
    align-items: center !important;
}

@media(max-width: 1260px) {

    .filter-container-design{
        max-width: 880px;
        // margin: auto;
    }

}

@media only screen and (max-width: 1024px) {
    #filterChipsContainerr {
        display: none !important;
    }
}
.clearAllSidebarCanvas {
    color: #1D3557 !important;
    background-color: #fff !important;
}
.plp-gap {
    gap: 0px !important;
}
.hit-count-li {
    width: 100%;
    justify-content: space-between;
    font-size: 18px;
    font-weight: normal;
    color: #051424;
    line-height: 1;
    letter-spacing: normal;
    font-family: "SourceSansPro SemiBold";
}
.color-scroll {
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;

}
