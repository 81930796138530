/*
    @package MEDICALERTTHEMEPREMIUM
    ========================================
        SEO COPYBLOCKS PLP COMPONENT SCSS
    ========================================

*/
.maf-component-copy-block{
    font-size: 0.75em;
    padding: 0 15px;
}

@media (min-width: 992px) {
    .maf-component-copy-block{
        padding: 0 45px;
    }
}
