/*
    @package MEDICALERTTHEMEPREMIUM
    ===============================
            PLP SFCC SCSS
    ===============================

*/

.search-results{
    margin: 0;
}

div.search-results.container,
div.search-results .container,
div.container.maf-plp-banner-wrap{
    max-width:1176px;
}

div.search-results{
    padding: 0 ;
}

div.search-results > div.row{
    margin:0;
}

div.search-results > div.search-nav{
    margin: 0 0 1em;
}

div#maincontent .maf-plp-banner-wrap{
    padding:0;
    margin-left: 0 !important;
    margin-right: 0  !important;
    width: 100% !important;
}

#product-search-results{
    .refinement-bar{
        #refinement-category{
            ul{
                ul{
                    li{
                        margin-left: 0;
                        &.all-medical-ids,
                        &.push-button {
                            display: none !important;
                        }
                    }
                }                
            }
        }            
    }
    .product-grid{
        .product-tile{
            img{
                &.tile-image {
                    // background: #fefefe;
                    // width: 100%;
                    // height: 100%;
                    display: block;
                    border: 0;
                }
            }
            .loader{
                background: var(--color-white);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.plp-card-layout{
    padding: 0;
    .result-count{
        max-width: 1176px;
        margin: 0 auto 32px;
        padding: 0;
        font-family: "SourceSansPro Regular";
        font-size: 1.125em;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
       
    }
    .plp-card-container-wrap{
        max-width: 1176px;
        margin: 0;
        padding: 0;
    }
}

#product-search-results{
    .result-count{
        max-width: 1176px;
        margin: auto;
        font-size: 1.125em;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #051424;     
        @media (max-width: 992px) {
            font-size: 14px !important;
            margin-left: 16px;
            margin-right: 15px;
        }   
    }
}

@media (min-width: 768px) {
    .search-results .tab-content,
    .search-results #product-search-results{
        padding:0;
    }
    div.search-results{
        // padding: 0 30px;
    }
    .maf-component-panel-ctas{
        > div{
            display: inline-block;
        }
    }
    #maincontent {
        .maf-component-panel-ctas{
            > button{
                width: 30%;
            }
        }
    }
}
@media (min-width: 992px) {
    div.search-results{
        padding: 0;
    }
}

#product-search-results{
    padding-left: 0;
    padding-right: 0;
}


@media (max-width: 544px) {
    div#product-search-results,
    div#product-search-results div:nth-of-type(2) > div.col-sm-12{
        padding: 0;
    }
    div#product-search-results .row{
        margin: 0;
        // padding-left: 0 !important;
        // padding-right: 0 !important;
    }
    div#product-search-results .grid-header{
        margin: 0;
    }
    div#product-search-results .grid-header div.col-6{
        padding:0;
    }
    div.search-results > div.search-nav{
        margin: 0;
    }
    // [START] Product Grid
    .product-grid > div{
        // box-shadow: -1px 7px 30px #e5e7e8;
        // margin: 0 0 3%;
        // border-radius: 30px;
        // &:last-of-type{
        //     box-shadow: none;
        //     border-radius: 0;
        //     margin: 0;
        // }
        .pdp-link{
            font-size: 1.4em;
            font-weight: bold;
            font-weight: 600;
        }
        .price{
            // font-size: 1.3em;
            span{
                // color: #1d3557;
            }
        }
    }
    // [END] Product Grid

    // [START] Filter and Sorting
    #product-search-results{
        // position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background: #ffffff;
        z-index: 99;
        box-shadow: -3px -1px 3px #cccccc;
        .product-grid{
            padding-top:0;
        }
    }

    // [END] Filter and Sorting
}

@media (max-width: 768px) {
    .refinement-bar{
        z-index: 99999;
        min-width: 100%;
        position: fixed;
    }
    div#product-search-results div.refinement-bar div.col-sm-12{
        padding: .25rem 1rem;
    }
    #maincontent div.refinement-bar .refinements button{
        padding: 0;
    }
}

@media (max-width: 970px) {
    div.search-results.container.maf-plp-header{
        max-width: 100%;
    }
}

@media (max-width: 1260px) {
    div.search-results.container.maf-plp-header{
        max-width: 880px;
    }
}
