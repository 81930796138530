/*
    @package MEDICALERTTHEMEPREMIUM
    ===============================
        BLOCK COVER - GUTTENBERG
    ===============================

*/

.maf-hero-paralax-cms-1{
    padding: 0;
    .wp-block-cover__inner-container{
        background: #1d3557cc;
        padding: 7% 2%;
        h2{
            width: 100%;
            margin: 0 auto 3%;
        }
        .wp-block-columns{
            margin: 3% auto;
        }
    }
    &.has-celadon-blue-background-color{
        background: var(--color-info);
        .wp-block-cover__inner-container{
            background:none;
        }
    }
}


@media (min-width: 768px) {
    .maf-hero-paralax-cms-1{
        .wp-block-cover__inner-container{
            padding: 7%;
            h2{
                width: 50%;
            }
        }
    }
}
