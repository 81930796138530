/*
    @package MEDICALERTTHEMEPREMIUM
    ==================================
            BUTTON ELEMENTS SCSS
    ==================================

*/

// @import "../skin/skinVariables";
// @import "../variables";

/*
    ============================
        WORDPRESS OVERWRITE
    ============================
*/

#maincontent{
    .product-detail{
        button.btn,
        input.btn,
        a.btn,
        .maf-button a.btn-content,
        .maf-button a,
        .wp-block-button a{
            // display: inline-block;
            font-size: 0.98rem;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            font-weight: bold;
            font-weight: 600;
            text-decoration: none;
            min-width: 233px;
            min-height: 41px;
            margin: 1.5em 0 0;
            box-shadow: 1px ​1px 2px 0px transparent;
            // border-radius: 1.2rem;
            // border: solid 2px;
            padding: 0.75rem 1.5rem;
            outline: none;
            text-align: center;
            vertical-align: middle;
            -webkit-user-select: none;
                -ms-user-select: none;
                    user-select: none;
            line-height: 1;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; 
            &:hover,
            &:focus{
                box-shadow: 0 0 0 0.2rem rgba(4, 204, 204, 0.5);
            }
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            position: relative;
            z-index: 2;
        }
    }
}
#maincontent .search-results,
#maincontent .cart-page,
#maincontent form,
#maincontent div.maf-element-checkout-buttons{
    .btn{
        min-width: auto;
        margin: 0;
        padding: 0.65rem 1.5rem;
        line-height: 1.5;
    }
}

/*
    =====================
        GLOBAL BUTTONS
    =====================
*/
.wp-block-button:focus{
    box-shadow: 0 0 0 0.2rem rgba(38, 175, 229, 0.5);
}
.btn.disabled, .btn:disabled,
a.disabled, a:disabled {
    opacity: .65;
}
button.disabled{
    pointer-events: none;
    opacity: .65;
}
button.maf-disabled{
    opacity: .65;
}
.maf-not-pointer{
    pointer-events: none;
}



/*
    =====================
        PRIMARY BUTTONS
    =====================
*/
.product-detail{
    a.btn-primary,
    button.btn-primary,
    a.has-spanish-viridian-background-color,
    button.has-spanish-viridian-background-color{
        color: var(--maf-bootstrap-solid-primary-content);
        background-color: var(--maf-bootstrap-solid-primary);
        border-color: var(--maf-bootstrap-solid-primary);
        &:hover,
        &:focus{            
            color: var(--maf-bootstrap-solid-primary-content);
            background-color: darken($success, 10%);
            border-color: darken($success, 10%);
        }
        &.disabled,
        &:disabled{
            opacity: .65;
        }
    }
}

// .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
// .show > .btn-primary.dropdown-toggle {
//     color: #fff;
//     background-color: #007cad;
//     border-color: #0073a0;
// }
// .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
// .show > .btn-primary.dropdown-toggle:focus {
//     box-shadow: 0 0 0 0.2rem rgba(38, 175, 229, 0.5);
// }



/*
    ===============================
        OUTLINE PRIMARY BUTTONS
    ===============================
*/

.product-detail{
    a.btn-outline-primary,
    button.btn-outline-primary,
    .is-style-outline a.has-spanish-viridian-color,
    .is-style-outline button.has-spanish-viridian-color{
        color: var(--maf-bootstrap-solid-primary);
        background-color: var(--maf-bootstrap-solid-primary-content);
        border-color: var(--maf-bootstrap-solid-primary);
        &:hover,
        &:focus{
            color: var(--maf-bootstrap-solid-primary-content);
            background-color: var(--maf-bootstrap-solid-primary);
            border-color: var(--maf-bootstrap-solid-primary);
        }
        &.disabled,
        &:disabled{
            opacity: .65;
        }
    }
}

/*
    =========================
        SECONDARY BUTTONS
    =========================
*/

.product-detail{    
    a.btn-secondary,
    button.btn-secondary,
    a.has-prussian-blue-background-color,
    button.has-prussian-blue-background-color{
        color: var(--maf-bootstrap-solid-secondary-content);
        background-color: var(--maf-bootstrap-solid-secondary);
        border-color: var(--maf-bootstrap-solid-secondary);
        &:hover,
        &:focus{
            color: var(--maf-bootstrap-solid-secondary-content);
            background-color: darken($secondary, 10%);
            border-color: darken($secondary, 10%);
        }
        &.disabled,
        &:disabled{
            opacity: .65;
        }
    }
}
/*
    ===============================
        OUTLINE SECONDARY BUTTONS
    ===============================
*/


.product-detail{    
    a.btn-outline-secondary,
    button.btn-outline-secondary,
    .is-style-outline a.has-beau-blue-background-color,
    .is-style-outline button.has-beau-blue-background-color{
        color: var(--maf-bootstrap-solid-secondary);
        background-color: var(--color-white);
        border-color: var(--maf-bootstrap-solid-secondary);
        &:hover,
        &:focus{
            color: var(--maf-bootstrap-solid-secondary-content);
            background-color: var(--maf-bootstrap-solid-secondary);
            border-color: var(--maf-bootstrap-solid-secondary);
        }
        &.disabled,
        &:disabled{
            opacity: .65;
        }
    }
}


/*
    =======================
        SUCCESS BUTTONS
    =======================
*/

.product-detail{
    button.btn-success,
    button.btn-success,
    a.has-prussian-blue-color,
    button.has-prussian-blue-color{
        color: var(--maf-bootstrap-solid-success-content);
        background-color: var(--maf-bootstrap-solid-success);
        border-color: var(--maf-bootstrap-solid-success);
        &:hover,
        &:focus{
            color: var(--maf-bootstrap-solid-success-content);
            background-color: darken($success, 10%);
            border-color: darken($success, 10%);
        }
        &.disabled,
        &:disabled{
            opacity: .65;
        }
    }
}

/*
    ===============================
        OUTLINE SUCCESS BUTTONS
    ===============================
*/

.product-detail{
    a.btn-outline-success,
    button.btn-outline-success,
    .is-style-outline a.has-prussian-blue-color,
    .is-style-outline button.has-prussian-blue-color{
        color: var(--maf-bootstrap-solid-success);
        background-color: var(--color-white);
        border-color: var(--maf-bootstrap-solid-success);
        &:hover,
        &:focus{
            color: var(--maf-bootstrap-solid-success-content);
            background-color: var(--maf-bootstrap-solid-success);
            border-color: var(--maf-bootstrap-solid-success);
        }
        &.disabled,
        &:disabled{
            opacity: .65;
        }
    }
}


/*
    =====================
        INFO BUTTONS
    =====================
*/

.product-detail{
    a.btn-info,
    button.btn-info,
    a.has-celadon-blue-background-color,
    button.has-celadon-blue-background-color{
        color: var(--maf-bootstrap-solid-info-content);
        background-color: var(--maf-bootstrap-solid-info);
        border-color: var(--maf-bootstrap-solid-info);
        &:hover,
        &:focus{
            color: var(--maf-bootstrap-solid-info-content);
            background-color: darken($info, 10%);
            border-color: darken($info, 10%);
        }
        &.disabled,
        &:disabled{
            opacity: .65;
        }
    }
}

/*
    ===============================
        OUTLINE INFO BUTTONS
    ===============================
*/


.product-detail{
    a.btn-outline-info,
    button.btn-outline-info,
    .is-style-outline a.has-celadon-blue-color,
    .is-style-outline button.has-celadon-blue-color{
        color: var(--maf-bootstrap-solid-info);
        background-color: var(--maf-bootstrap-solid-info-content);
        border-color: var(--maf-bootstrap-solid-info);
        &:hover,
        &:focus{
            color: var(--maf-bootstrap-solid-info-content);
            background-color: var(--maf-bootstrap-solid-info);
            border-color: var(--maf-bootstrap-solid-info);
        }
        &.disabled,
        &:disabled{
            opacity: .65;
        }
    }
}


/*
    =====================
        DANGER BUTTONS
    =====================
*/


.product-detail{
    a.btn-danger,
    button.btn-danger,
    a.has-rose-madder-background-color,
    button.has-rose-madder-background-color{
        color: var(--maf-bootstrap-solid-danger-content);
        background-color: var(--maf-bootstrap-solid-danger);
        border-color: var(--maf-bootstrap-solid-danger);
        &:hover,
        &:focus{
            color: var(--maf-bootstrap-solid-danger-content);
            background-color: darken($danger, 10%);
            border-color: darken($danger, 10%);
        }
        &.disabled,
        &:disabled{
            opacity: .65;
        }
    }
}



/*
    ===============================
        OUTLINE DANGER BUTTONS
    ===============================
*/


.product-detail{
    a.btn-outline-danger,
    button.btn-outline-danger,
    .is-style-outline a.has-rose-madder-color,
    .is-style-outline button.has-rose-madder-color{
        color: var(--maf-bootstrap-solid-danger);
        background-color: var(--maf-bootstrap-solid-danger-content);
        border-color: var(--maf-bootstrap-solid-danger);
        &:hover,
        &:focus{
            color: var(--maf-bootstrap-solid-danger-content);
            background-color: var(--maf-bootstrap-solid-danger);
            border-color: var(--maf-bootstrap-solid-danger);
        }
        &.disabled,
        &:disabled{
            opacity: .65;
        }
    }
}


/*
    =====================
        WARNING BUTTONS
    =====================
*/


.product-detail{
    a.btn-warning,
    button.btn-warning,
    a.has-sinopia-background-color,
    button.has-sinopia-background-color{
        color: var(--maf-bootstrap-solid-notification-content);
        background-color: var(--maf-bootstrap-solid-notification);
        border-color: var(--maf-bootstrap-solid-notification);
        &:hover,
        &:focus{
            color: var(--maf-bootstrap-solid-notification-content);
            background-color: darken($notification, 10%);
            border-color: darken($notification, 10%);
        }
        &.disabled,
        &:disabled{
            opacity: .65;
        }
    }
}


/*
    ===============================
        OUTLINE WARNING BUTTONS
    ===============================
*/

.product-detail{
    a.btn-outline-warning,
    button.btn-outline-warning,
    .is-style-outline a.has-sinopia-color,
    .is-style-outline button.has-sinopia-color{
        color: var(--maf-bootstrap-solid-notification);
        background-color: var(--maf-bootstrap-solid-notification-content);
        border-color: var(--maf-bootstrap-solid-notification);
        &:hover,
        &:focus{
            color: var(--maf-bootstrap-solid-notification-content);
            background-color: var(--maf-bootstrap-solid-notification);
            border-color: var(--maf-bootstrap-solid-notification);
        }
        &.disabled,
        &:disabled{
            opacity: .65;
        }
    }
}


/*
    ====================
        DARK BUTTONS
    ====================
*/

.product-detail{
    a.btn-dark,
    button.btn-dark,
    a.has-black-froga-background-color,
    button.has-black-froga-background-color{
        color: var(--maf-bootstrap-solid-dark-content);
        background-color: var(--maf-bootstrap-solid-dark);
        border-color: var(--maf-bootstrap-solid-dark);
        &:hover,
        &:focus{
            color: var(--maf-bootstrap-solid-dark-content);
            background-color: darken($black, 10%);
            border-color: darken($black, 10%);
        }
        &.disabled,
        &:disabled{
            opacity: .65;
        }
    }
}


/*
    ===============================
        OUTLINE DARK BUTTONS
    ===============================
*/


.product-detail{
    a.btn-outline-dark,
    button.btn-outline-dark,
    .is-style-outline a.has-black-froga-color,
    .is-style-outline button.has-black-froga-color{
        color: var(--maf-bootstrap-solid-dark);
        background-color: var(--maf-bootstrap-solid-dark-content);
        border-color: var(--maf-bootstrap-solid-dark);
        &:hover,
        &:focus{
            color: var(--maf-bootstrap-solid-dark-content);
            background-color: var(--maf-bootstrap-solid-dark);
            border-color: var(--maf-bootstrap-solid-dark);
        }
        &.disabled,
        &:disabled{
            opacity: .65;
        }
    }
}


/*
    =====================
        LIGHT BUTTONS
    =====================
*/


.product-detail{
    a.btn-light,
    button.btn-light,
    a.has-mint-cream-background-color,
    button.has-mint-cream-background-color{
        color: var(--maf-bootstrap-solid-light-content);
        background-color: var(--maf-bootstrap-solid-light);
        border-color: var(--maf-bootstrap-solid-light);
        &:hover,
        &:focus{
            color: var(--maf-bootstrap-solid-light-content);
            background-color: darken($light-blue, 10%);
            border-color: darken($light-blue, 10%);
        }
        &.disabled,
        &:disabled{
            opacity: .65;
        }
    }
}


/*
    ===============================
        OUTLINE LIGHT BUTTONS
    ===============================
*/


.product-detail{
    a.btn-outline-light,
    button.btn-outline-light,
    .is-style-outline a.has-mint-cream-color,
    .is-style-outline button.has-mint-cream-color{
        color: var(--maf-bootstrap-solid-light);
        background-color: var(--maf-bootstrap-solid-dark-content);
        border-color: var(--maf-bootstrap-solid-light);
        &:hover,
        &:focus{
            color: var(--maf-bootstrap-solid-light-content);
            background-color: var(--maf-bootstrap-solid-light);
            border-color: var(--maf-bootstrap-solid-light);
        }
        &.disabled,
        &:disabled{
            opacity: .65;
        }
    }
}



/*
    ==========================
        OUTLINE LINK CTAs
    ==========================
*/
.product-detail {
    button.btn-link,
    a.btn-link{
    font-weight: bold;
    font-weight: 600;
    border: none;
    padding: 0;
    margin: 0;
    color: var(--color-blue);
    min-width: initial;
    &:hover,
    &:focus{
        text-decoration: none;
        color: #1D3557;
    }
    }
    .product-detail{
        button.btn-link,
        a.btn-link{
            font-size: 0.85em;
        }
    }
}
.btn-link {
    font-weight: 400;
    color: #444;
    text-decoration: none;
}
.btn-link:hover {
    color: #1e1e1e;
    text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
}
.maf-link:hover > span,
.maf-link:focus > span{
    font-weight: bold;
    font-weight: 600;
}
.maf-link:after{
    content: "";
}
.maf-link span{
    border-bottom: solid 1px;
    position: absolute;
    bottom: -3%;
    width: 100%;
    left: 0;
    color: inherit;
}
.maf-link-underline{
    text-decoration: none;
    border-bottom: solid 1px;
    display: inline-block;
    cursor: pointer;
    &:hover,
    &:focus{
        text-decoration: none;
    }
}

/*  [START] Sizes Buttons */
.btn-lg, .btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.1875rem;
}
.btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.1875rem;
}

.btn-block {
    display: block;
    width: 100%;
}
.btn-block + .btn-block {
    margin-top: 0.5rem;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
    width: 100%;
}
/*
    ========================
        BUTTON POST MORE
    ========================
*/

a.maf-element-button-post-more{
    border-radius: 30px;
    padding: 0.5em 1.5em;
    margin: 1em 0 0;
}
a.maf-element-button-post-more span.arrow{
    visibility: hidden;
    opacity: 0;
    display:none;
    transition: visibility 0s, opacity 0.5s linear;
}
a.maf-element-button-post-more:hover span.arrow,
a.maf-element-button-post-more:focus span.arrow {
    color:#ffffff;
    visibility: visible;
    opacity: 1;
    display:inline-block;
}
/*
    ========================
        PIPE LINKS LIGHT
    ========================
*/

.maf-link-light a.nav-link{
    color:var(--maf-o-pipe_link-text);
    background:var(--maf-o-pipe_link-background);
}
.maf-link-light a:hover,
.maf-link-light a:focus{
    color:var(--maf-o-pipe_link-text-hover);
    background:var(--maf-o-pipe_link-background-hover);
}
/*
    =============================
        TEXT LINK ANIMATION 1
    =============================
*/


ul.maf-text-link-1 a, a.maf-link, .maf-page-layout-content a,
ul.maf-text-link-1 a:visited, a.maf-link:visited, .maf-page-layout-content a:visited,
ul.maf-text-link-1 a:hover, a.maf-link:hover{
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    position:relative;
    transition:0.5s color ease;
    text-decoration:none;
}
ul.maf-text-link-1 a:after,
a.maf-link:after,
.maf-page-layout-content a:not(.btn, .wp-block-button__link):after{
    text-decoration:none;
    content: "";
    transition:0.5s all ease;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    position:absolute;
    bottom:-0.25em;
    height:1px;
    width:0;
    background:var(--color-info);
    left:50%;
    -webkit-transform:translateX(-50%);
    transform:translateX(-50%);
}
.maf-global-banner a.maf-link:after,
a.continue-shopping-link:after,
.maf-page-layout-content a:after{
    background:var(--color-info);
}
footer ul.maf-text-link-1 a:after{
    background:var(--color-white);
}

ul.maf-text-link-1 a:hover:after,
a.maf-link:hover:after,
.maf-page-layout-content a:hover:after{
    width:100%;
}
a.edit.maf-link{
    font-size: 1em !important;
}
a.maf-link{
    width: auto;
    color:var(--color-info);
    cursor: pointer;
    font-size: 1em;
    color:var(--color-info);
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
}
@media (max-width: 992px) {
    .product-detail{
        button.btn,
        .maf-button a.btn-content,
        .maf-button a,
        .wp-block-button a{
            min-width: auto;
        }
    }
}
@media (max-width: 768px) {
    .maf-component-fix-mob-down{
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 99;
        width: auto;
        border-radius: 0 30px 30px 0;
        padding: 1em;
        margin: 0;
        background: #ffffffc7;
        a{
            min-width: auto;
            width: auto;
            margin: 0 auto;
            display: block;
        }
    }
    .maf-page-layout-content a:not(.btn, .wp-block-button__link){
        text-decoration:underline;
    }

    // CART & CHECKOUT
    .maf-element-cart-checkout-button,
    .maf-element-checkout-buttons{
        position:fixed;
        bottom:0;
        left: 0;
        width: 100%;
        z-index:999;
    }
    .maf-element-cart-checkout-button div,
    .maf-element-checkout-buttons div{
        padding:0;
        background: var(--color-white);
    }
    // END CART & CHECKOUT

}
