/*
    @package MEDICALERTTHEMEPREMIUM
    ==================================
        QUICK VIEW COMPONENT SCSS
    ==================================
*/

.quick-view-dialog {
    .modal-body{
        max-height: 30em;
    }
    .modal-footer{
        background: var(--color-light-blue);
        padding: 0;
    }
}
.product-quickview #membershipPlans option[data-value-id="00000"],
.protection-plan-00000{
    display: none !important;
}
.maf-logged-in.maf-has-plan{
    #editProductModal{
        .product-option{
            display: none;            
            #membershipPlans{
                option[data-value-id="80161"] {
                    display: none;
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .quick-view-dialog {
        .modal-footer{
            .col-8{
                padding: 0 0 1.5em;
            }
        }
    }
}
