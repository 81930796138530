/*
    @package MEDICALERTTHEMEPREMIUM

*/

@import "../variables";
:root {
    /** ==================================================================== **/
    /** Create Custom CSS Variables from "sfra bootstrap override" variables **/
    /** ==================================================================== **/
    --color-inherent: none; /* take background color */
    --color-white: #ffffff; /* white */
    --color-black: #051424; /* Rich Black Fogra */
    --color-primary: #008827; /* Spanish Viridian */
    --color-secondary: #AAC3D2; /* Beau Blue */
    --color-blue: #457796; /* Celadon Blue */
    --color-blue-2: #AAC3D2; /* Beau Blue */
    --color-light-blue: #F0F9F9; /* Mint Cream */
    --color-orange: #D14900; /* Sinopia */
    --color-green: #0A8558; /* Spanish Viridian */
    --color-red: #D82A32; /* Rose Madder */
    --color-info: var(--color-blue);
    --color-success: #1D3557;
    --color-notification: var(--color-orange);
    --color-danger: var(--color-red);
    --color-grey1: #fff;
    --color-grey2: #eee;
    --color-grey3: #F7F7F8; /* Cultured */
    --color-grey4: #E5E7E8; /* Platinum */
    --color-grey5: #CDD0D3; /* Light Gray */
    --color-grey6: #6B737B; /* Sonic Silver */
    --color-grey7: #37434F; /* Charcoal */
    --color-grey8: #051424; /* Rich Black Fogra */
    --color-grey-transparent-1: rgba(0, 0, 0, 0.65);
    --color-grey-transparent-2: rgba(0, 0, 0, 0.25);
    --color-light-gray: var(--color-grey1);
    --color-slightly-darker-gray: var(--color-grey4);
    --color-dark-gray: var(--color-grey6);
    --color-darker-gray: var(--color-grey7);
    --color-horizontal-rule-grey: var(--color-grey3);
    --color-product-number-grey: var(--color-grey3);
    --color-horizontal-border-grey: var(--color-grey4);
    --color-menu-link: var(--color-grey6);
    --color-close-menu-bg: var(--color-grey2);
    --color-link-color: var(--color-dark-gray);
    --color-hr-border-color: var(--color-grey3);
    --color-breast-cancer:#EA80B0;
    /** ==================================================================== **/
    /** Define skin named variables here                                    **/
    /** ==================================================================== **/
    --skin-background-color-1: var(--color-white);
    --skin-btn-color-primary: var(--color-primary);
    --skin-border-color-1: var(--color-white);
    --skin-main-text-color-1: var(--color-gray7);
    --skin-main-text-color-2: var(--color-gray7);
    --skin-menu-color-1: #444;
    --skin-menu-color-1-invert: var(--color-white);
    --skin-menu-color-2: #222;
    --skin-link-color-1: #444;
    --skin-link-color-2: var(--color-blue);
    --skin-primary-color-1: var(--color-primary);
    --skin-primary-color-2: var(--color-primary);
    --skin-primary-color-invert-1: var(--color-white);
    --skin-selectbox-background-color-1: var(--color-white);
    --skin-selectbox-text-color-1: var(--skin-main-text-color-1);
    --skin-banner-background-color-1: #444;
    --skin-banner-background-color-2: #222;
    --skin-banner-text-color-1: var(--color-white);
    --skin-heading-color-1: var(--color-primary);
    --skin-heading-color-1-invert: var(--color-white);
    --skin-price-1: var(--color-gray7);
    --skin-header-font: var(--font-family-sans-serif);
    --skin-body-font: var(--font-family-sans-serif);
    // Social
    --skin-facebook-icon: #3b5998;
    --skin-twitter-icon: #00acee;
    --skin-email-icon: var(--color-black);
    --skin-linkedin-icon: #0072b1;

    /** ==================================================================== **/
    /**                    MAF DESIGN SYSTEM SKIN                            **/
    /** ==================================================================== **/

    // BUTTONS
    --maf-e-button-light-dark: var(--color-primary);
    --maf-e-button-light-light: var(--color-white);

    //PRIMARY BUTTON
    --maf-bootstrap-solid-primary: var(--color-primary);
    --maf-bootstrap-outline-primary: var(--color-primary);
    --maf-bootstrap-solid-primary-content: var(--color-white);
    --maf-bootstrap-solid-primary-hover: darken($primary, 10%);

    //SUCCESS BUTTON
    --maf-bootstrap-solid-success: var(--color-success);
    --maf-bootstrap-outline-success: var(--color-success);
    --maf-bootstrap-solid-success-content: var(--color-white);
    --maf-bootstrap-solid-success-hover: darken($success, 10%);

    //SECONDARY BUTTON
    --maf-bootstrap-solid-secondary: var(--color-secondary);
    --maf-bootstrap-outline-secondary: var(--color-secondary);
    --maf-bootstrap-solid-secondary-content: var(--color-black);
    --maf-bootstrap-solid-secondary-hover: darken($secondary, 10%);

    //INFO BUTTON
    --maf-bootstrap-solid-info: var(--color-info);
    --maf-bootstrap-outline-info: var(--color-info);
    --maf-bootstrap-solid-info-content: var(--color-white);
    --maf-bootstrap-solid-info-hover: darken($info, 10%);

    //DANGER BUTTON
    --maf-bootstrap-solid-danger: var(--color-danger);
    --maf-bootstrap-outline-danger: var(--color-danger);
    --maf-bootstrap-solid-danger-content: var(--color-white);
    --maf-bootstrap-solid-danger-hover: darken($danger, 10%);

    //WARNING BUTTON
    --maf-bootstrap-solid-notification: var(--color-notification);
    --maf-bootstrap-outline-notification: var(--color-notification);
    --maf-bootstrap-solid-notification-content: var(--color-white);
    --maf-bootstrap-solid-notification-hover: darken($notification, 10%);

    //DARK BUTTON
    --maf-bootstrap-solid-dark: var(--color-black);
    --maf-bootstrap-outline-dark: var(--color-black);
    --maf-bootstrap-solid-dark-content: var(--color-white);
    --maf-bootstrap-solid-dark-hover: darken($black, 10%);

    //LIGHT BUTTON
    --maf-bootstrap-solid-light: var(--color-light-blue);
    --maf-bootstrap-outline-light: var(--color-light-blue);
    --maf-bootstrap-solid-light-content: var(--color-black);
    --maf-bootstrap-solid-light-hover: darken($light-blue, 10%);

    // NAVIGATION
    --maf-nav-bg-primary-hover: var(--color-success);
    --maf-nav-font-primary: var(--color-white);
    --maf-mob-nav-primary: var(--color-primary);
    --maf-mob-nav-submenu: var(--color-grey2);
    --maf-mob-nav-primary-border: var(--color-grey4);
    --maf-mob-nav-utility-background: var(--color-light-blue);
    --maf-minicart-number: var(--color-danger);
    --maf-progress-bar: var(--color-breast-cancer);

    // BREADCRUMBS
    --maf-breadcrumbs-current: var(--color-blue);

    // PIPE LINKS
    --maf-o-pipe_link-background: var(--color-inherent);
    --maf-o-pipe_link-text: var(--color-black);
    --maf-o-pipe_link-background-hover: var(--color-primary);
    --maf-o-pipe_link-text-hover: var(--color-white);

    //BLOGS
    --maf-page_layout-detail-blog: var(--color-light-blue);

    // FOOTER
    --maf-o-footer-email_sign_up-background: var(--color-light-blue);
    --maf-o-footer-email_sign_up-header: var(--color-red);
    --maf-o-footer-back_to_top-link_arrow: var(--color-blue);
    --maf-o-footer-back_to_top-link_arrow_hover: var(--color-primary);
    --maf-o-footer-social-links: var(--color-blue);
    --maf-o-footer-main_nav-background: var(--color-success);
    --maf-o-footer-main_nav-text-title: var(--color-white);
    --maf-o-footer-legal_nav-background: var(--color-light-blue);
    --maf-o-footer-legal_nav-text: var(--color-white);
    --maf-o-footer-copyright-background: var(--color-grey3);

    //PAGINATION
    --maf-c-pagination-background: var(--color-grey3);
    --maf-c-pagination-background-hover: var(--color-blue-2);
    --maf-c-pagination-current-background: var(--color-blue);
    --maf-c-pagination-current-text: var(--color-white);

    // FONT
    --h1-layout-content: var(--font-family-sans-serif);
    --h1-layout-color: var(--color-success);

    // PDP
    --pdp-pannels-product-bg: var(--color-light-blue);
    --pdp-pannels-header-bg: var(--color-secondary);
    --pdp-pannels-header-step-circle: var(--color-success);
    --pdp-pannels-header-step-number: var(--color-white);
    --pdp-rating-product-border: var(--color-grey4);


    // ======== CONTENT BLOCKS ========

  }

  :root {
    --blue: #0070d2;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #c00;
    --orange: #D14900;
    --yellow: #ffc107;
    --green: #008827;
    --teal: #030303;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #051424;
    --primary: #1D3557;
    --secondary: #487C9C;
    --success: #F0F9F9;
    --info: #17a2b8;
    --warning: #C24E14;
    --danger: #c00;
    --light: #f8f9fa;
    --dark: #051424;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --breakpoint-xxl: 1920px;
    --font-family-sans-serif: 'Source Sans Pro', sans-serif
  }
  