/*
    @package MEDICALERTTHEMEPREMIUM
    =============================================
        MOST RECENT HORIZONTAL COMPONENT SCSS
    =============================================

*/

span.maf-element-excerpt-post-header{
    position: absolute;
    text-align: center;
    top: -0.7em;
    right: 0;
    width: 100%;
}
span.maf-element-excerpt-post-header a{
    color: #ffffff;
    font-weight: bold;
    font-weight: 600;
    padding: 0 2em;
    border: solid 1px;
    border-radius: 5px;
    text-transform: capitalize;
}
.maf-component-most-recent .maf-excerpt-wrapper{
    border-top: solid 2px;
}

.maf-component-post .maf-excerpt-wrapper{
    border-color: black;
}
.maf-component-post span.maf-element-excerpt-post-header a{
    background: black;
    border-color: black;
}
span.maf-element-excerpt-post-header a:hover{
    background:#ffffff;
    text-decoration:none;
}
.maf-component-post span.maf-element-excerpt-post-header a:hover{
    color: black;
}

// STORIES
.maf-component-stories .maf-excerpt-wrapper{
    border-color: var(--color-info);
}
.maf-component-stories span.maf-element-excerpt-post-header a{
    background: var(--color-info);
    border-color: var(--color-info);
}
.maf-component-stories span.maf-element-excerpt-post-header a:hover{
    color: var(--color-info);
}

// NEWS
.maf-component-news .maf-excerpt-wrapper{
    border-color: var(--color-success);
}
.maf-component-news span.maf-element-excerpt-post-header a{
    background: var(--color-success);
    border-color: var(--color-success);
}
.maf-component-news span.maf-element-excerpt-post-header a:hover{
    background: var(--color-white);
    color: var(--color-success);
}

// NEWS
.maf-component-podcasts .maf-excerpt-wrapper{
    border-color: var(--color-primary);
}
.maf-component-podcasts span.maf-element-excerpt-post-header a{
    background: var(--color-primary);
    border-color: var(--color-primary);
}
.maf-component-podcasts span.maf-element-excerpt-post-header a:hover{
    background: var(--color-white);
    color: var(--color-primary);
}
