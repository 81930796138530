/*
    @package MEDICALERTTHEMEPREMIUM
    ==================================
           SEARCH COMPONENT SCSS
    ==================================

*/

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}


/*
    =======================
        Search Component
    =======================
*/

div.maf-c-site-search{
    width: 100%;
    margin: 2.5rem 0 0;
}
section.maf-organism .maf-c-site-search form input.search-field{
    border-radius: 20px;
}
section.maf-organism .maf-c-site-search form .fa-search{
    border-radius: 3px 20px 20px 3px;
}
.search-mobile{
    position: relative;
    z-index: 9;
}

/*
    ====================
        Search Header
    ====================
*/

section.maf-organism-search-modal .modal-lg{
    max-width: 100%;
    margin: 0;
}
section.maf-organism-search-modal .modal-content{
    padding: 1% 0;
}
section.maf-organism-search-modal .modal-header{
    border: none;
}

/*
    ==========================
        Search Suggestions
    ==========================
*/
.site-search{
    width: 100%;
    z-index: 999;
    .suggestions-wrapper{
        width: 141%;
        .maf-component-search-suggestions{
            right: 29%;
            top: 0 !important;
            li{
                text-align: left;
                padding: 1em 0;
                margin: 0;
                &.header{
                    text-align: left;
                    padding: 0 4%;
                    background: var(--color-grey3);
                    > div{
                        width: 100%;
                        padding: 4% 0;
                        font-weight: bold;
                        font-weight: 600;
                        color: var(--color-info);
                    }
                }
            }
        }
    }

}


@media (min-width: 768px) {
    section.maf-organism .maf-c-site-search{
        width: 55%;
        max-width:635px;
        margin: 0 auto;
    }
}

@media (min-width: 1200px) {
    .site-search {
        width: 25rem;
        /* 320/16 */
        .suggestions{
            width: 100%;
        }
    }
}
