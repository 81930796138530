/*
    @package MEDICALERTTHEMEPREMIUM
    =============================================
            MEMBERSHIP PLANS COMPONENT SCSS
    =============================================

*/

#maincontent{
    .maf-modal-memberships{
        a.btn{
            margin: 0;
        }
        .maf-modal-header{
            margin: 0 0 1em;
        }
   }
}

.maf-modal-memberships-plans{
    > div.modal-dialog{
        max-width:920px;
    }
}
.maf-links-wrap{
    text-align: center;
    margin: 0 0 2em;
    >div{
        margin: 1% 0;
        &:nth-of-type(1){
            a{
                color: var(--color-info);
                font-weight: bold;
                font-weight: 600;
            }
        }
        &:nth-of-type(2){
            a{
                color: var(--color-grey6);
                font-weight: normal;
                font-weight: 500;
            }
        }
    }
}
.maf-component-membership-plans{
    margin: 4em 0 3em;
    div.row{
        width: 100%;
        margin: 0 0 0 1em;
        .maf-card-basic{
            margin-right: -2%;
        }
        .maf-card-advantage{
            background: white;
            z-index: 9;
            margin: -1em 0 0;
            .maf-plan-advantage{
                height: 490px;
            }
        }
        .maf-card-advantage-plus{
            margin-left: -0.3em;
        }
        > div{
            padding: 0;
            .maf-plan{
                position:relative;
                border: solid 2px gray;
                border-radius: 8px;
                padding: 0 0 1.5em;
                .maf-header{
                    text-align: center;
                    background: var(--color-grey3);
                    padding: 2em 0 0.5em;
                    figure{
                        position: absolute;
                        top: -2.3em;
                        left: 0;
                        width: 100%;
                        img{
                            width: 25%;
                        }
                    }
                    h3{
                        color:var(--color-white);
                        font-weight: bold;
                        font-weight: 600;
                    }
                }
                .maf-body{
                    ul{
                        li{
                            padding: 0.7em 1.5em;
                            letter-spacing: 0;
                            line-height: 1;
                        }
                    }
                }
                .maf-price{
                    text-align: center;
                }
                &.maf-plan-basic{
                    border-color: var(--color-info);
                    min-height: 456px;
                    max-height: 456px;
                    .maf-header{
                        background: var(--color-info);
                    }
                    .maf-body{
                        ul{
                            li{
                                &:nth-of-type(even){
                                    background:#45779620;
                                }
                            }
                        }
                    }
                }
                &.maf-plan-advantage{
                    border-color: var(--color-danger);
                    .maf-header{
                        background: var(--color-danger);
                    }
                    .maf-body{
                        ul{
                            li{
                                &:nth-of-type(even){
                                    background:#d82a3220;
                                }
                            }
                        }
                    }
                }
                &.maf-plan-advantage-plus{
                    border-color: var(--color-success);
                    min-height: 456px;
                    max-height: 456px;
                    .maf-header{
                        background: var(--color-success);
                    }
                    .maf-body{
                        ul{
                            li{
                                &:nth-of-type(even){
                                    background:#1d355720;
                                }
                            }
                        }
                    }
                }
                .maf-body{
                    margin:0;
                    ul{
                        list-style: none;
                        padding: 0;
                        margin: 1em 0;
                        li{
                            &::before {
                                content: "✓";
                                margin: 0 0.2em 0 0;
                            }
                        }
                    }
                }
                .maf-price{
                    p{
                        font-size: 2em;
                        font-weight: bold;
                        font-weight: 600;
                        color: var(--color-success);
                        span{
                            font-size: 0.35em;
                            line-height: 1;
                            display: inline-block;
                            text-align: left;
                            border-left: solid 1px;
                            margin: 0 0 0 0.5em;
                            padding: 0 0 0 0.5em;
                            color: var(--color-success);
                        }
                    }
                }
                .maf-button{
                    position: absolute;
                    bottom: -1.3em;
                    left: 0;
                    width: 100%;
                    text-align: center;
                    a{
                        max-width: 100%;
                        min-width: 150px !important;
                    }
                    button{
                        max-width: 100%;
                        min-width: 150px !important;
                    }
                }
                .maf-sub-header{
                    text-align: center;
                    p{
                        margin: 3% 0 0;
                        font-weight: bold;
                        font-weight: 600;
                        &:nth-last-of-type(2){
                            margin: 6% 0 0;
                        }
                        &.maf-advantage-plus-sub-header{
                            color:var(--color-success);
                        }
                        &.maf-advantage-sub-header{
                            color:var(--color-danger);
                        }
                        &.maf-basic-sub-header{
                            color:var(--color-info);
                        }
                    }
                    .maf-line-breaker{
                        border: solid 1px grey;
                        width: 80%;
                        margin: 2% auto;
                        border-color: var(--color-grey5);
                    }
                }
            }
        }
    }
}
@media (max-width: 768px) {
.maf-component-membership-plans{
    margin: 1em 0 4em;
    ul.maf-mobile-plan-tabs{
        border: none;
        margin: 0 !important;
        li.maf-header{
            position:relative;
            flex:1;
            text-align: center;
            figure{
                width: 100%;
                top: -1em;
                img{
                    max-width: 40px;
                }
            }
            h3{
                font-size: 0.83em;
                font-weight: bold;
                font-weight: 600;
                margin: 3% 0 0;
                color:var(--color-white);
            }
            a{
                min-height: 100px;
                opacity: 0.35;
                border-radius: 10x 10px 0 0;
                &.active{
                    opacity: 1;
                }
            }
            &.maf-header-basic a{
                background:var(--color-info);
            }
            &.maf-header-advantage a{
                background:var(--color-danger);
            }
            &.maf-header-advantage-plus a{
                background:var(--color-success);
            }
        }
    }
    .tab-content{
        > div{
            padding: 4%;
            border: solid 1px grey;
            .maf-sub-header{
                text-align: center;
                p{
                    margin: 3% 0 0;
                    font-weight: bold;
                    font-weight: 600;
                    &:nth-last-of-type(2){
                        margin: 6% 0 0;
                    }
                    &.maf-advantage-plus-sub-header{
                        color:var(--color-success);
                    }
                    &.maf-advantage-sub-header{
                        color:var(--color-danger);
                    }
                    &.maf-basic-sub-header{
                        color:var(--color-info);
                    }
                }
                .maf-line-breaker{
                    border: solid 1px grey;
                    width: 80%;
                    margin: 2% auto;
                    border-color: var(--color-grey5);
                }
            }
            &.tab-content-basic{
                border-color: var(--color-info);
                li{
                    &:nth-of-type(even){
                        background:#45779620;
                    }
                }
            }
            &.tab-content-advantage{
                border-color: var(--color-danger);
                li{
                    &:nth-of-type(even){
                        background:#d82a3220;
                    }
                }
            }
            &.tab-content-advantage-plus{
                border-color: var(--color-success);
                li{
                    &:nth-of-type(even){
                        background:#1d355720;
                    }
                }
            }
            .maf-body{
                ul{
                    list-style: none;
                    padding: 0;
                    li{
                        &::before {
                            content: "✓";
                            margin: 0 0.5em 0 0;
                        }
                    }
                }
            }
            .maf-price{
                text-align: center;
                p{
                    font-size: 2em;
                    font-weight: bold;
                    font-weight: 600;
                    color: var(--color-success);
                    span{
                        font-size: 0.35em;
                        line-height: 1;
                        display: inline-block;
                        text-align: left;
                        border-left: solid 1px;
                        margin: 0 0 0 0.5em;
                        padding: 0 0 0 0.5em;
                        color: var(--color-success);
                    }
                }
            }
            .maf-button{
                position: absolute;
                bottom: -1.3em;
                left: 0;
                width: 100%;
                text-align: center;
                a{
                    max-width: 100%;
                    min-width: 150px !important;
                }
                button{
                    max-width: 100%;
                    min-width: 150px !important;
                }
            }
        }
    }
}

}
