/*
    @package MEDICALERTTHEMEPREMIUM
    ===============================
        CONTACT US LAYOUTS SCSS
    ===============================
*/

.maf-card-with-form{
    background: #f0f9f9;
    padding: 4%;
    border-radius: 30px;
}
.coblocks-form{
    label{
        color: var(--color-success);
    }
    form{
        input, textarea, select{
            background: none;
            border: 1px solid #AAC3D2;
                border-color: var(--color-secondary);
            border-radius: 7px;
            padding: 0 3%;
            // &:valid {
            //     border: 1px solid #AAC3D2;
            //     border-color: var(--color-secondary);
            // }
        }
        input, select{
            height: 47px;
        }
        .coblocks-field{
            input{
                height: auto;
            }
        }
        button{
            display: inline-block;
            font-size: 0.98rem;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            font-weight: bold;
            font-weight: 600;
            text-decoration: none;
            min-width: 233px;
            min-height: 41px;
            margin: 1.5em 0.3em 0;
            box-shadow: 1px ​1px 2px 0px transparent;
            border-radius: 1.2rem;
            padding: 0.75rem 1.5rem;
            outline: none;
            text-align: center;
            vertical-align: middle;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
            line-height: 1;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            position: relative;
            z-index: 2;
        }
    }
}
