/*
    @package MEDICALERTTHEMEPREMIUM
    =============================================
        MEMBERSHIP PLANS OFFERS COMPONENT SCSS
    =============================================

*/

.maf-modal-memberships{
    text-align: center;
    section{
        position: relative;
        border: solid 1px;
        border-radius: 30px;
        padding: 12% 12% 19%;
        margin: 1em 0;
        h3{
            font-weight: bold;
            font-weight: 600;
            color: var(--color-blue);
        }
        ul{
            list-style: disc;
            text-align: left;
            padding: 0;
            margin: 0;
            min-height: 12em;
        }
    }
    
    &.maf-plan-advantage{
        padding: 19% 12% 12%;
    }
}
.maf-modal-memberships > div.modal-dialog{
    max-width:920px;
}
.maf-modal-memberships .modal-body-membership > .row{
    width: 100%;
    margin: 0 auto;
    margin-left: 1.5%;
}
.maf-modal-memberships section label.most-popular-label{
    position: absolute;
    top: -1px;
    width: 100%;
    left: 0;
    background: #F8F7F5;
    color: white;
    border-radius: 30px 30px 0 0;
    padding: 0.05em 0;
    font-weight: bold;
    font-weight: 600;
    font-size: 1em;
    text-transform: capitalize;
}
.maf-modal-memberships section.maf-plan-advantage label.most-popular-label{
    background: #1D3557;
    padding: 0.2em 0;
}
.modal-body-membership .maf-modal-content-asset-wrap{
    width: 90%;
    margin: 0 auto;
    line-height: 1;
}
.maf-modal-memberships .modal-body-membership .maf-link-modal{
    text-align: center;
    display: block;
}
.maf-modal-memberships .modal-body-membership > .row.maf-links-wrap{
      margin: 3em 0 0;
}
.maf-modal-memberships .modal-body-membership #btnAlreadyHavePlan{
    border-bottom: solid 1px;
    color: var(--color-info);
}
.maf-modal-memberships .modal-body-membership #btnConfirmNoPlan{
    margin: 2% 0 0;
    color: var(--color-grey6);
    font-weight: normal;
    font-weight: 500;
}

// [START] NO PLAN MODAL
div.maf-modal-memberships-no-plan,
div.maf-modal-memberships-3-years{
    .modal-content{
        border-radius: 10px;
        padding: 1% 0 4%;
        h2.modal-title{
            padding: 0.8em;
            font-size: 1.8em;
            font-weight: bold;
            font-weight: 700;
            &.modal-title-underline{
                border-bottom: solid 1px;
                display: inline-block;
            }
        }
        .maf-modal-ctas{
            margin: 0;
            display: -webkit-box;
            display: -moz-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            > div{
                width: 100%;
                margin: 3% 0;
                a.btn{
                    font-weight: bold;
                    font-weight: 700;
                    padding: 0.5em 2em;
                }
            }
        }
    }
}
div.div.maf-modal-memberships-3-years{
    .maf-modal-ctas{
        margin: 3em 0 0;
    }
}
.maf-links-wrap{
    .maf-link-modal-plans-no-plan{
        margin: 2em 0 0;
        a{
            border-bottom: solid 1px;
            font-size: 0.87em;
            cursor: pointer;
            &:focus-visible{
                box-shadow: 0 0 2px 2px #015ecc;
            }
            &:focus{
                box-shadow: 0 0 2px 2px #015ecc;
            }
        }
    }
}

// [END] NO PLAN MODAL

#membershipModalPDP{
    .maf-links-wrap{
        display: block;
    }
}

@media (min-width: 768px) {
    .maf-modal-memberships section.maf-plan-advantage{
        margin: 0;
    }
    .maf-modal-memberships .modal-body-membership .row > div:nth-of-type(1){
        padding-right: 0;
    }
    .maf-modal-memberships .modal-body-membership .row > div:nth-of-type(2){
        padding: 0;
        margin: 0;
        z-index: 2;
        background: white;
    }
    .maf-modal-memberships .modal-body-membership .row > div:nth-of-type(3){
        padding-left: 0;
    }
    // [START] NO PLAN MODAL
    .maf-modal-memberships-no-plan,
    .maf-modal-memberships-3-years{
        .modal-content{
            div.maf-modal-ctas{
                > div{
                    margin: 0;
                    &:nth-of-type(2){
                        margin: 3% 0 0;
                    }
                }
            }
        }
    }
    // [END] NO PLAN MODAL
    #btnAlreadyHavePlan{
        border-bottom: solid 1px;
    }
    .maf-links-wrap{
        .maf-link-modal-plans-no-plan{
            margin: 1em 0 0;
        }
    }
}

@media (max-width: 768px) {
    #btnAlreadyHavePlan{
        border: solid 1px;
        padding: 0.5em 1em;
        font-size: 3.3vw;
        cursor: pointer;
        &:hover{
            text-decoration:none;
        }
    }
}
