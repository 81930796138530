/*
    @package MEDICALERTTHEMEPREMIUM
    ==================================
           SYSTEM NAV COMPONENT
    ==================================

*/

section.maf-organism-system-navigation div.user{
    display: none;
}
div.maf-component-system-navigation{
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    width: 100%;
    height: 100%;
}

.navbar-header .country-selector, .navbar-header .search, .navbar-header .minicart, .navbar-header .user{
    line-height: 0;
    margin: 0 0 0 1em;
}



@media (min-width: 992px) {
    section.maf-organism-system-navigation div.maf-c-logo{
        display:none;
    }
    section.maf-organism-system-navigation{
        position: absolute;
        top: 0;
        right: 0;
    }
    section.maf-organism-system-navigation div.user{
        display: inline-block;
    }
    .maf-component-system-navigation{
        height:100%;
    }

}
