/*
    @package MEDICALERTTHEMEPREMIUM
    ================================
        FADE ANIMATIONS SCSS
    ================================
*/

// ================================
//     FADE ANIMATIONS SCSS
// ================================
@media only screen and (min-width: 769px) {

    .maf-fade .fade-item{
        opacity: 0;
    }
    .maf-fade .f1 {
        animation: fade-in-item 1s .2s ease-out forwards;
    }
    .maf-fade .f2 {
        animation: fade-in-item 1s .6s ease-out forwards;
    }
    .maf-fade .f3 {
        animation: fade-in-item 1s .8s ease-out forwards;
    }
    .maf-fade .f4 {
        animation: fade-in-item 1s 1s ease-out forwards;
    }
    @-webkit-keyframes fade-in-item {
        0%   { display: block; opacity: 0;  }
        30% { display: block; opacity: .5; }
        60% { display: block; opacity: .8; }
        100% { display: block; opacity: 1; }
    }

}
