/*
    @package MEDICALERTTHEMEPREMIUM
    ===============================
            PDP SFCC SCSS
    ===============================

*/
#maincontent .prices-add-to-cart-actions {
    .cart-and-ipay{
        button.add-to-cart{
            border-radius: 0;
            margin: 1.5em 0em 0;
        }
    }
}
.maf-organism-pdp-more-details {
    margin: 2em 0 0;
    h2{
        font-weight:bold;
        font-weight:600;
    }
}

div.container.product-detail{
    width: 94%;
    padding: 0;
    &> .row{
        margin-left:0;
        margin-right:0;
    }
    .product-number{
        color: var(--color-black);
        .product-id{
            font-weight: bold;
            font-weight: 700;
        }
        button.disabled{
            pointer-events: none;
        }
    }
    .product-number-rating{
        padding: 0 3%;
        border-color: var(--pdp-rating-product-border);
    }
    .maf-component-tabs {
        .attribute{
            margin:0;
        }
    }
    .attributes{
        padding-left: 0;
        padding-right: 0;
        label,
        .color,
        .product-availability .availability,
        .non-input-label{
            color: var(--color-black);
            text-transform: uppercase;
            font-weight: bold;
            font-weight: 600;
            font-size: 0.85rem;
            letter-spacing: 0.05em;
        }
        &>div{
            margin: 0 0 0.3em;
            &:nth-of-type(3){
                margin: 0;
            }
        }
        .maf-number-circled {
            background: var(--color-success);
           border-radius: 0.8em;
           -moz-border-radius: 0.8em;
           -webkit-border-radius: 0.8em;
           color: var(--color-white);
           display: inline-block;
           font-weight: bold;
           line-height: 1.35em;
           margin-right: 5px;
           text-align: center;
           width: 1.35em;
           font-size: 0.9em;
        }
    }
    .non-input-label,
    label.size,
    .attribute label{
        margin: 0 0 0.2em;
    }
    .prices-add-to-cart-actions{
        > .row > .col-12{
            padding: 0;
        }
        .prices {
            padding: 0;
        }
        .prices .price{
            text-align: left;
            font-size: 1.83em;
            line-height: 1;
            margin: 0 0 0.5em;
        }
    }
    .maf-component-pdp_membership{
        align-self: flex-end;
    }
    .availability-msg,
    .product-detail .attributes>div.availability{
        margin:0;
    }
    .product-availability > div{
        padding: 0;
        align-self: flex-end;
    }
    .product-availability > div span{
        margin: 0;
    }
    .cart-and-ipay > div{
        padding:0;
    }
    .cart-and-ipay > div button{
        margin-left:0;
        margin-right:0;
    }
    div[data-attr="color"] > div.col-12{
        margin: 0 0 1em;
    }
    .nav-tabs .nav-link{
        font-size: 1em;
    }
    
    .maf-pannel {
      display: none;
    }
    
    .step {
      height: 15px;
      width: 15px;
      margin: 0 2px;
      background-color: #bbbbbb;
      border: none;
      border-radius: 50%;
      display: inline-block;
      opacity: 0.5;
    }
    .step.active {
      opacity: 1;
    }
    .step.finish {
      background-color: #04AA6D;
    }
    #maf-error-message,
    .maf-error-message{
        border-color:var(--color-danger);
        box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25);
        border-radius: 10px;
    }
    .btn-disabled{
        opacity: .65;
        pointer-events: none;
    }

}
.h1.product-name{
    font-size: 1.75em;
    margin:0;
}

// [START] CAROUSEL
.product-detail{
    .carousel{
        &> a{
            position: absolute;
            top:0;
        }
        .carousel-control-prev{
            left:0;
        }
        .carousel-control-next{
            right:0;
        }
        .icon-next,
        .icon-prev{
            background: var(--color-light-blue);
        }
        ol.carousel-indicators li {
            background-color: var(--color-info );
            width: 10px;
            height: 10px;
            margin: 0 0.3em;
            border:none;
            border-radius: 100em;
        }

    }
}
// [END] CAROUSEL

// [START] PDP ATTRIBUTES PANNELS
.maf-pdp-right-side{
    padding: 0;
    .maf-component-tabs{
        &>div{
            background: var(--pdp-pannels-product-bg);
        }
        .maf-pannel{
            h2{
                font-weight: bold;
                font-weight: 600;
                font-size: 1.1em;
                background: var(--pdp-pannels-header-bg);
                color: var(--color-black);
                padding: 0.5em 1em;
                span{
                    font-size: 18px;
                    color: var(--pdp-pannels-header-step-number);
                    background: var(--pdp-pannels-header-step-circle);
                }
            }
            &>div{
                padding: 1em;
            }
        }
        .nav-tabs{
            font-weight: bold;
            font-weight: 600;
            border-top: 1px solid #1D3557;
        }
        .maf-component-panel-ctas{
            padding: 0 2%;
            text-align: right;
            button{
                width: 100%;
                padding: 0;
                margin: 0;
            }
        }
    }
}
.maf-component-pdp-panel{
    position:relative;
    fieldset {
        background: white;
        border: 0 none;
        border-radius: 3px;
        box-shadow: 0 0 15px 1px rgb(0 0 0 / 11%);
        padding: 0em 0em 2.5em;
        box-sizing: border-box;
        margin: 0 auto;
        position: relative;
        text-align:right;
        &:not(:first-of-type) {
            display: none;
        }
        > div{
            text-align:left;
            &.maf-panel-multiple-ctas{
                text-align: right;
                .maf-add-to-cart-pdp-cta{
                    position: relative;
                    p{
                        top: -0.5em;
                        position: absolute;
                        right: 0;
                        width: 200%;
                        text-align: center;
                        color: var(--color-info);
                    }
                }
            }
            h2{
                font-size: 1.2em;
                font-weight: bold;
                font-weight: 600;
                // position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                background: var(--color-secondary);
                padding: 0.4em 3%;
            }
        }
        .maf-pannel-body,
        .maf-panel-cta-wrap{
            padding: 0 0.5em;
        }
        .maf-panel-cta-wrap{
            text-align:right;
        }
    }
}
.maf-component-pdp-panel .progressBar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    li {
        list-style-type: none;
        color: white;
        text-transform: uppercase;
        font-size: 9px;
        width: 33.33%;
        margin-bottom: 30px;
        float: left;
        position: relative;
        text-align: center;
        &.step{
            height: 15px;
            width: 15px;
            margin: 0 2px;
            background-color: var(--color-grey5);
            border: none;
            border-radius: 50%;
            display: inline-block;
            opacity: .5;
            &.active{
                background-color: var(--color-secondary);
            }
        }
    }
}

// [END] PDP ATTRIBUTES PANNELS
.description-and-detail{
    overflow: auto;
    h2{
        color: var(--color-info);
        padding:0;
    }
    h3{
        font-size:1.4em;
    }
}

.product-number-rating{
    .product-number{
        color: var(--color-grey6);
        span{
            color: var(--color-grey6);
        }
    }
}

@media (min-width: 768px) {
    .maf-component-pdp-panel{        
        fieldset {    
            .maf-pannel-body,
            .maf-panel-cta-wrap{
                padding: 0 3em;
            }
        }
    }
}

@media (max-width: 768px) {
    .product-detail {
        .attributes{
            &>div{
               margin: 0;
            }
            .product-number-rating{
                padding: 0;
                .product-number{
                    float: none;
                    padding: 0;
                }
                .ratings{
                    float: none;
                    margin: 0;
                    background: none;
                    padding: 0;
                }
            }
        }
        h1.product-name{
            font-size:1.3em;
        }
    }
    #maincontent .prices-add-to-cart-actions {
        .prices{
            padding: 0;
            span{
                margin:0;
                display: block;
            }
        }
        .cart-and-ipay{
            padding: 0 0 0.5em;
            button.add-to-cart{
                margin: 0;
            }
        }
    }
}
