/*
    @package MEDICALERTTHEMEPREMIUM
    ===============================
        DONATION BUTTON TOP NAV
    ===============================

*/

a.maf-element-donate-button,
button.maf-element-donate-button{
    text-transform: uppercase;
    font-weight: bold;
    font-weight: 600;
    text-decoration: none;
    min-width: auto;
    box-shadow: 1px ​1px 2px 0px transparent;
    border-radius: 1.2rem;
    // border: solid 2px;
    outline: none;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    line-height: 1;
    padding: 0.75rem 1.5rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; 
    &:hover,
    &:focus{
        box-shadow: 0 0 0 0.2rem rgba(4, 204, 204, 0.5);
    }
}

@media (min-width: 768px) {

    a.maf-element-donate-button,
    button.maf-element-donate-button{
        font-size: 0.98em;
        letter-spacing: 0.1em;
        padding: 0.5em 1.5em;
    }
    .maf-component-donate{
        margin: 0 0 0 1em;
    }

}
