/*
    @package MEDICALERTTHEMEPREMIUM
    ================================
        ELEMENTOR ELEMENTS SCSS
    ================================
*/

// [START] LINKS
.elementor-widget-post-info{
    .elementor-inline-item{
        .elementor-icon-list-text{
            a.elementor-post-info__terms-list-item{
                border-bottom: solid 1px var(--color-blue);
                color: var(--color-blue);
            }
        }
    }
}
// [END] LINKS
