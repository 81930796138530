/*
    @package MEDICALERTTHEMEPREMIUM
    ===========================================
        INFORMATION NO SIDEBAR TEMPLATE SCSS
    ===========================================

*/


.maf-page-layout-informational-no-sidebar{
    .maf-page-header{
        margin: 0 0 1em;
    }
    h2{
        margin: 0 0 1em
    }
    article{
        .maf-post-sidebar{
            background: var(--maf-page_layout-detail-blog);
            border-radius: 0 0 20px 20px;
            padding: 10%;
        }
    }
}

@media (max-width: 768px) {

    .maf-page-layout-informational-sidebar{
        width: 100%;
    }

}
